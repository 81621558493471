import React from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Hidden,
  Box,
  IconButton,Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchImg from '../../assests/images/agent-search.gif'
// import SearchImg from '../../assests/images/adss_default_logo.png'
// import {SearchImg} from '../../assests/images/adss_default_logo.png'



const useStyle = makeStyles((theme) => ({
  pageBorder: {
    border: "1px dashed #cdcfd1",
    opacity: 0.8,
    textAlign:"center",
    height: "100%"
  },
  imageSize:{
    maxWidth:"30rem"
  },
  searchContent:{
    color: "#15537C",
    opacity: 1,
    fontSize:"24px"
  }
}));
export default function E_Agent_Home(props) {
  let classes = useStyle();

  return(

    <>
    <Grid  className={classes.pageBorder}>
        <Grid  >
          <img
              className={classes.imageSize}
              src={SearchImg}
            />
        </Grid>
        <Grid>
            <Typography className={classes.searchContent}>
                   { props.searchContent }
                  </Typography>
        </Grid>

    </Grid>
    
    </>
  ) ;
}