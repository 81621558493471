import React, { useEffect, useState } from "react";
import { CardContent, Card, Grid } from "@material-ui/core";
import PaymentHeader from "../e_multiple_payments/view/e_multiple_payments_header";
import InitiatePayment from "../e_multiple_payments/e_make_payments/view/e_make_payment_your_account";
import { useSelector } from "react-redux";
import RechargePayment from "../e_multiple_payments/e_prepaid_recharge/view/e_prepaid_recharge_your_account";

export const RouteContext = React.createContext();

export default function MultiPaymentComponent(props) {
  const [routeData, setRouteData] = useState({});
  useEffect(() => {
    if (props?.location?.state) {
      setRouteData(props?.location?.state);
    }
  }, [props?.location]);
  const isPrepaid = useSelector(
    (store) => store?.meterDetails?.getSaInfo?.isPrepaidSa
  );
  return (
    <RouteContext.Provider value={{ routeData }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <PaymentHeader/>
            {isPrepaid && isPrepaid === "N" ? (
              <>
                <InitiatePayment />
              </>
            ) : (
              <>
                <RechargePayment />
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </RouteContext.Provider>
  );
}
