// @flow

import React from "react";
import { Router } from "react-router-dom";
import { history } from "../../helpers/index";
import { createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { appStyles } from "./app-style";
import { setupAxiosInterceptors } from "./axios-setup";
import { commonService } from "../../services/common.service";
import { CssBaseline, Hidden, Typography } from "@material-ui/core";
import "../app/base-style.css";
import {
  userDetails,
  updateMeterDetails,
  userPreferences,
} from "./../../actions/home.action";
import SwipeableInstallBanner from "./install-banner";
import { getAppReady_Labels } from "./../../actions/language.actions";
import { getAppReady_widget, browserDetect } from "./../../actions/app.actions";
import TitleComponent from "./title-component";
import E_Layout from "../e_layout/e_layout";
import { config } from "../../enviroment";
// import ChatWidget from "../chat-widget/chat.widget";
import FetchLabels from "./labels";
import Payment from "../e_payments/e_razor_pay/e_razor_payment";
import CacheBuster from '../../CacheBuster';

type AppProps = {
  browserDetect: () => Function,
  getAppReady_Labels: () => Function,
  getParams: () => Function,
  userDetails: () => Function,
  accountDetails: () => Function,
  userPreferences: () => Function,
  store: any,
};

type AppState = {
  theme: any,
};

class App extends React.Component<AppProps, AppState> {
  constructor(props) {
    // setTimeout(function () { ChatWidget();  }, 10000);

    super(props);
    this.showThirdPartyRedirectInProgress = false;
    // this.setState({
    //   showThirdPartyRedirectInProgress:false
    // })
    this.redirectPage = "";
    this.amount = "";
    this.accounts = "";
    const testNative = async () => {
      if (window.location.href) {
        let search = window.location.search;
        const params = new URLSearchParams(search);
        let accesstoken;
        let redirectPage;
        let accountNumber;
        let saID;
        let amount;
        let accounts;
        for (const [key, value] of params.entries()) {
          if (key.toLowerCase() === "nativeapptoken") {
            accesstoken = value;
          }
          if (key.toLowerCase() === "path") {
            redirectPage = value;
            localStorage.setItem("nativeRedirect", value);
          }
          if (key.toLowerCase() === "accountnumber") {
            accountNumber = value;
          }
          if (key.toLowerCase() === "serviceid") {
            saID = value;
          }
          if (key.toLowerCase() === "amount") {
            amount = value;
            this.amount = value;
          }
          if (key.toLowerCase() === "accounts") {
            accounts = value;
            this.accounts = value;
          }
        }
        this.redirectPage = redirectPage;

        if (
          accesstoken && 
          window.location.pathname === config.constants.REDIRECT_URL
        ) {
          // localStorage.clear();
          this.showThirdPartyRedirectInProgress = true;

          this.setState({
            showThirdPartyRedirectInProgress: true,
          });
          localStorage.clear();
          await commonService
            .getBearerFromAccess(accesstoken)
            .then((response) => {
              localStorage.setItem("nativeAppRediection", true);
              localStorage.setItem("bearer", JSON.stringify(response));
              localStorage.setItem("accountSelected",accountNumber);
              // localStorage.setItem("nativeAccount", accounts);
              // localStorage.setItem("nativeAmount", amount);
              let bearer = JSON.stringify(response);
              if (bearer && !redirectPage) {
                window.location.href = window.location.origin;
                this.showThirdPartyRedirectInProgress = false;

                this.setState({
                  showThirdPartyRedirectInProgress: false,
                });
              } else if (bearer && redirectPage) {
                this.showThirdPartyRedirectInProgress = false;
                setupAxiosInterceptors();
                this.props.userDetails();
                this.props.userPreferences();

                this.setState({
                  showThirdPartyRedirectInProgress: false,
                });
                console.log("redirectpage", this.redirectPage);
                this.redirectPage !== "initiate-payment" &&
                  history.push(redirectPage);
              }
            })
            .catch((err) => {
              alert(
                "We could not log you in. You will be redirected to the login page."
              );
              localStorage.clear();
              commonService.logoutUser();
            });
        } else {
          this.showThirdPartyRedirectInProgress = false;
          !this.redirectPage && localStorage.removeItem("nativeRedirect");

          this.setState({
            showThirdPartyRedirectInProgress: false,
          });
          let bearer = localStorage.getItem("bearer");
          if (bearer === null) {
            localStorage.setItem("nativeAppRediection", false);

            commonService.logoutUser();
          }
        }
      }
    };

    testNative();

    // this.testNative = this.testNative.bind(this);
    function noop() {}
    if (process.env.NODE_ENV !== "development") {
      console.log = noop;
      console.warn = noop;
      console.error = noop;
    }
    let bearer = localStorage.getItem("bearer");

    if (bearer && this.showThirdPartyRedirectInProgress === false) {
      setupAxiosInterceptors();
      this.props.getAppReady_widget();
      // this.props.getAppReady_Labels();
    }
    this.state = {
      theme: appStyles.outerTheme,
      showThirdPartyRedirectInProgress:
        this.redirectPage === "initiate-payment"
          ? false
          : this.showThirdPartyRedirectInProgress,
      redirectNative: this.redirectPage,
    };
  }

  async componentDidMount() {
    this.props.browserDetect();

    let bearer = localStorage.getItem("bearer");
    if (bearer && !this.showThirdPartyRedirectInProgress) {
      this.props.userDetails();
      this.props.userPreferences();
    }
    if (localStorage.getItem("nativeRedirect")) {
      this.setState({
        redirectNative: localStorage.getItem("nativeRedirect"),
      });
    }
    if (
      localStorage.getItem("nativeAppRediection") &&
      localStorage.getItem("nativeRedirect") === "PAYMENTCONFIRM"
    ) {
      this.showThirdPartyRedirectInProgress = true;
      this.setState({
        showThirdPartyRedirectInProgress: true,
      });
    }
    this.props.store.subscribe(() => {
      const state = this.props.store.getState();
      if (state.parametersWidgets.ParameterLookup) {
        const theme = createMuiTheme({
          palette: {
            primary: {
              main: "#15537c",
            },
            secondary: {
              main: "#00ab6a",
            },
            tertiary: {
              main: "#f77d0f",
            },
            quaternary: {
              main: "#e5f6df",
            },
            quinary: {
              main: "#90cce1",
            },
            whiteColor: {
              main: "#FFFFFF",
            },
            blackColor: {
              main: "#000000",
            },
            usageNegativeColor: {
              main: "#FF0000",
            },
            usagePositiveColor: {
              main: "#01B61E",
            },
            background: {
              default: state.parametersWidgets.ParameterLookup.QUINARY_COLOR,
            },
            ePrimaryColors: {
              first: state.parametersWidgets.ParameterLookup.PRIMARY_COLOR_1,
              second: state.parametersWidgets.ParameterLookup.PRIMARY_COLOR_2,
              third: state.parametersWidgets.ParameterLookup.PRIMARY_COLOR_3,
              forth: state.parametersWidgets.ParameterLookup.PRIMARY_COLOR_4,
              fifth: state.parametersWidgets.ParameterLookup.PRIMARY_COLOR_5,
            },
            eSecondaryColors: {
              first: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR_1,
              second: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR_2,
              third: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR_3,
              forth: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR_4,
              fifth: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR_5,
              sixth: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR_6,
            },
            eTertiaryColors: {
              first: state.parametersWidgets.ParameterLookup.TERTIARY_COLOR_1,
              second: state.parametersWidgets.ParameterLookup.TERTIARY_COLOR_2,
              third: state.parametersWidgets.ParameterLookup.TERTIARY_COLOR_3,
            },
            text: {
              primary:
                state.parametersWidgets.ParameterLookup.SECONDARY_COLOR_6,
            },
            ePrimary: {
              base: "#15537c",
              selected: "#144362",
              hover: "#477695",
            },
            eSecondary: {
              base: "#00ab6a",
              selected: "#018854",
              hover: "#44AD85",
            },
            eTertiary: {
              base: "#f77d0f",
              selected: "#c4641f",
              hover: "#f79752",
            },
            ePastelColor1: {
              base: "#e5f6df",
              selected: "#d5f0cb",
              hover: "#ecf8e8",
            },
            ePastelColor2: {
              base: "#90cce1",
              selected: "#7cc3dc",
              hover: "#b1dbea",
            },

            eRich: { base: "#37393a", selected: "#2c2e2e", hover: "#5f6161" },
            eDark: { base: "#5c5e60", selected: "#4a4b4d", hover: "#7d7e80" },
            eMedium: { base: "#93979a", selected: "#76797b", hover: "#a9acae" },
            eLight: { base: "#dee2e4", selected: "#c8ccce", hover: "#e5e8e9" },

            eBackground: {
              base: "#eff2f4",
              selected: "#d8dadc",
              hover: "#f2f5f6",
            },
            eFaint: { base: "#f4f7f8", selected: "#e8eaeb", hover: "#f6f9f9" },
            eWhite: { base: "#ffffff", selected: "#cccccc", hover: "#ffffff" },
            eSuccess: { dark: "#12805c", light: "#ddffdd" },
            eDanger: { dark: "#c9252d", light: "#ffdddd" },
            eInformation: {
              dark: "#0d66d0",
              light: "#ddffff",
              hover: "#3D84D9",
            },
            eWarning: { dark: "#ffe500", light: "#ffffcc" },
          },
          typography: {
            fontFamily: state.parametersWidgets.ParameterLookup.BASE_FONT,
            h6: {
              fontSize: "1.125rem",
            },
            button: {
              textTransform: "capitalize",
            },
          },
          overrides: {
            MuiCssBaseline: {
              "@global": {
                "@font-face": [
                  // notoSansRegular,
                  // notoSansLight,
                  // notoSansSemiBold,
                  // notoSansBold,
                ],
              },
            },
          },
        });

        this.setState({
          theme: theme,
        });
      }
    });
  }

  render() {
      return (
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
          return this.showThirdPartyRedirectInProgress ? ( 
            <Typography variant="h4" style={{ marginTop: "10%" }} align="center">
              Redirecting...
            </Typography>
          ) : this.state.redirectNative === "initiate-payment" ? (
            <Payment
              totalAmount={this.amount}
              allData=""
              selectBills={this.accounts}
              errorMessage=""
              buttonLabel=""
              saId=""
              isNative={true}
            />
          ) : (
            <ThemeProvider theme={this.state.theme}>
              <TitleComponent />
              <CssBaseline />
              <FetchLabels />
              {/* TODO: Change change-coded ltr/rtl and take from language type */}
              <div dir="ltr">
                {localStorage.getItem("nativeRedirect") === "PAYMENTCONFIRM" ? (
                  <Typography
                    variant="h4"
                    style={{ marginTop: "10%" }}
                    align="center"
                  >
                    Redirecting...
                  </Typography>
                ) : (
                  <Router history={history}>
                    <E_Layout />
                  </Router>
                )}
              </div>
              {!localStorage.getItem("nativeAppRediection") && (
                <>
                  <Hidden smUp>
                    <SwipeableInstallBanner />
                  </Hidden>
                  <Hidden xsDown mdUp>
                    <SwipeableInstallBanner />
                  </Hidden>
                </>
              )}
            </ThemeProvider>
          );
        }}
        </CacheBuster>
      );
  }
}

const actionCreators = {
  userDetails,
  updateMeterDetails,
  getAppReady_widget,
  getAppReady_Labels,
  browserDetect,
  userPreferences,
};

const connectedApp = connect(null, actionCreators)(App);
export { connectedApp as App };
