import { appConstants } from "../constants";

const initialState = {};
export function profileDetails(state = initialState, action) {
  switch (action.type) {
    case appConstants.PROFILE_DETAILS_SUCESS: {
      return {
        ...state,
        ...action.snack
      };
    }
    case appConstants.PROFILE_DETAILS_ERROR:
      return {
        ...state,
        ...action.snack
    };
    default:
      return state;
  }
}

export function getUserPreferences(state = initialState, action) {
  switch (action.type) {
    case appConstants.GET_USER_PREFERENCES_SUCCESS: {
      return {
        ...state,
        ...action.snack
      };
    }
    case appConstants.GET_USER_PREFERENCES_ERROR:
      return {
        ...state,
        ...action.snack
    };
    default:
      return state;
  }
}

export function accountInformation(state = initialState, action) {
  switch(action.type) {
    case appConstants.ACCOUNT_DETAILS_SUCESS: {
      return {
        ...state,
        ...action.snack
      };
    }
    case appConstants.ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        ...action.snack
    };
    default:
      return state;
  }
}


export function accountDetails(state = initialState, action) {
  switch (action.type) {
    case appConstants.UPDATE_ACCOUNT_DETAILS: {
      return {
        ...state,
        ...action.snack
      };
    }
    default:
      return state;
  }
}


