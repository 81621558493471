import axios from "axios";
import { config } from "../../enviroment";
import { refreshBearerService } from "../../services/refresh.bearer.service";

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
const envConfig = config;
let errCount = 0;
export var isTokenRefreshing = null;
const getExpirationDate = (jwtToken?: string): number | null => {
  if (!jwtToken) {
    return null;
  }
  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));
  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

const isExpired = (exp?: number) => {
  if (!exp) {
    return false;
  }
  return Date.now() > exp;
};

export const setIsTokenRefreshing = (isRefreshing) => {
  isTokenRefreshing = isRefreshing;
}

export const setupAxiosInterceptors = () => {
  const onRequestSuccess = async (config) => {
    let bearer = localStorage.getItem("bearer");
    bearer = JSON.parse(bearer);
    let accessToken = bearer.acessToken;
    let dataExpiration = getExpirationDate(accessToken);
    let timeCheck = isExpired(dataExpiration);
    if (timeCheck && !config._retry && config.url !== envConfig.urls.REFRESH_BEARER) {
      config._retry = true;
      isTokenRefreshing = isTokenRefreshing ? isTokenRefreshing : refreshBearerService.refreshBearerToken();
      await isTokenRefreshing;
      isTokenRefreshing = null;
    }
      bearer = localStorage.getItem("bearer");
      bearer = JSON.parse(bearer);
      accessToken = bearer.acessToken;
      if (bearer && window.location.pathname !== config.url.BILL_PAY) {
        const sessionId = bearer.sessionId;
        config.headers.SessionId = `${sessionId}`;
        // config.headers['Accept-Encoding']='gzip, deflate, br';
        config.headers.tenantCode = envConfig.constants.BASE_TENANT_CODE;
        config.headers.personId = bearer.accountDetails ? bearer.accountDetails.indentifiers.personId :"";
        config.headers.username = bearer.userName;
        config.headers["x-client-tenantCode"] =
          envConfig.constants.BASE_TENANT_CODE;

        if (config.url !== envConfig.urls.EXPIRE_SESSION) {
          config.headers.accessToken = `${accessToken}`;
          config.headers.isAgent = bearer?.isAgent;
        }
      } else {
        // if (window.location.href !== config.url.BILL_PAY) {
        //   commonService.logoutUser();
        // }
      }

      return config;
    // }
  };

  const onResponseSuccess = (response) => response;
  const onResponseError = async (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (
      err.config.url === envConfig.urls.AUTH_SERVICE ||
      err.config.url === envConfig.urls.PROFILE_DETAILS_DATA ||
      err.config.url === envConfig.urls.LABELS ||
      err.config.url === envConfig.urls.USAGE_SERVICE_BASE_URL ||
      err.config.url === envConfig.urls.ADMIN_SERVICE_BASE_URL
    ) {
      // if (status === 500 || status === 0) {
      // if (status === 500 ) {
      //   history.push(routePaths.RETRY);
      // }
    }
    // if (status === 403) {
    //   refreshBearerService.refreshBearerToken();
    // }

    let errMessage = "";
    if (err.response) {
      if (err.response.data.message) {
        errMessage = err.response.data.message;
      } else {
        errMessage = Array.isArray(err.response.data.errors)
          ? err.response.data.errors[0]["message"]
          : "";
      }
    }
        return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};
