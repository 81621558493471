import { combineReducers } from "redux";
import {
  parametersWidgets,
  snackbar,
  appLogo,
  profileIcon,
  profileDarkIcon,
  browserDetect
} from "./app.reducer";
import { labels } from "../reducers/labels.reducer";
import {
  profileDetails,
  accountInformation,
  accountDetails,
  getUserPreferences
} from "./home.reducer";
import { meterDetails } from "./app.usgae.reducer";
import { usageMeterDetails, usageUserData } from "./usgae.reducer";
import profileReducer from "../components/e_layout/e_header/e_profile_dropdown/model/profile-reducer";
import { ticketCategory } from "./ticket.reducer";
import {
  meterAlertsAllData,
  meterAlertsSearchInput,
  meterAlertsTableInput,
  meterAlertsPageInput,
  meterAlertsWithoutPagination,
} from "../components/alerts-notification/meter-alerts/model/meter-alerts-reducer";
import {
  sendbillPreferenceGetRoutingData, 
  sendbillPreferenceGetAlertsData,
  sendPaymentPreferenceData,
  handleChangeBillAlerts,
  handleChangeBillRouting,
  handleChangeBillsAndPayments,
  handleChangeBillAlertdata,
  handleChangeBillForecastWidgetPref
} from "../components/manage-preference/bill-preference/model/bill-preference-reducer";
import { UsageGraphData } from "../components/usage/model/usage-reducer";
import {announcementReducer,updateAnnouncementValue} from "../components/layout/announcement/model/announcement-reducer"
import { notificationDot } from "../components/layout/notification/model/notification.reducer"
import {billsandPaymentsHistoryData} from "../components/bills-and-payments-hisrory-postpaid-prepaid/model/reducers";
import {
  alertsPriorityDataDisplay
} from "../components/home/alerts-priority/model/alert-priority-reducer";
import { billForecastReducer,sendBillForecastPreferenceData  } from "../components/home/bill-forecast/model/bill-forcast-reducer";
import energyPlanDetails from "../components/energy-plans/model/reducers";
import energyDetailsPages from "../components/energy-details-page/model/reducers";
import { e_meterAlertsDataCount ,e_updateDotCount} from "../components/e_alerts_notification/model/e_meter_alerts_reducer"
import {serviceFunc} from "../components/e_service/e_complaint/model/service-reducer"
const rootReducer = combineReducers({
  parametersWidgets,
  snackbar,
  labels,
  profileDetails,
  getUserPreferences,
  handleChangeBillForecastWidgetPref,
  accountInformation,
  meterDetails,
  usageMeterDetails,
  usageUserData,
  profileReducer,
  ticketCategory,
  FAccountInformation: accountDetails,
  appLogo,
  profileIcon,
  profileDarkIcon,
  meterAlertsAllData,
  meterAlertsSearchInput,
  meterAlertsPageInput,
  meterAlertsTableInput,
  meterAlertsWithoutPagination,
  sendbillPreferenceGetRoutingData,
  sendbillPreferenceGetAlertsData,
  handleChangeBillAlerts,
  handleChangeBillRouting,
  announcementReducer,
  updateAnnouncementValue,
  UsageGraphData,
  notificationDot,
  alertsPriorityDataDisplay,
  // getPrepaidChargePaymentsData,
  billForecastDetails: billForecastReducer,
  energyPlanDetails,
  billsandPaymentsHistoryData,
  handleChangeBillsAndPayments,
  sendPaymentPreferenceData,
  handleChangeBillAlertdata,
  energyDetailsPages,
  e_meterAlertsDataCount,
  e_updateDotCount,
  serviceFunc,
  browserDetect
})
export default rootReducer;