import React,{useState,useEffect} from "react";
import Routes from "../config/routes";
import E_Header from "./e_header/e_header";
import { makeStyles } from "@material-ui/core/styles";
import E_Footer from "./e_footer/e_footer";
import { useMediaQuery } from "react-responsive";
import E_SidebarController from "./e_sidebar/e_sidebar_controller";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "./e_error-bounder";
import E_Agent_Home from './e_agent_home'
import {
  userDetails,
} from "./../../actions/home.action";
import { useTranslationCustom } from "../../i18nOverrides";

const useStyle = makeStyles((theme)=>({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: "2",
    position: "absolute",
    top: "75px",
    left: "1%",
    right: "1%",
    height:"auto",
    overflowX:"hidden",
    '&::-webkit-scrollbar': {
      display:"block",
    },
    [theme.breakpoints.down("md")]: {
      bottom:"3.3rem !important",
     },
     [theme.breakpoints.up("md")]: {
      bottom:"2rem !important",
     },
     [theme.breakpoints.down("sm")]:{
      top:"7.7rem"
     }
  },
  desktopRoot:{
    left:"70px"
  },
  desktopRoot1:{
    background:"white",
    padding:"2%"
  },
}));
export default function E_Layout(props) {
  let classes = useStyle();
  const dispatch = useDispatch()
  let [open,setOpen] = useState(false);
  let [isNewAccount,setisNewAccount] = useState(false);
  const browserName = useSelector((store) => store?.browserDetect?.browserName);
  const isTabletOrMobile = useMediaQuery({ minWidth: 961 });
  let bearer = JSON.parse(localStorage.getItem("bearer"));
  const {t:labels, ready: labelReady} = useTranslationCustom("label-E_AGENT_SEARCH_DASHBOARD");
  let [searchContent,setSearchContent] = useState();
  useEffect(() => {
    if (labelReady) {
      setSearchContent(labels("AGENT_SEARCH_LABEL"))
    }
  }, [labelReady]);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleChange =()=>{
    setisNewAccount(true)
    // props.componentDidMount()
    dispatch(userDetails())
  }
  const handlefailed =()=>{
    // setSearchContent('Searched service a/c number not Found!')
    dispatch(userDetails())
    setisNewAccount(false)
    setSearchContent(labels("AGENT_SEARCH_ERROR_LABEL"))
  }
  return (
    // <ErrorBoundary>
    <>
   <E_SidebarController handleDrawerOpen={handleDrawerOpen} open={open}/>
      <E_Header handleDrawerOpen={handleDrawerOpen} handleChange={handleChange} handlefailed={handlefailed}/>
      {
        bearer?.isAgent == true && !bearer?.accountDetails && !isNewAccount?(
          <div className={`${classes.root} ${isTabletOrMobile && classes.desktopRoot} ${bearer?.isAgent && classes.desktopRoot1}`} 
             style={{overflowY: browserName === "firefox" ? "auto" : "overlay", scrollbarWidth:browserName === "firefox" ?"none":"auto"}}>
                     <E_Agent_Home searchContent={searchContent} />
          </div>
        ):(
          <div className={`${classes.root} ${isTabletOrMobile && classes.desktopRoot}`} 
             style={{overflowY: browserName === "firefox" ? "auto" : "overlay", scrollbarWidth:browserName === "firefox" ?"none":"auto"}}>
            <Routes />
          </div>
        )

      }
      
       <E_Footer />
    </>
    // </ErrorBoundary>
  );
}
