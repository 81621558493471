import axios from "axios";
import { config } from "../../src/enviroment";
import moment from "moment";
import {apolloClientService} from './apollo-client';

const endPoints = config.urls;

export const usageService = {
  electricityUsageTrendGraph,
  accountDetais,
  paymentReceipt,
  billDetails,
  prePaymentProcessing,
  downloadData,
  meterConsumtion,
  verifyPaymentHash,
  prePaymentProcessingBillPay,
  usageWeekGraph,
  billComparison,
};
async function usageWeekGraph(dates,saId,accountId) {
    
  const GET_CONTENT=`{getUsageWeek(input:{displayMode:"D2BW",startDate:"`+dates.from_date+`",endDate:"`+dates.to_date+`",
  additionalIdentifiers: [{
    name: "mode",
    value: "HIGHEST_USAGE_DAYS"
    },
    {
      name: "accountId",
      value: "` +accountId +`"
    },
    {
      name: "saId",
      value: "` + saId + `"
    }
  ]
  })
          {startOfTheWeek usageUnit usage{ cost currency intervalName usage}}}`
  let headers={
          saId: saId,
          accountId:accountId
          }
  return await apolloClientService.ApolloClientgqlsUsage(GET_CONTENT,headers,"cache-first");
  // return new Promise((resolve, reject) => {
  //   axios
  //     .post(config.urls.USAGE_SERVICE_BASE_URL, {
  //       query:
  //         `{getUsageWeek(input:{displayMode:"D2BW",startDate:"`+dates.from_date+`",endDate:"`+dates.to_date+`"})
  //         {startOfTheWeek usageUnit usage{ cost currency intervalName usage}}}`,
  //     },
  //     {
  //       headers: {
  //         saId: saId,
  //         accountId:accountId
  //       },
  //     })
  //     .then(function(response) {
  //       resolve(response.data);
  //     })
  //     .catch(function(error) {
  //       reject(error);
  //     });
  // });
}
async function electricityUsageTrendGraph(
  usageGraphInput,
  accountId,
  saId,
  dashboardUsage
) {
  let startDate = moment(usageGraphInput.year).startOf("year");
  let endDate = moment(usageGraphInput.year).endOf("year");
  let startFormat = moment(startDate).format("YYYY-MM-DD");
  let endFormat = moment(endDate).format("YYYY-MM-DD");

    const GET_CONTENT=`{getUsageTrends(input:{displayMode:"`+usageGraphInput.displayMode+`",year:"`+usageGraphInput.year+`"}){usageQuantity{ intervalName intervalShortName intervalDateTime quantity}usageUnit}}`;
    let headers={
            saId: saId,
            accountId:accountId
            }
    return apolloClientService.ApolloClientgqlsUsage(GET_CONTENT,headers);
    // return new Promise((resolve, reject) => {
    // axios
    //   .post(
    //     config.urls.USAGE_SERVICE_BASE_URL,
    //     {
    //       query:
    //       `{getUsageTrends(input:{displayMode:"`+usageGraphInput.displayMode+`",year:"`+usageGraphInput.year+`"}){usageQuantity{ intervalName intervalShortName intervalDateTime quantity}usageUnit}}`,
    //     },
    //     {
    //       headers: {
    //         accountId: accountId,
    //         saId: saId,
    //       },
    //     }
    //   )
    //   .then(function(response) {
    //     resolve(response);
    //   })
    //   .catch(function(error) {
    //     reject(error);
    //   });
  // });
}
// async function electricityUsageTrendGraph(usageGraphInput,accountId) {
//   return axios.post(
//     `${endPoints.USAGE_SERVICE_ENDPOINT}usage-trends`,
//     usageGraphInput
//   );
// }
async function meterConsumtion(apiInput, accountId) {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let startDate = moment(apiInput.year).startOf("year");
  let endDate = moment(apiInput.year).endOf("year");
  let startFormat = moment(startDate).format("YYYY-MM-DD");
  let endFormat = moment(endDate).format("YYYY-MM-DD");
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query:
            `{
            getMeterReads(input:{startDate:"` +
            startFormat +
            `",
              endDate:"` +
            endFormat +
            `"})
            { usageQuantity startdate endDate usageUnit saId} } 
       `,
        },
        {
          headers: {
            accountId: accountId,
            tenantCode: tenantCode,
          },
        }
      )
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
function accountDetais() {
  return axios.get(`${endPoints.USAGE_SERVICE_ENDPOINT}account-details`);
}

async function paymentReceipt(accountId, payId) {
  let tenantCode = config.constants.BASE_TENANT_CODE;

  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `{getPaymentReceipt{accountNumber,paymentEventId,paymentDate,paymentStatus,cardType,cardNumber,premise,amountPaid,amountPaidInWords,matchIdType,matchIdValue,payId,payType,payMode}}
       `,
        },
        {
          headers: {
            accountId: accountId,
            payId: payId,
            tenantCode: tenantCode,
          },
        }
      )
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function billDetails(accountId, billId) {
  let billHeader = "";
  if (billId) {
    billHeader = {
      tenantCode: config.constants.BASE_TENANT_CODE,
      accountId: accountId,
      billId: billId,
    };
  } else {
    billHeader = {
      tenantCode: config.constants.BASE_TENANT_CODE,
      accountId: accountId,
    };
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `{getBillDetails{   
                   accountDetails {     
                         name, 
                         email,
                         phone, 
                         address,
                         accountId, 
                         currency
                        },       billDetails {     
                           billMessages, billRouting, ,billNumber,        total,        billDueDate,        billDate,       totalAmountDue,        billRoute,  totalCorrections, totalAdjustments,billPaymentStatus   }      
                            summaryOfCharges{  billSegmentSequenceNumber,     headerSequenceNumber   saId,          saInfo, premiseInfo,endDate,startDate,rateDesc,billSegmentPeriod,billSegmentInfo,          name,           value{        sequenceNumber,    name,           value         }     
        }
        }}
       `,
        },
        {
          headers: billHeader,
        }
      )
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function prePaymentProcessing(connectionId, totalSelAmount, paymentType) {
  let tenantCode = config.constants.BASE_TENANT_CODE;

  let APIquery =
    `query {
    prePaymentProcessing(
      input: {
        RequestInput:{
        Amount: ` +
    totalSelAmount +
    `
        PaymentType:"` +
    paymentType +
    `"
        tenantCode: "` +
    tenantCode +
    `"
        Quantity: 1
        ConnectionId: "` +
    connectionId +
    `"
      }
    }
    ) 
  }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function downloadData(accountId, code, billId, paymentId, userName,startDate,endDate,languageCode) {
  let downloadHeader = "";
  let primaryKeyType = "";
  let tenantCode = config.constants.BASE_TENANT_CODE;
  if (billId) {
    downloadHeader = {
      tenantCode: tenantCode,
      accountId: accountId,
      billId: billId,
    };
    primaryKeyType = "billId";
  } else if (paymentId) {
    downloadHeader = {
      tenantCode: tenantCode,
      accountId: accountId,
      payId: paymentId,
    };
    primaryKeyType = "payId";
  } else {
    downloadHeader = {
      tenantCode: tenantCode,
      accountId: accountId,
    };
    primaryKeyType = "accountId";
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.USAGE_SERVICE_BASE_URL,
        {
          query: `query{ 
            getBase64Document(input: {
                documentCode:"`+code+`"
                tenantCode:"`+tenantCode+`",
                languageCode:"`+languageCode+`",
                billId:"`+billId+`",
                payId:"`+paymentId+`",
                startDate:"`+startDate+`",
                endDate:"`+endDate+`",
              })
            }`,
        },
        {
          headers: downloadHeader,
        }
      )
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function verifyPaymentHash(
  status,
  statusMessage,
  confirmationId,
  merchantId,
  ezConnectResponseDate,
  amount,
  currencyCode,
  merchantSessionId,
  pun,
  bankId,
  cardExpiryDate,
  cardHolderName,
  cardNumber,
  secureHash
) {
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.USAGE_SERVICE_BASE_URL, {
        query:
          `{
            verifyPaymentHash(input:{Status:"` +
          status +
          `",StatusMessage:"` +
          statusMessage +
          `",
            ConfirmationId:"` +
          confirmationId +
          `", MerchantId:"` +
          merchantId +
          `",
            EZConnectResponseDate:"` +
          ezConnectResponseDate +
          `",Amount:` +
          amount +
          `,
            CurrencyCode:` +
          currencyCode +
          `,MerchantModuleSessionId:"` +
          merchantSessionId +
          `",
            PUN:"` +
          pun +
          `",BankID:"` +
          bankId +
          `",CardExpiryDate:"` +
          cardExpiryDate +
          `",CardHolderName:"` +
          cardHolderName +
          `",
            CardNumber:"` +
          cardNumber +
          `", SecureHash:"` +
          secureHash +
          `"}){
              Status,StatusMessage,ConfirmationId,MerchantId,EZConnectResponseDate,Amount
              ,CurrencyCode,MerchantModuleSessionId,PUN,BankID,CardExpiryDate,CardHolderName,
              CardNumber
            }
          }
   `,
      })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function prePaymentProcessingBillPay(
  billId,
  totalSelAmount,
  creditCardGatewayCode
) {
  let tenantCode = config.constants.BASE_TENANT_CODE;

  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.PREPAYMENTBILL, {
        RequestInput: {
          Amount: totalSelAmount,
          PaymentType: creditCardGatewayCode,
          tenantCode: tenantCode,
          source: "UNPAID_PAY",
          billId: billId,
        },
      })

      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function billComparison(accountId) {
  return new Promise((resolve, reject) => {
    let tenantCode = config.constants.BASE_TENANT_CODE;
    axios
      .post(config.urls.USAGE_SERVICE_BASE_URL, {
        query:
          `{getAllBills(input:{ accountId:"` +
          accountId +
          `",tenantCode:"` +
          config.constants.BASE_TENANT_CODE +
          `"}){
                history{date,
                  amount,currency}
              }}`,
      },
      {
        headers: {
          accountId: accountId,
          tenantCode: tenantCode,
        },
      })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
