import { serviceConstants } from "./service-constants";
const initialState = {
    updatedValue: null,
};

export function serviceFunc (state = initialState, action) 
{
    switch (action.type) {        
        case serviceConstants.E_TAB :
            return {
                ...state,
                updatedValue:action.data.updatedValue
            }
        default:
            return state
    }
}