import React from "react";
import { Hidden } from "@material-ui/core";
import E_SideBar from "./desktop_sidebar/e_sidebar";
import E_MobileSideBar from "./e_mobile_sidebar/e_mobile_sidebar";

export default function E_SidebarController({open,handleDrawerOpen}) {
   
  return (
    <>
      <Hidden smDown>
        <E_SideBar />
      </Hidden>
      <Hidden mdUp>
        <E_MobileSideBar open={open} handleDrawerOpen={handleDrawerOpen}  />
      </Hidden>
    </>
  );
}
