// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import axios from "axios";
import SWHelper from './SWHelper';
import {config as env} from "./enviroment";
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

window.addEventListener('beforeunload', async () => {
  if (window.swNeedUpdate) {
    await SWHelper.skipWaitingWhenSolo();
  }
});

export function register(config) {
  console.log(isLocalhost)
  if (env.NodeVariables.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL('', window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `/service-worker.js`;
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      if (registration.waiting && registration.active) {
        window.swNeedUpdate = true;
      }
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              window.swNeedUpdate = true;
              SWHelper.prepareCachesForUpdate().then();
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              );
              // if(config && !config.isSwPatchDone) {
              //   SWHelper.skipWaiting().then();
              // }
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister().then();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export function registerNotification() {
  const publicApiKey = env.confidentialClient.SW_VAP_PUBLIC_KEY;
  let oldTokenEndpoint = localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):"";
  let accountId = JSON.parse(localStorage.getItem("bearer"))?.accountDetails?.indentifiers?.accountId;
  if (accountId && !Array.isArray(accountId)) {
    accountId = [accountId]
  }
  if ('serviceWorker' in navigator) {
    run().catch(error => console.error(error));
  }
  async function run() {
    // console.log('Registering service worker');
    // const registration = await navigator.serviceWorker.register('/worker.js');
    // console.log('Registered service worker');
    const registration = await navigator.serviceWorker.getRegistration('/')
    if(registration) {
      const subscription = await registration.pushManager.
        subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicApiKey)
        });
      let token=(JSON.parse(JSON.stringify(subscription)));
      let accountIdSubscriptionsList = JSON.parse(localStorage.getItem("accountIdSubscriptionsList"));
      let alreadySubscribedList = [], additionalNewSubscriptionList = [], removableSubscriptionsList;
      accountId.forEach(id => {
        if(accountIdSubscriptionsList?.includes(id)) {
          alreadySubscribedList.push(id);
          accountIdSubscriptionsList = accountIdSubscriptionsList.filter(item => item !== id);
        } else {
          additionalNewSubscriptionList.push(id);
        }
      })
      removableSubscriptionsList = accountIdSubscriptionsList;
      if(oldTokenEndpoint != token.endpoint || additionalNewSubscriptionList?.length){
        axios({
            url: env.urls.COMMUNICATION_BASE_URL + 'pushNotification/saveToken',
            method: 'post',
            data: {
              token: JSON.stringify(JSON.parse(JSON.stringify(subscription))),
              tenantCode: env.constants.BASE_TENANT_CODE,
              accountId: additionalNewSubscriptionList,
            }
          }).then(function(response) {
            localStorage.setItem('token',JSON.stringify(token.endpoint));
            localStorage.setItem('accountIdSubscriptionsList',JSON.stringify([...alreadySubscribedList, ...additionalNewSubscriptionList]));
            console.log("token saved successfully");
          }).catch(function(error) {
            console.log(error);
          });
      }
      if(oldTokenEndpoint && removableSubscriptionsList?.length) {
        let oldToken = Object.assign({},token)
        oldToken.endpoint = oldTokenEndpoint;
        axios({
          url: env.urls.COMMUNICATION_BASE_URL + 'pushNotification/removeToken',
          method: 'post',
          data: {
            token: JSON.stringify(JSON.parse(JSON.stringify(oldToken))),
            tenantCode: env.constants.BASE_TENANT_CODE,
            accountId: removableSubscriptionsList,
          }
        }).then(function(response) {
          localStorage.setItem('accountIdSubscriptionsList',JSON.stringify([...alreadySubscribedList, ...additionalNewSubscriptionList]));
          console.log("token removed successfully");
        }).catch(function(error) {
          console.log(error);;
        });
      }
      console.log('Tokens : ',JSON.parse(JSON.stringify(subscription)));
    }
  }
 
  function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}

let deferredPrompt;
  window.addEventListener('beforeinstallprompt', (e) => {
  const addBtn = document.getElementById('INSTALL_PWA_APP');
  const mainBackground = document.getElementById('mainBackground');
  const rocketchat = document.getElementsByClassName('rocketchat-widget');
  if(rocketchat.length>0) rocketchat[0].style.display = "block";
  if(mainBackground){
    mainBackground.style.display='none';
  }else{
    return
  }
  e.preventDefault();
  deferredPrompt = e;

  if(addBtn && mainBackground){

  addBtn.style.display = 'block';

  mainBackground.style.display='block';

  if(rocketchat.length>0) rocketchat[0].style.display = "none";
  addBtn.addEventListener('click', () => {
    addBtn.style.display = 'none';
    mainBackground.style.display = 'none';
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  });
}else{
  return;
}
});
