import { languageConstants } from "../constants/language.constants";
import { appConstants } from "../constants/app.constants";
import { appService } from "../services/index";

export const languageActions = {
  downloadLabels
};
export{
  getAppReady_Labels,
}
function getAppReady_Labels(languageCode){
  return dispatch => {
    dispatch(languageActions.downloadLabels(languageCode))
  }
}
function downloadLabels(languageCode) {
  return async dispatch => {
    dispatch(request());
    await appService
      .downloadLabels(languageCode)
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => {
        let snack = {
          message: "Error in getting lables",
          status: true,
          type: "error",
          reload: "true"
        };
        dispatch(failure(snack));
      });
  };

  function request(lan) {
    return { type: languageConstants.LABELS_REQUEST };
  }
  function success(language) {
    return { type: languageConstants.LABELS_SUCCESS, language };
  }
  function failure(error, snack) {
    return { type: appConstants.SNACKBAR_SUCCESS, snack };
  }
}

