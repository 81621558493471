import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  IconButton,
  Drawer,
  Box,
  Select,
  Tooltip,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { getSaIcon } from "../e_sa_switcher/e_sa_icons";
import EAccount_SA_Switched_Warning from "../../../e_account_sa_switch_warning/e_account-sa-switched-warning";
import { updateMeterDetails } from "../../../../actions/home.action";
import { getPremiseDetailsForAcct } from "../e_accountDetails/view/_accountDropdown";
import { SwitcherIcon } from "./view/switcherSvgIcon";
import { Skeleton } from "@material-ui/lab";
import { useTranslationCustom } from "../../../../i18nOverrides";
const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.ePastelColor2.base,
    padding: "0px 10px 5px 10px",
  },
  switcherHeader: {
    background: theme.palette.ePrimary.base,
    color: theme.palette.whiteColor.main,
  },
  closeIconStyle: {
    color: theme.palette.whiteColor.main,
  },
  drawerOverrides: {
    bottom: "0 !important",
    right: "0 !important",
    borderTopLeftRadius: "0.4rem",
    borderTopRightRadius: "0.4rem",
  },
  cancelBtnStyle: {
    border: `1px solid ${theme.palette.eSecondary.base}`,
    borderRadius: "2rem",
    padding: "0.2rem 1.5rem",
    color: theme.palette.eSecondary.base,
  },
  OkBtnStyle: {
    background: theme.palette.eSecondary.base,
    borderRadius: "2rem",
    padding: "0.2rem 1.5rem",
    color: theme.palette.eWhite.base,
    marginLeft: "0.5rem",
    "&:hover": {
      backgroundColor: theme.palette.eSecondary?.base,
    },
  },
  singleItem: {
    padding: "6px 9px",
    border: "2px solid " + theme.palette.eLight.base,
    borderRadius: "4px",
  },
  btnContainer: {
    background: theme.palette.eLight.base,
  },
  tooltipStyle: {
    background: theme?.palette?.eWhite?.base,
    padding: "0.5rem 0.8rem",
    color: theme.palette.ePrimary.base,
    fontSize: "0.7rem",
    border: "1px solid " + theme.palette.ePrimary.base,
    fontWeight: "bold",
  },
}));

export const STYLED_SELECT = withStyles((theme) => ({
  root: {
    fontSize: "0.875rem",
    borderRadius: "4px",
    padding: "9px 9px",
    justifyContent: "flex-start",
    border: "2px solid " + theme.palette.eLight.base,
    whiteSpace: "unset",
    wordBreak: "break-all",
  },
  select: {
    "&:focus": {
      borderRadius: "4px",
    },
    whiteSpace: "pre-line",
  },
  icon: {
    color: theme.palette.eDark?.base,
  },
}))(Select);

export const STYLED_MENU_ITEM = withStyles((theme) => ({
  root: {
    fontSize: "0.8rem",
    justifyContent: "flex-start",
  },
}))(MenuItem);

export default function EaccountSaSwitcherMd() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showSwitcher, setShowSwitcher] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [saId, setSaId] = useState(null);
  const [selectedMeterDetails, setSelectedMeterDetails] = useState(null);
  let [openDialog, setOpenDialog] = useState(false);
  let [showWarning, setShowWarning] = useState(false);
  let [isDetailsSwitched, setIsDetailsSwitched] = useState(false);
  const { t: labels, ready: labelReady, i18n } = useTranslationCustom(
    "label-E_ACCOUNT_SA_SWITCHER_MD"
  );
  const hideRocketChat = () => {
    window.RocketChat(function() {
      this.hideWidget();
    });
    const rocketchat = document.getElementsByClassName("rocketchat-widget");
    if (rocketchat.length > 0) rocketchat[0].style.display = "none";
  };

  const showRocketChat = () => {
    window.RocketChat(function() {
      this.showWidget();
    });
    const rocketchat = document.getElementsByClassName("rocketchat-widget");
    if (rocketchat.length > 0) rocketchat[0].style.display = "block";
    setSelectedMeterDetails(meterDetails);
  };

  const switcherClickHandler = () => {
    hideRocketChat();
    setShowSwitcher(true);
  };

  const closeSwitcherHandler = () => {
    setShowSwitcher(false);
    setIsDetailsSwitched(false);
    setOpenDialog(false);
    showRocketChat();
  };

  const accountList = useSelector(
    (store) =>
      store?.accountInformation?.personAcccountDetail?.personAccountList
  );

  const primaryIdentifier = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.PRIMARY_IDENTIFIER
  );

  const meterDetails = useSelector((store) => store?.meterDetails);

  useEffect(() => {
    if (meterDetails) {
      setSelectedMeterDetails(meterDetails);
      localStorage.setItem("accountSelected", meterDetails[primaryIdentifier]);
    }
  }, [meterDetails, primaryIdentifier]);

  useEffect(() => {
    if (selectedMeterDetails) {
      setAccountId(selectedMeterDetails[primaryIdentifier]);
      setSaId(selectedMeterDetails?.getSaInfo?.saId);
    }
  }, [selectedMeterDetails, primaryIdentifier]);

  const handleAccountIdChange = (event) => {
    setAccountId(event.target.value);
    updateComponentMeterDetails(event.target.value, null);
    setIsDetailsSwitched(true);
  };

  const handleSaIdChange = (event) => {
    setSaId(event.target.value);
    updateComponentMeterDetails(null, event.target.value);
    setIsDetailsSwitched(true);
  };

  const updateComponentMeterDetails = (accountId, saId) => {
    if (accountList && accountId) {
      accountList.forEach((account, k) => {
        let selectedConnetionDetails = account?.accountSaList?.forEach(
          (sa, i) => {
            if (Number(account?.[primaryIdentifier]) === Number(accountId)) {
              if (i === 0) {
                const saDetails = { getSaInfo: sa };
                localStorage.removeItem("enabled_state");
                setSelectedMeterDetails({ ...account, ...saDetails });
              }
            }
          }
        );
      });
    } else if (accountList && saId) {
      accountList &&
        accountList.map((account) => {
          return (
            selectedMeterDetails?.["accountId"] === account?.["accountId"] &&
            selectedMeterDetails?.accountSaList.map((sa) => {
              if (sa?.saId === saId) {
                const saDetails = { getSaInfo: sa };
                setSelectedMeterDetails({ ...account, ...saDetails });
              }
            })
          );
        });
    }
  };

  const updateDetails = () => {
    setOpenDialog(false);
    setIsDetailsSwitched(false);
    setShowSwitcher(false);
    let saInfo = selectedMeterDetails.getSaInfo;
    delete selectedMeterDetails.getSaInfo;
    dispatch(updateMeterDetails(selectedMeterDetails, saInfo));
    i18n.changeLanguage();
  };

  const handleOkClick = () => {
    if (isDetailsSwitched) {
      setShowWarning(true);
      setOpenDialog(true);
    } else {
      closeSwitcherHandler();
    }
  };

  return (
    <>
      <IconButton onClick={switcherClickHandler}>
        <SwitcherIcon color={"primary"} fontSize="large" />
      </IconButton>
      {showWarning ? (
        <EAccount_SA_Switched_Warning
          heading="saId-home"
          openDialog={openDialog}
          sameDetails={closeSwitcherHandler}
          updateDetails={updateDetails}
        />
      ) : null}
      <Drawer
        anchor={"bottom"}
        open={showSwitcher}
        onClose={closeSwitcherHandler}
        classes={{ paper: classes.drawerOverrides }}
      >
        <Box>
          <Box
            className={classes.switcherHeader}
            display="flex"
            flexDirection={"row"}
            justifyContent="space-between"
            px={2}
          >
            <Box pt={"0.875rem"}>
              <Typography variant="body1">
                {labelReady ? labels("E_SWITCHER_HEADER") : <Skeleton />}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={closeSwitcherHandler}>
                <CloseIcon
                  className={classes.closeIconStyle}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          </Box>
          <Box px={2} py={2}>
          <InputLabel id="acct-id-drop-label">
            <Typography variant="body2">
              {labelReady ? labels("E_SWITCHER_ACC_ID_LABEL") : <Skeleton />}
            </Typography>
            </InputLabel>
            {accountList && accountList.length === 1 ? (
              <Tooltip
                placement="top"
                title={`${
                  accountList?.[0]?.[primaryIdentifier]
                } - ${getPremiseDetailsForAcct(accountList?.[0])}`}
                classes={{ tooltip: classes.tooltipStyle }}
              >
                <Typography className={`${classes.singleItem}`}>
                  {accountList?.[0]?.[primaryIdentifier]}
                </Typography>
              </Tooltip>
            ) : (
              <STYLED_SELECT
                IconComponent={ExpandMoreSharpIcon}
                value={accountId}
                disableUnderline
                fullWidth
                onChange={handleAccountIdChange}
                renderValue={(value) => value.split("-")[0]}
                labelId="acct-id-drop-label"
              >
                {accountList &&
                  accountList.map((account, k) => {
                    return (
                      <STYLED_MENU_ITEM
                        key={k}
                        value={account?.[primaryIdentifier]}
                      >
                        {account?.[primaryIdentifier]} -{" "}
                        {getPremiseDetailsForAcct(account)}
                      </STYLED_MENU_ITEM>
                    );
                  })}
              </STYLED_SELECT>
            )}
          </Box>
          <Box px={2} pb={2}>
            <InputLabel id="sa-id-drop-label">
              <Typography variant="body2">
                {labelReady ? labels("E_SWITCHER_SA_ID_LABEL") : <Skeleton />}
              </Typography>
            </InputLabel>
            {selectedMeterDetails &&
            selectedMeterDetails?.accountSaList &&
            selectedMeterDetails?.accountSaList?.length === 1 ? (
              <Typography className={`${classes.singleItem}`}>
                {getSaIcon(
                  selectedMeterDetails?.accountSaList?.[0]?.serviceType
                )}
                &nbsp;
                {selectedMeterDetails?.accountSaList[0]?.saId}
              </Typography>
            ) : (
              <STYLED_SELECT
                IconComponent={ExpandMoreSharpIcon}
                disableUnderline
                fullWidth
                value={saId}
                onChange={handleSaIdChange}
                labelId="sa-id-drop-label"
              >
                {selectedMeterDetails?.accountSaList &&
                  selectedMeterDetails?.accountSaList?.map((sa, k) => {
                    return (
                      <STYLED_MENU_ITEM key={k} value={sa?.saId}>
                        {getSaIcon(sa?.serviceType)}&nbsp;{sa?.saId}
                      </STYLED_MENU_ITEM>
                    );
                  })}
              </STYLED_SELECT>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent={"flex-end"}
            pb={2}
            pt={1}
            px={2}
            className={classes.btnContainer}
          >
            <Button
              className={classes.cancelBtnStyle}
              onClick={closeSwitcherHandler}
              aria-label="Cancel"
            >
              {labelReady ? labels("E_SWITCHER_CANCEL_LABEL") : <Skeleton />}
            </Button>
            <Button
              className={classes.OkBtnStyle}
              onClick={handleOkClick}
              aria-label="Ok"
            >
              {labelReady ? labels("E_SWITCHER_OK_LABEL") : <Skeleton />}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
