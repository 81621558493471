import React, { useEffect, useState } from "react";
import { Grid, Select, Typography, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import EAccount_SA_Switched_Warning from "../../../e_account_sa_switch_warning/e_account-sa-switched-warning";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { updateMeterDetails } from "../../../../actions/home.action";
import {
  STYLED_MENU_ITEM,
  STYLED_SELECT,
} from "../e_accountDetails/view/_accountDropdown";
import { config } from "../../../../enviroment";
import { getSaIcon } from "./e_sa_icons";
import { useTranslationCustom } from "../../../../i18nOverrides";
import { useMediaQuery } from "react-responsive";
const useStyles = makeStyles((theme) => ({
  singleSaStyle: {
    backgroundColor: theme.palette.ePrimary?.base,
    borderRadius:"4px",
    padding:"3px 9px",
    border: "1px solid " +theme.palette.ePrimary?.base+ " !important",
    justifyContent: "flex-start",
    color:theme.palette.eWhite?.base,
    fontSize: "0.875rem",
    maxWidth: "170px",
    width: "170px",
    maxHeight: "45px",
    height: "45px",
    lineHeight: "2.5",
    "& .MuiSvgIcon-root": {
      marginRight: "3px",
    },
  },
  accountIdColor: {
    color: "initial !important",
  },
  blueTextColor: {
    /*color: theme.palette.ePrimary?.base,
    maxWidth: "17vw",*/
    width: "170px",
    fontSize: "0.875rem",
    maxWidth: "170px",
    maxHeight: "37px",
    borderRadius: "4px",
    "& .MuiSelect-selectMenu": {
      paddingRight: "18px",
      "& .MuiTypography-subtitle1": {
        backgroundColor: theme.palette.ePrimary?.base,
        padding: 0,
        maxHeight: "37px",
        height: "37px", 
        width: "20vh",
        lineHeight: 2.5,
        "& .MuiSvgIcon-root": {
          marginRight: "3px",
        },
      },
    },
  },
  list:{
    borderRadius: "0 0 4px 4px !important",
    "& .MuiList-padding": {
      padding:0,
      "& .MuiMenuItem-root": {
        padding: 0,
      }
    } ,
  },
  mobilePadding:{
    padding:"6px 1px"
  },
  dropdownblueText: {
    color:theme.palette.eWhite?.base,
    maxWidth: "170px",
    padding:"2px 9px",
    backgroundColor: theme.palette.ePrimary?.hover,
    borderRadius:"0",
    justifyContent: "flex-start",
    fontSize: "0.875rem",
    width: "100%",
    maxHeight: "40px",
    height: "40px", 
    lineHeight:2.5,
    "&:hover": {
      backgroundColor: theme.palette.ePrimary?.base,
    },  
  },
}));
export const SaSwitcher = () => {
  let classes = useStyles();
  let dispatch = useDispatch();
  let [connection, setConnection] = useState("");
  let [serviceAgreement, setServiceAgreement] = useState(null);
  let [showWarning, setShowWarning] = useState(false);
  let [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 380 });

  const {t:label, ready: labelReady, i18n} = useTranslationCustom("label-E_CUSTOMER_PROFILE");

  let primaryIdentifier = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.PRIMARY_IDENTIFIER
  );

  let meterDetails = useSelector((state) => state?.meterDetails);
  let saId = useSelector((state) => state?.meterDetails?.getSaInfo?.saId);
  let accountDetails = useSelector(
    (state) =>
      state?.accountInformation?.personAcccountDetail?.personAccountList
  );
  useEffect(() => {
    if (saId) {
      setConnection(saId);
    }
  }, [saId]);

  const handleChange = (e) => {
    setServiceAgreement(meterDetails?.getSaInfo?.saId);
    setConnection(e.target.value);
    setShowWarning(true);
    setOpenDialog(true);
  };
  const ddlMenuProps = {
    disableScrollLock: false,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    getContentAnchorEl: null,
    classes: { paper: classes.list },
  };
  const sameDetails = () => {
    setOpenDialog(false);
    setConnection(serviceAgreement);
    setServiceAgreement(null);
  };
  const updateDetails = () => {
    setOpenDialog(false);
    setServiceAgreement(null);
    accountDetails &&
      accountDetails.map((account) => {
        return (
            meterDetails?.[primaryIdentifier] === account?.[primaryIdentifier] &&
              meterDetails?.accountSaList.map((saItem) => {
                return (
                  saItem?.saId === connection &&
                  dispatch(updateMeterDetails(account, saItem))
                );
              })
        );
      })
    i18n.changeLanguage();
  };
  return (
    <>
      {showWarning ? (
        <EAccount_SA_Switched_Warning
          heading="saId-home"
          openDialog={openDialog}
          sameDetails={sameDetails}
          updateDetails={updateDetails}
        />
      ) : null}
      <Grid item xs={12}>
        {meterDetails?.accountSaList ? (
          meterDetails?.accountSaList.length > 1 ? (
            <STYLED_SELECT
              value={serviceAgreement || connection}
              onChange={handleChange}
              className={classes.blueTextColor}
              disableUnderline={true}
              MenuProps={ddlMenuProps}
              IconComponent={ExpandMoreSharpIcon}
            >
              {meterDetails?.accountSaList.map((item, k) => {
                return (
                  <STYLED_MENU_ITEM
                    key={k}
                    value={item?.saId}
                  >
                    <Typography variant="subtitle1" className={`${classes.dropdownblueText} ${!isMobile ? classes.mobilePadding :''}`}>{getSaIcon(item?.serviceType)}&nbsp;{item?.saId}</Typography>
                  </STYLED_MENU_ITEM>
                );
              })}
            </STYLED_SELECT>
          ) : (
            <Typography className={classes.singleSaStyle} variant="subtitle1">
              {getSaIcon(meterDetails.accountSaList?.[0]?.serviceType)}&nbsp;{meterDetails.accountSaList?.[0]?.saId}
            </Typography>
          )
        ) : (
          <Skeleton />
        )}
      </Grid>
    </>
  );
};
