import React, { useState, useEffect } from "react";
import {
  Avatar,
  Grow,
  Paper,
  ClickAwayListener,
  Menu,
  MenuItem,
  Popper,
  Tooltip,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../../helpers";
import { routePaths } from "../../../config/route-paths";
import { updateSelectedVal } from "./model/profile-action";
import { commonService } from "../../../../services/common.service";
import { useMediaQuery } from "react-responsive";
import "../../../common/common.css";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import { useTranslationCustom } from "../../../../i18nOverrides";

const StyleAvatar = withStyles((theme) => ({
  root: {
    background: theme.palette.ePrimary?.base,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  blueText: {
    color: theme.palette.ePrimary?.base,
    cursor: "pointer",
  },
  blueBox: {
    backgroundColor: theme.palette.ePrimary?.base,
    borderRadius: "4px",
    justifyContent: "flex-start",
    color: theme.palette.eWhite?.base,
    cursor: "pointer",
  },
  customerNameMenuItem: {
    "&:hover": {
      backgroundColor: theme.palette.ePrimary?.base,
      borderRadius: "0.25rem",
      border: "0.063rem solid " + theme.palette.ePrimary?.base + " !important",
      justifyContent: "flex-start",
      color: theme.palette.eWhite?.base,
    },
    "&:focus": {
      backgroundColor: theme.palette.ePrimary?.base,
      borderRadius: "0.25rem",
      border: "0.063rem solid " + theme.palette.ePrimary?.base + " !important",
      justifyContent: "flex-start",
      color: theme.palette.eWhite?.base,
    },
  },
  styleIndex: {
    zIndex: "1",
  },
  maxwidthName: {
    maxWidth: "15rem !important",
  },
  menuRoot: {
    minWidth: "10%",
  },
  profileName:{
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "9ch"
  },
  profileNameSection:{
    display: "flex"
  }
}));

export default function E_ProfileDropdown({ profileData }) {
  let [open, setOpen] = useState(false);
  let dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  let profileDetails = useSelector((store) => store?.profileDetails);
  let notification = useSelector((store) => store?.notificationDot?.data);
  const {t:labels, ready: labelReady}=useTranslationCustom("label-E_HEADER");
  let [manageProfile, setManageProfile] = useState(false);
  let [managePreference, setManagePreference] = useState(false);
  let [linkedAccount, setLinkedAccount] = useState(false);
  let [getHelp, setGetHelp] = useState(false);
  let isTabletOrMobile = useMediaQuery({ minWidth: 961 });
  let bearer = JSON.parse(localStorage.getItem("bearer"));
  if(bearer.isAgent){
    profileDetails={
      firstName: bearer?.agentAccountDetails?.firstName,
      lastName:bearer?.agentAccountDetails?.lastName
    }
  }

  useEffect(() => {
    profileData.map((item) => {
      item.widgetCode === "E_HEADER.MANAGE_PROFILE" && setManageProfile(true);
      item.widgetCode === "E_HEADER.MANAGE_PREFERENCE" &&
        setManagePreference(true);
      item.widgetCode === "E_HEADER.LINKED_ACCOUNT" && setLinkedAccount(true);
      item.widgetCode === "E_HEADER.GET_HELP" && setGetHelp(true);
    });
  }, profileData);

  let classes = useStyles();
  const handleCloseAway = () => {
    setOpen(false);
  };
  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleProfileClick = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    history.push(routePaths.PROFILE);
    dispatch(updateSelectedVal(event.target.value));
  };

  const handleManagePreference = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    history.push(routePaths.MANAGE_PREFERENCE);
    dispatch(updateSelectedVal(event.target.value));
    setOpen(false);
  };

  let handleLinkedAccount = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    history.push(routePaths.PROFILE);
    dispatch(updateSelectedVal(event.target.value));
    setOpen(false);
  };
  let handlehelp = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    history.push(routePaths.SELF_HELP);
    setOpen(false);
  };
  const logout = (event) => {
    setOpen(false);
    let unreadMeter = localStorage.getItem("unreadMeter");
    let unreadBill = localStorage.getItem("unreadBill");
    let unreadAnnouncement = localStorage.getItem("unreadAnnouncement");
    unreadMeter = JSON.parse(unreadMeter);
    unreadBill = JSON.parse(unreadBill);
    unreadAnnouncement = JSON.parse(unreadAnnouncement);

    if (
      unreadBill?.length > 0 ||
      unreadMeter?.length > 0 ||
      unreadAnnouncement?.length > 0 ||
      notification?.Meter === true ||
      notification?.Bill === true ||
      notification?.Outage === true ||
      notification?.Announcements === true
    ) {
      let ok = window.confirm(labels?.E_NOTIFICATION_LOGOUT_WARNING);
      if (ok === true) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        commonService.logoutUser();
      }
      // window.addEventListener("beforeunload", (event) => {
      //   event.returnValue = "labels.E_NOTIFICATION_LOGOUT_WARNING";
      // });
    } else {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      commonService.logoutUser();
    }
  };
  return (
    <>
      <div ref={anchorRef} onClick={handleToggle}>
        {profileDetails && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={
              open && isTabletOrMobile ? classes.blueBox : classes.blueText
            }
          >
            <Grid item>
              {" "}
              <StyleAvatar />
            </Grid>
            <Hidden mdDown>
              <Grid item className={classes.maxwidthName}>
                <Typography variant="subtitle2" className={[classes.profileNameSection,"wrapin3line"]} >
                  <div className={classes.profileName}>
                    {profileDetails?.firstName && profileDetails?.firstName}&nbsp;
                    {profileDetails?.lastName && profileDetails?.lastName}
                    </div>
                  <KeyboardArrowDownIcon />
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        )}
      </div>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleCloseAway}
        autoFocusItem={open}
        id="menu-list-grow"
        onKeyDown={handleListKeyDown}
        classes={{
          paper: classes.menuRoot,
        }}
        className={classes.blueText}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}

      >
        <Hidden mdUp>
          {profileDetails && (
            <MenuItem
              className={classes.blueBox}
              classes={{ root: classes.customerNameMenuItem }}
              id="profile-details-firstname"
              onClick={handleCloseAway}
            >
              <b>
                {profileDetails?.firstName && profileDetails?.firstName}
                &nbsp;
                {profileDetails?.lastName && profileDetails?.lastName}
              </b>
            </MenuItem>
          )}
        </Hidden>

        {manageProfile && (
          <MenuItem
            className={`profile-menu-item ${classes.blueText}`}
            id="profile-menu-option-details"
            value={0}
            onClick={handleProfileClick}
          >
            {labelReady ? labels("E_HEADER_MANAGE_PROFILE"): <Skeleton />}
          </MenuItem>
        )}
        {managePreference && (
          <MenuItem
            className={`profile-menu-item ${classes.blueText}`}
            id="profile-menu-option-manage-preferences"
            value={1}
            onClick={handleManagePreference}
          >
            {labelReady ? labels("E_HEADER_MANAGE_PREFERENCE"): <Skeleton />}
          </MenuItem>
        )}
        {linkedAccount && (
          <MenuItem
            className={`profile-menu-item ${classes.blueText}`}
            id="profile-menu-option-password"
            value={2}
            onClick={handleLinkedAccount}
          >
            {labelReady ? labels("E_HEADER_LINKED_ACCOUNTS"): <Skeleton />}
          </MenuItem>
        )}
        {getHelp && (
          <MenuItem
            className={`profile-menu-item ${classes.blueText}`}
            id="profile-menu-option-preferences"
            onClick={handlehelp}
          >
            {labelReady ? labels("E_HEADER_GET_HELP"): <Skeleton />}
          </MenuItem>
        )}
        <MenuItem
          className={`profile-menu-item ${classes.blueText}`}
          id="profile-menu-option-logout"
          onClick={logout}
        >
          {labelReady ? labels("E_HEADER_LOGOUT"): <Skeleton />}
        </MenuItem>
      </Menu>
     
    </>
  );
}
