import React, { useEffect, useRef, useState } from 'react'
import {
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    DialogActions,
    DialogTitle,
    DialogContentText,
  } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { useTranslationCustom } from '../../i18nOverrides';

const useStyles=makeStyles((theme)=>({
    dialogTitle:{
        background:theme.palette.ePrimary?.base,
        color:theme.palette.eWhite?.base,
        paddingLeft: "3%",
    },
    rightIcon: {
        textAlign: "-webkit-right",
    },
    closeIcon: {
        color: theme.palette.eWhite?.base,
    },
    buttonStyle: {
        borderRadius: 20,
        padding: "1% 5%",
        background: theme.palette.ePrimary?.base,
        color: theme.palette.eWhite?.base,
        "&:hover": {
          backgroundColor: theme.palette.ePrimary?.hover,
        },
        "&:focus": {
          border:"4px solid"+theme.palette.ePastelColor2?.base
        },
    },
    dialogContent:{
      color:theme.palette.eDark?.base +"!important"
    },
    alertIcon:{
      color: theme.palette.eTertiary?.base,
      width: "4rem",
      height: "auto",
      display: "block",
      margin: "auto",
     
    },
    dialogContainer:{
      ["@media (min-width:393px)"]: {
        maxWidth:"90% !important"
      },
      ["@media (min-width:768px)"]: {
        maxWidth:"60% !important"
      },
      ["@media (min-width:1024px)"]: {
        maxWidth:"40% !important"
      },
    },
}))

export default function EAccount_SA_Switched_Warning({
  heading,
  openDialog,
  sameDetails,
  updateDetails
}) {
    const style=useStyles()
    const saHeading="saId"
    const saHeadingHome="saId-home"
    const noActionBtn=useRef(null)
    const [saContent,setSaContent]=useState(false)
    const [saHomeContent,setSaHomeContent]=useState(false)
    const [open, setOpen] = useState(true);
    const {t: labels, ready: labelReady} = useTranslationCustom('label-E_ACCOUNT_SA_SWITCH_WARNING_DIALOG');
    const {t: content, ready: contentReady} = useTranslationCustom('content-E_ACCOUNT_SA_SWITCH_WARNING_DIALOG');

    const languageCode = useSelector(
      (state) =>
        state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
    );


    const handleClose = () => {
      setOpen(false);
    };

    useEffect(()=>{
      if(heading == saHeadingHome){
        setSaHomeContent(true)
      }
      else if(heading == saHeading){
        setSaContent(true)
      }else{
        setSaContent(false)
      }
    },[heading])

    let [SaHomeSwitchDetails,setSaHomeSwitchDetails]=useState("")
    let [SaServiceSwitchDetails,setSaServiceSwitchDetails]=useState("")
    let [accountSwitchDetails,setAccountSwitchDetails]=useState("")
    
    useEffect(()=>{
      if(contentReady){
           setSaHomeSwitchDetails(content("E_SA_HOME_SWITCH")?.answer)
           setSaServiceSwitchDetails(content("E_SA_SERVICE_SWITCH")?.answer)
           setAccountSwitchDetails(content("E_ACCOUNT_SWITCH")?.answer)
      }
    },[contentReady])

    return ( 
        <Dialog
          classes={{paperWidthSm : style.dialogContainer}}
          open={openDialog}
          PaperProps={{
            style: { borderRadius: 10 }   }}
          onClose={handleClose}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

         <Grid
        container
        alignItems="center"
        className={style.dialogTitle}
        justify="space-between"
      >
        <Grid item xs={10}>
            <Typography variant="subtitle1">
              {(saHomeContent || saContent)  ? labels("E_SA_SWITCH_HEADING") : labels("E_ACCOUNT_SWITCH_HEADING")}
              </Typography>
          
        </Grid>
        <Grid item xs={2} className={style.rightIcon}>
          <IconButton aria-label="close"
           onClick={sameDetails}
           >
            <CloseIcon className={style.closeIcon} />
          </IconButton>
        </Grid>
      </Grid>
          <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
          <WarningRoundedIcon className={style.alertIcon} />
            </Grid>
              <Grid item xs={12}>
              <Typography variant="body1" className={style.dialogContent}>
                {
                  // saHomeContent ? 
                  // SaHomeSwitchDetails.length ? SaHomeSwitchDetails :<Skeleton/>
                  // :
                  // saContent ?
                  // accountSwitchDetails.length ? accountSwitchDetails : <Skeleton/>
                  // :
                  // accountSwitchDetails.length ? accountSwitchDetails : <Skeleton/>
                  SaServiceSwitchDetails
                }
            
            </Typography>
            </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button 
          ref={(node)=>node && node.focus()}
          onClick={sameDetails}
          variant="contained"
          size="small"
          className={style.buttonStyle}
          align="right"
        >
          <Typography variant='button'>
          {labelReady ? labels("E_ACC_SA_WARNING_ACTION_NO") : <Skeleton/>}
          </Typography>
        </Button>
        

        <Button
          onClick={updateDetails}
          variant="contained"
          size="small"
          className={style.buttonStyle}
          align="right"
        >
          <Typography variant="button">
          {labelReady ? labels("E_ACC_SA_WARNING_ACTION_YES") : <Skeleton/>}
          </Typography>
        </Button>
          </DialogActions>
        </Dialog>
    );
  
}
