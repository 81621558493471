import { energyDetailsPageConstants } from "./constants";

const initialState = {};

export default function energyDetailsPages(state = initialState,action){
    switch(action.type){
      case energyDetailsPageConstants.ENERGY_DETAILS_PAGE_SUCCESS:{
        return{
          ...state,
          data: action.data
        };
      }
      case energyDetailsPageConstants.ENERGY_DETAILS_PAGE_ERROR:{
      return {
        ...state,
        ...action.data,
      };
      }
      default:
        return state;
    }
  }