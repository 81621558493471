import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import E_ImpresaImage from "./_impresacx_img";
import E_Searchbar from "../e_searchbar/e_searchbar";
import E_AgentSearchbar from "../e_searchbar/e_agent_searchbar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  gridSpace: {
    margin: "0px !important",
  },
  iconButton: {
    width: "20%",
    marginLeft: 5,
  },
  paddingImage:{
    paddingRight:"0px !important",
    paddingLeft:"12px !important"
  },
  removeSpace:{
    paddingBottom:"0px !important"
  }
});

export default function E_ImgSearchBar(props) {
  let classes = useStyles();
  let bearer = JSON.parse(localStorage.getItem("bearer"));
  return (
    <Grid
      container
      spacing={5}
      justify="flex-start"
      alignItems="center"
      className={classes.gridSpace}
    >
      <Grid item className={classes.paddingImage}>
        <E_ImpresaImage white={false} />
      </Grid>
      {props.searchDisplay && 
      <Hidden smDown>
        <Grid item className={classes.removeSpace} md={bearer?.isAgent == true ? 9 : ''}>
          {
            bearer?.isAgent == true ?(
              <E_AgentSearchbar fullWidth={true} handlefailed={props.handlefailed} handleChange={props.handleChange}/>
              ):
            (<E_Searchbar fullWidth={false} />)
          }
        </Grid>
      </Hidden>}
    </Grid>
  );
}
