import { config } from "../../src/enviroment";
import axios from "axios";
export const userPreferencesService = {
  updateUserPreferences: updateUserPreferences,
  getUserPreferences: getUserPreferences
};

export function updateUserPreferences({ identifiers, preference }) {
  let APIquery = `query {
        updateUserPreference(input: {
            setUserPreference: {
                preferenceName: "${preference.preferenceName}",
                preferenceValue: "${preference.preferenceValue}"
            }
        }) {
          isPreferenceUpdated
        }
    }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.AUTH_SERVICE,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function getUserPreferences() {
  let APIquery = `query {
        getUserPreferences
    }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.AUTH_SERVICE,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response.data?.data?.getUserPreferences);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
