import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useMediaQuery } from "react-responsive";
import { appService } from "../../../services/app.service";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../config/route-paths";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import { useTranslationCustom } from "../../../i18nOverrides";

const useStyles = makeStyles((theme) => ({
  backColor: {
    color: theme.palette.ePrimary?.base,
    backgroundColor: "none",
    textTransform: "capitalize !important",
  },
  marginBotton: {
    marginBottom: "0.3rem",
  },
}));

const Backlink = (props) => {

  const {t:labels, ready: labelReady}=useTranslationCustom("label-CONFIGURATIONS")
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  const history = useHistory();
  const backCall = () => {
    if (props.backToHome) {
      history.push(routePaths.HOME);
    } else {
      if (history.length >= 1) {
        if (
          history?.location?.state?.source === "STOP_SERVICE" &&
          history?.location?.pathname === routePaths.INITIATE_PAYMENT
        ) {
          history.push({
            pathname: routePaths?.GET_SERVICE,
            state: {
              tab: "E_SERVICE_REQUEST",
              index: 0,
              open: 3,
              source: "PAYMENT",
            },
          });
        } else if (
          history?.location?.pathname === routePaths.GET_SERVICE &&
          history?.location?.state?.selectedValue &&
          history?.location?.state?.tab === "E_COMPLAINT"
        ) {
          history.push({
            pathname: routePaths.PROFILE,
            state: { selectedValue: history.location.state.selectedValue  },
          });
        } 
        else {
          history.goBack();
          if (history?.location?.state?.serviceTab !== undefined) {
            history.goBack();
          }
        }
      } else {
        history.push(routePaths.HOME);
      }
    }
  };
  return (
    <Button
      className={`${classes.backColor} ${
        isTabletOrMobile ? "" : classes.isTabletOrMobile
      }`}
      size="small"
      startIcon={<ArrowBackIosIcon fontSize="small" className="marginRight" />}
      onClick={backCall}
    >
      <b>{labelReady?labels("BACK_BUTTON"): <Skeleton/> }</b>
    </Button>
  );
};

export default Backlink;
