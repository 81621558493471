import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Hidden,
  Box,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import E_RightToolbar from "./view/_rightToolBar";
import E_ImgSearchBar from "./view/_imgSearchBar";
import E_Searchbar from "./e_searchbar/e_searchbar";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from "react-redux";
import E_AgentSearchbar from "./e_searchbar/e_agent_searchbar";

const useStyle = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.eWhite?.base,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: "1202",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "60px",
    padding: "1%",
  },
  searchHr: { width: "150%", margin: "0px", padding: "5px" },
  paddingSearch: {
    padding: "1%",
    paddingBottom:"0%",
    height: "auto",
  },
  iconPadding:{
    padding:"1%"
  },
  mobilehead:{
    ["@media (max-width:960px)"]: {
      display: "flex",
    }
  },
  maxwidthleftbar: {
    ["@media (min-width:1279px)"]: {
      maxWidth: "35%",
    }
  },
  maxwidthleftagent: {
    ["@media (min-width:1279px)"]: {
      maxWidth: "60%",
    }
  },
}));
export default function E_Header(props) {
  let classes = useStyle();
  let bearer = JSON.parse(localStorage.getItem("bearer"));
  let widgets = useSelector((state) => state?.parametersWidgets?.WidgetLookUp);
  const [searchDisplay,setSearchDisplay]=useState(false);
  useEffect(() => {
    widgets &&
      widgets.map((item) => {
        if (item.widgetCode === "E_SEARCH" && item?.widgetDefaultValue==="true") {
          setSearchDisplay(true);
        }
      });
  }, [widgets]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Grid container justify="space-between" alignItems="center" md={12} xs={12}>
            <Grid md={bearer?.isAgent == true ? 9 : ''} xs={bearer?.isAgent == true ? 7 : 5} className={!bearer?.isAgent ? classes.maxwidthleftbar : classes.maxwidthleftagent}>
              <Grid className={classes.mobilehead} justify="space-between" alignItems="center" xs={12}>
                <Grid xs={1}>
                  <Hidden mdUp>
                    <IconButton
                      color="default"
                      aria-label="open drawer"
                      edge="end"
                      onClick={props.handleDrawerOpen}
                      className={classes.iconPadding}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                </Grid>
                <Grid xs={11}>
                  <E_ImgSearchBar searchDisplay={searchDisplay} handleChange={props.handleChange} handlefailed={props.handlefailed} />
                </Grid>
              </Grid>
            </Grid>
            <Grid >
              <E_RightToolbar />
            </Grid>
          </Grid>
        </Toolbar>
      {searchDisplay &&
        <Hidden mdUp>
          <div className={classes.paddingSearch}>
          {
            bearer?.isAgent == true ?(
              <E_AgentSearchbar fullWidth={true} handleChange={props.handleChange}/>
              ):
            (
            <E_Searchbar fullWidth={true} />
            )
          }
          </div>
        </Hidden>
      }
      </AppBar>
    </Box>
  );
}
