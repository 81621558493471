import { billPreferenceConstant } from "./bill-preference-constants";

const sendinitialState = [];
export function sendbillPreferenceGetRoutingData(
  state = sendinitialState,
  action
) {
  switch (action.type) {
    case billPreferenceConstant.SEND_BILL_ROUTING_START:
      return { ...state, data: action.data };
    case billPreferenceConstant.SEND_BILL_ROUTING_SUCCESS:
      return { ...state, data: action.data };
    case billPreferenceConstant.SEND_BILL_ROUTING_FAILURE:
      return {
        ...state,
        error:action.error,
        data:action.data
      };
    default:
      return state;
  }
}
const sendinitialAlertsState = [];
export function sendbillPreferenceGetAlertsData(
  state = sendinitialAlertsState,
  action
) {
  switch (action.type) {
    case billPreferenceConstant.SEND_BILL_ALERTS_NOTIFICATION_START:
      return { ...state, data: action.data };
    case billPreferenceConstant.SEND_BILL_ALERTS_NOTIFICATION_SUCCESS:
      return { ...state, data: action.data };
    case billPreferenceConstant.SEND_BILL_ALERTS_NOTIFICATION_FAILURE:
      return {
        ...state,
        error:action.error,
        data:action.data
      };
    default:
      return state;
  }
}

const sendinitialPaymentPreferenceState = [];
export function sendPaymentPreferenceData(state = sendinitialPaymentPreferenceState,action){
  switch(action.type){
    case billPreferenceConstant.SEND_PAYMENT_PREFERENCE_START:
      return {...state, data: action.data}
    case billPreferenceConstant.SEND_PAYMENT_PREFERENCE_SUCCESS:
      return { ...state, data: action.data };
    case billPreferenceConstant.SEND_PAYMENT_PREFERENCE_FAILURE:
      return {
        ...state,
        error:action.error,
        data:action.data
      };
    default:
      return state;
  }
}

const handleChangeInitialState = [];
export function handleChangeBillRouting(
  state = handleChangeInitialState,
  action
) {
  switch (action.type) {
    case billPreferenceConstant.HANDLE_CHANGE_BILL_ROUTING:
      return {
        ...state,
        routingData: action.routingData,
      };
    default:
      return state;
  }
}

const handleChangeAlertsInitialState = [];
export function handleChangeBillAlerts(
  state = handleChangeAlertsInitialState,
  action
) {
  switch (action.type) {
    case billPreferenceConstant.HANDLE_CHANGE_BILL_ALERTS:
      return {
        ...state,
        alertsData: action.alertsData,
      };
    default:
      return state;
  }
}

const handleBillsAndPaymentsPreferenceInitialState = []
export function handleChangeBillsAndPayments(
  state = handleBillsAndPaymentsPreferenceInitialState,
  action
) {
  switch (action.type) {
    case billPreferenceConstant.HANDLE_BILLS_AND_PAYMENTS_PREFERENCES:
      return {
        ...state,
        AutoPayBillData: action.AutoPayBillData,
      };
    default:
      return state;
  }
}

const handleChangeAlertDataState = [];
export function handleChangeBillAlertdata(
  state = handleChangeAlertDataState,
  action
) {
  switch (action.type) {
    case billPreferenceConstant.HANDLE_CHANGE_BILL_ALERTS_DATA:
      return {
        ...state,
        Data: action.Data,
      };
    default:
      return state;
  }
}

const handleChangeBillForecastWidgetPrefState = {}
export function handleChangeBillForecastWidgetPref(
  state = handleChangeBillForecastWidgetPrefState,
  action
) {
  switch (action.type) {
    case billPreferenceConstant.HANDLE_CHANGE_BILL_FORECAST_PREFERENCE:
      return {
        ...state,
        enableWidget: action.checkboxValue,
      };
    default:
      return state;
  }
}