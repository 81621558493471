import axios from "axios";
import moment from "moment";
import { config } from "../../../../enviroment";
import { apolloClientService } from "../../../../services/apollo-client";

export const PaymentService = {
  getContent: getContentDetails,
  postPaymentProcessing,
  prepayment,
};

async function getContentDetails(languageCode) {
  let APIquery =
    `query{
        getContents(input:{categoryCode:"INITIATE_PAYMENT",languageCode:"` +
    languageCode +
    `"})
        }`;
  return apolloClientService.ApolloClientgqls(APIquery);
}

async function postPaymentProcessing(
  orderData,
  razorPayData,
  accountData,
  prepaidEngineUser
) {
  let accountInfo = JSON.stringify(accountData).replace(
    /"([^("")"]+)":/g,
    "$1:"
  );
  let paymentDateTime = moment().format();

  let APIquery =
    `query{postPaymentDetailsRazorPay(input:{
      RequestInput:{  
      prepaidEngineUser:` +
    prepaidEngineUser +
    `,    
      Accounts:` +
    accountInfo +
    `,  
      paymentDateTime:"` +
    paymentDateTime +
    `"    
      orderId:"` +
    orderData?.id +
    `",      
      razorpay_payment_id:"` +
    razorPayData?.razorpay_payment_id +
    `",      
      razorpay_signature:"` +
    razorPayData?.razorpay_signature +
    `"      
      PaymentType:"RAZORPAY_PAYMENT"      
          }      
      })}`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function prepayment(amount, accountData, bearer) {
  let accountInfo = JSON.stringify(accountData).replace(
    /"([^("")"]+)":/g,
    "$1:"
  );
  let APIquery =
    `query{prePaymentDetailsRazorPay(input:{RequestInput:{accountId:` +
    accountInfo +
    `,PaymentType:"RAZORPAY_PAYMENT",amount:` +
    amount +
    `}})}`;
  let headers = { accessToken: bearer?.accessToken };
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
      headers: headers,
    })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export default PaymentService;
