import { config } from "./enviroment";
import { initReactI18next } from "react-i18next";
import LocalStorageBackend from "i18next-localstorage-backend";
import ChainedBackend from "i18next-chained-backend";
import HttpApi from "i18next-http-backend";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const TENANT_CODE = config.constants.BASE_TENANT_CODE;
const END_POINT = config.urls.ASSEST_URL_ENDPOINT;
const i18Instance = i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    debug: true,
    ns: [],
    fallbackNS: [],
    partialBundledLanguages: true,
    initImmediate: false,
    fallbackLng: 'en',
    detection:{
      order: ['cookie','localStorage', 'htmlTag', 'path', 'subdomain'],
    },
    backend: {
      backends:[
        LocalStorageBackend,
        HttpApi,
        HttpApi, // Here 2nd HTTP API is for fallback with namespace overrides
        HttpApi //For widget paramerter language fallback
      ],
      backendOptions: [
        {
          expirationTime: parseInt(config.constants.I18_CACHE_EXPIRY_TIME)
        },
        {
          loadPath: `${END_POINT}/i18n/locales/${TENANT_CODE}/{{lng}}/{{ns}}.json`,
          crossDomain: true,
        },
        {
          loadPath: ((lng,ns) => {
            if(Array.isArray(ns)) {
              ns =  ns.map(currentNS => {
                let currentNSsplit = currentNS.split('_');
                currentNSsplit[0] = config.constants['DEFAULT_SERVICE_TYPE'];
                return currentNSsplit.join('_');
              })
            }
            return `${END_POINT}/i18n/locales/${TENANT_CODE}/{{lng}}/${ns}.json`
          }),
          crossDomain: true,
        },
        {
          loadPath: `${END_POINT}/i18n/locales/${TENANT_CODE}/en/{{ns}}.json`, //for widget parameter language fallback
          crossDomain: true,
        },
      ]
    },
    react: { wait: true, useSuspense: false },
    returnObjects: true
  });
export default i18Instance;
