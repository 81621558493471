import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  Typography,
  Grid,
  IconButton,
  Hidden,
  Popper,
  Fade,
  Paper,
} from "@material-ui/core";
import { routePaths } from "../../../../config/route-paths";
import { history } from "../../../../../helpers";
import { useLocation } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import UsageIcon from "../../assets/usageIcon.svg";
import OutageIcon from "../../assets/Outage-Alert-icon.svg";
import BillingIcon from "../../assets/billingIcon.svg";
import Sustainability from "../../assets/sustainabilityIcon.svg";
import ServiceIcon from "../../assets/servicesIcon.svg";
import AlertsIcon from "../../assets/announcementIcon.svg";
import SelfHelpIcon from "../../assets/selfhelpIcon.svg";
import HomeOutlinedIcon from "../../assets/homeIcon.svg";
import Your_Rewards from "../../assets/your_Rewards.png"
import { useDispatch, useSelector } from "react-redux";
import { updateSelected_Value } from "../../../../e_service/e_complaint/model/service-action";
import { Skeleton } from "@material-ui/lab";
import { useTranslationCustom } from "../../../../../i18nOverrides";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
const useStyle = makeStyles((theme) => ({
  whiteColor: {
    color: theme.palette.eWhite?.base,
  },
  selectedItem: {
    background: theme.palette.eSecondary?.base,
  },
  hoverPaper: {
    background: theme.palette.eSecondary?.hover,
    color: theme.palette.eWhite?.base,
    boxShadow: "none !important",
    height: "50px !important",
    padding: "12px 10px 10px 10px",
    minWidth: "130px",
    borderRadius: "unset",
    marginLeft: "-4px",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.only('sm')]: {
      marginBottom:"5px"
    }
  },
  tabletFontSize:{
    [theme.breakpoints.only('sm')]: {
      fontSize:"1.3rem"
    }
  },
  iconStyle:{
    height:'24px',
    width:'24px',
    objectFit:'contain'
  },
  iconStyles:{
    color: theme.palette.eWhite?.base,
    height:'24px',
    width:'24px',
  },
  disabledItem:{
    pointerEvents: "none"
  }
}));
export const StyledListItem = withStyles((theme) => ({
  root: {
    height: "50px !important",
    borderLeft: "3px solid" + theme.palette.ePrimary?.base + "!important",
    "&:hover": {
      background: theme.palette.eSecondary?.hover,
    },
  },
  disabled: {
    backgroundColor: theme.palette.eSecondary?.base + " !important",
    opacity: "1 !important",
    borderLeft:
      "3px solid " + theme.palette.eWhite?.base + " !important",
    borderRadius: "0px 4% 4% 0px",
    borderRight:
      "3px solid " + theme.palette.ePrimary?.base + " !important",
  },
}))(ListItem);

export const StyleIconButton = withStyles((theme) => ({
  root: {
    padding: "1px !important",
  },
}))(IconButton);

export default function E_SidebarList(props) {
  let classes = useStyle();
  const {t:labels, ready: labelReady} = useTranslationCustom("label-E_SIDEBAR")
  let widgets = useSelector((state) => state?.parametersWidgets?.WidgetLookUp);
  const currentServiceTypeFromMeterDetails = useSelector(store => store?.meterDetails?.getSaInfo?.serviceType)
  const [allSidebarWidgets, setAllSidebarWidgets] = useState([])
  let [components, setComponents] = useState("");
  let [presetComponents, setItems] = useState("");
  let bearer = JSON.parse(localStorage.getItem("bearer"));
  const currentServiceTypeCd = bearer?.isAgent && !currentServiceTypeFromMeterDetails ? "E" : currentServiceTypeFromMeterDetails

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectItem, setItem] = React.useState();

  const handleClick = (newItem) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => selectItem !== newItem || !prev);
    setItem(newItem);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    setOpen(false);
    setItem();
  };
  useEffect(() => {
    if (labelReady) {
      setComponents([
        {
          title: "E_SIDEBAR_HOME",
          route: routePaths.HOME,
          icon: <img src={HomeOutlinedIcon} className={classes.iconStyle}/>,
          widgetCode: bearer?.isAgent ? "E_AGENT_SIDEBAR.HOME":"E_SIDEBAR.HOME",
          selected: false,
        },

        {
          title: "E_SIDEBAR_BILLING",
          route: routePaths.BILLING,
          icon: <img src={BillingIcon} className={classes.iconStyle}/>,
          widgetCode: "E_SIDEBAR.BILLING",
          selected: false,
        },
        {
          title: "E_SIDEBAR_SUSTAINABILITY",
          route: routePaths.SUSTAINABILITY,
          icon: <img src={Sustainability} className={classes.iconStyle}/>,
          widgetCode: "E_SIDEBAR.SUSTAINABILITY",
          selected: false,
        },
        {
          title: "E_SIDEBAR_SERVICE",
          route: routePaths.GET_SERVICE,
          icon: <img src={ServiceIcon} className={classes.iconStyle}/>,
          widgetCode: "E_SIDEBAR.SERVICE",
          selected: false,
        },
        {
          title: "E_SIDEBAR_ALERTS",
          route: routePaths.ALERTS_AND_NOTIFICATION,
          icon: <img src={AlertsIcon} className={classes.iconStyle}/>,
          widgetCode: "E_SIDEBAR.ALERTS",
          selected: false,
        },
        {
          title: "E_SIDEBAR_YOUR_REWARDS",
          route: routePaths.Your_Rewards,
          icon: <img src={Your_Rewards} className={classes.iconStyle} height="29px"/>,
          widgetCode: "E_SIDEBAR.YOUR_REWARDS",
          selected: false,
        },
        {
          title: "E_SIDEBAR_SELF_HELP",
          route: routePaths.SELF_HELP,
          icon: <img src={SelfHelpIcon} className={classes.iconStyle}/>,
          widgetCode: "E_SIDEBAR.SELF_HELP",
          selected: false,
        },
        {
          title: "E_SIDEBAR_USAGE",
          route: routePaths.USAGE,
          icon: <img src={UsageIcon} className={classes.iconStyle}/>,
          widgetCode: "E_SIDEBAR.USAGE",
          selected: false,
        },
        {
          title: "E_SIDEBAR_OUTAGE",
          route: routePaths.OUTAGE,
          icon: <img src={OutageIcon} className={classes.iconStyle}/>,
          widgetCode: "E_SIDEBAR.OUTAGE",
          selected: false,
        },
        {
          title: "E_SIDEBAR_ECOMMERCE",
          route: routePaths.ECOMMERCE,
          icon: <ShoppingCartIcon />,
          widgetCode: "E_SIDEBAR.ECOMMERCE",
          selected: false,
        },
      ]);
    }
  }, [labelReady, labels]);

  useEffect(() => {
    if (widgets && components.length !== 0) {
      let widget = [];
      let allSidebarWidget = []
      widgets.forEach((element) => {
        components.forEach((item, k) => {
          if (element.widgetCode === item.widgetCode) {
            if (element.widgetType === "MENU") {
              if (element.widgetDefaultValue === "true") {
                item.widgetRank = element.widgetRank;
                allSidebarWidget.push(item)
                if(element.serviceTypesEnabled && element.serviceTypesEnabled.split(",").includes(currentServiceTypeCd)) {
                  widget.push(item);
                }
              }
            }
          }
        });
      });
      setItems(widget);
      let availableRoutesBasedOnSA = widget.map(component => component.route);
      let allRoutesForSidebarWidgets = allSidebarWidget.map(component => component.route);
      if(!(availableRoutesBasedOnSA.includes(history.location.pathname)) && allRoutesForSidebarWidgets.includes(history.location.pathname)) {
        history.push(routePaths.HOME)
      }
    }
  }, [widgets, components, currentServiceTypeCd, history.location.pathname]);

  let location = useLocation();
  useEffect(() => {}, [location]);

  useEffect(() => {
    if (presetComponents && presetComponents.length !== 0) {
      presetComponents.sort((a, b) => {
        return a.widgetRank - b.widgetRank;
      });
    }
  }, [presetComponents]);

  useEffect(() => {
    if (
      presetComponents &&
      presetComponents.length !== 0 &&
      history.location.pathname
    ) {
      presetComponents.map((item) =>
        item.route === history.location.pathname
          ? (item.selected = true)
          : item.route === routePaths.GET_SERVICE
          ? history.location.pathname === routePaths.GET_SUPPORT ||
            history.location.pathname === routePaths.GET_SERVICE_REQUEST
            ? (item.selected = true)
            : (item.selected = false)
          : (item.selected = false)
      );
      setItems([...presetComponents]);
    }
  }, [history.location.pathname]);
  let dispatch=useDispatch();
  let componentClick = (selectedComponent, k) => {
    dispatch(updateSelected_Value({ updatedValue: 0 }))
    presetComponents.map((item) =>
      item.widgetCode === selectedComponent.widgetCode
        ? (item.selected = true)
        : item.route === routePaths.GET_SERVICE
        ? history.location.pathname === routePaths.GET_SUPPORT ||
          history.location.pathname === routePaths.GET_SERVICE_REQUEST
          ? (item.selected = true)
          : (item.selected = false)
        : (item.selected = false)
    );
    history.push(selectedComponent.route);
    setItems([...presetComponents]);
    props.mobile && props.handleDrawerOpen();
  };
  return (
    <List className={bearer.isAgent && !bearer.accountDetails? classes.disabledItem :""}>
      {presetComponents &&
        presetComponents.map((item, k) => (
          <StyledListItem
            id={labels(item.title)}
            key={k}
            onClick={() => componentClick(item, k)}
            disabled={item.route === history.location.pathname || item.selected}
            onMouseEnter={!item.selected && handleClick(labels(item.title))}
            onMouseLeave={handleClose}
            className={classes.listItem}         
          >
            <Hidden smDown>
              <>
                <StyleIconButton className={classes.whiteColor}>
                  {item.icon}
                </StyleIconButton>
                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  transition
                  placement="right"
                  style={{ zIndex: "2" }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <Paper className={classes.hoverPaper}>
                      {
                        labelReady?
                        <Typography>{selectItem}</Typography>
                        : <Skeleton /> }
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </>
            </Hidden>
            <Hidden mdUp>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  {" "}
                  <StyleIconButton className={classes.iconStyles}>
                    {item.icon}
                  </StyleIconButton>
                </Grid>
                <Grid item>
                  <Typography className={[classes.whiteColor,classes.tabletFontSize]}>
                    {labelReady ? labels(item.title): <Skeleton />}
                  </Typography>
                </Grid>
              </Grid>
            </Hidden>
          </StyledListItem>
        ))}
    </List>
  );
}
