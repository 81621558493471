import React, { useState, useEffect } from "react";
import { Grid, Typography, Tooltip, Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import E_AccountDropdown from "./view/_accountDropdown";
import "../../../common/common.css";
import { SaSwitcher } from "../e_sa_switcher/e_sa_switcher";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import { useTranslationCustom } from "../../../../i18nOverrides";
const useStyles = makeStyles((theme) => ({
  accountIdColor: {
    color: "initial !important",
  },
  accountIdwidth: {
    overflow: "hidden",
    maxWidth: "15ch",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  blueTextColor: {
    color: theme.palette.ePrimary?.base,
    maxWidth: "17vw",
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.eWhite?.base,
    color: theme.palette.ePrimary?.base,
    boxShadow: theme.shadows[2],
    border: "1px solid " + theme.palette.eLight?.base,
  },
}))(Tooltip);

export default function E_AccountDetails() {
  let classes = useStyles();
  const {t:labels, ready: labelReady} = useTranslationCustom("label-E_HEADER");
  let premise = useSelector(
    (store) =>
      store?.meterDetails?.accountPersonDetail?.accountPremiseDetailList
  );
  let [premiseDetail, setPremiseDetails] = useState(null);

  useEffect(() => {
    if (premise) {
      premise = premise.length > 1 ? premise[0] : premise;
      let address =
        premise?.address1 && premise?.address2 && premise?.address3
          ? premise?.address1 +
            ", " +
            premise?.address2 +
            ", " +
            premise?.address3
          : premise?.address1 && premise?.address2
          ? premise?.address1 + ", " + premise?.address2
          : premise?.address1 && premise?.address3
          ? premise?.address1 + ", " + premise?.address3
          : premise?.address2 && premise?.address3
          ? premise?.address2 + ", " + premise?.address3
          : premise?.address1
          ? premise?.address1
          : premise?.address2
          ? premise?.address2
          : premise?.address3
          ? premise?.address3
          : "";
      let premiseAdd =
        (premise?.city ? ", " + premise?.city : "") +
        (premise?.state ? ", " + premise?.state : "") +
        (premise?.country ? ", " + premise?.country : "") +
        (premise?.postal ? ", " + premise?.postal : "");
      setPremiseDetails(address + premiseAdd);
    }
  }, [premise]);
  return (
    <Grid container alignItems="center" spacing={1}>
      <Hidden mdDown>
        <Grid item>
          <Typography variant="subtitle2" title={labelReady ? labels("E_HEADER_ACCOUNT_ID") : <Skeleton />} className={`${classes.accountIdColor} ${classes.accountIdwidth}`}>
            {labelReady ? labels("E_HEADER_ACCOUNT_ID") : <Skeleton />}
          </Typography>
        </Grid>
        <Grid item>
          <E_AccountDropdown />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className={classes.accountIdColor}>
            {labelReady ? labels("E_HEADER_SA_ID") : <Skeleton />}
          </Typography>
        </Grid>
        <Grid item>
          <SaSwitcher />
        </Grid>
      </Hidden>
    </Grid>
  );
}
