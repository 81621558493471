import { config } from "../enviroment";
import axios from "axios";

const endPoints = config.urls;
export const logService = {
  postExternalLog,
};


function postExternalLog(key, message) {
  if (localStorage.getItem("bearer")) {
    return new Promise((resolve, reject) => {
      axios
        .post(config.urls.ADMIN_BASE_URL+'log/external', {
          "message": message,
          "key": key
      })
        .then(function(response) {
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }
}
