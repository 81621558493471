import { languageConstants } from "../constants/language.constants";

var initialState = "";
export function labels(state = initialState, action) {
  switch (action.type) {
    case languageConstants.LABELS_SUCCESS:
      return {
        ...action.language
      };
    default:
      return state;
  }
}
