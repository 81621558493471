import { e_meterAlertsConstant } from "./e_meter_alerts_constants";

let initiateState = {
  METER: [],
  BILL: [],
  ANNOUNCEMENT: [],
  CHARGE: [],
  LOWBALANCE: [],
  MINBALANCE: [],
};
let initialReadStatus = {
  justReadNow:false
}
export function e_meterAlertsDataCount(
  state = { data: initiateState },
  action
) {
  switch (action.type) {
    case e_meterAlertsConstant.METER_ALERTS_SUCCESS:
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
}

export function e_updateDotCount(state={data:initialReadStatus},action){
  switch (action.type) {
    case e_meterAlertsConstant.UPDATE_COUNT:
      return {
        state,data:action.data
      }
    default:
      return state;
  }
}
