import { makeStyles, createStyles ,withStyles} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core"


export const OutlinedTextFiled = withStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-input": {
        padding: " 2% 0.8vw !important",
        height: "8% !important",
        textAlign:"right",
        backgroundColor: theme.palette.eWhite?.base, 
      },
      "& .MuiOutlinedInput-root":{
       width: "100%",
      }
    },
  }))(TextField);
  export const useCustomCheckboxStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      width: 17,
      height: 17,
      marginLeft: 5,
      marginRight: 5,
      "input:disabled ~ &": {
        boxShadow: "none",
        background: theme.palette.eLight?.base + " !important",  
      }
    }
  })
  );
  export const useCustomRadioStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      borderRadius: '50%',
      width: 20,
      height: 20,
      marginLeft: 5,
      marginRight: 5,
      "input:disabled ~ &": {
        boxShadow: "none",
        background: theme.palette.eLight?.base + " !important",
      }
    }
  })
  );
  export const RechargeTextField = withStyles((theme) => ({
    root: {
      "& .MuiOutlinedInput-input": {
        padding: " 2% 0.8vw !important",
        height: "8% !important",
        textAlign:"right",
        color:theme.palette.ePrimary?.base,
        border: "solid 1px" + theme.palette.eWhite?.base,
        background: theme.palette.eWhite?.base
      },
      "& .MuiOutlinedInput-root":{
       width: "100%",
       borderRadius: "0px",
      },
      '&:hover fieldset': {
        borderColor:  theme.palette.eWhite?.base,
      }
    },
  }))(TextField);