import React from "react";
import { Redirect, Route } from "react-router-dom";
import { routePaths } from "../config/route-paths";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return localStorage.getItem("bearer") !== null ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: routePaths.LOGOUT, state: { from: props.location } }}
        />
      );
    }}
  />
);
