import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { App } from "./components/app/app";
// import * as serviceWorker from "./serviceWorker";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from "react-redux";
import { store } from "./helpers";
import i18Instance from './i18NextService';
import { I18nextProvider } from 'react-i18next';
import ErrorBoundary from "./components/e_layout/e_error-bounder";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate( 
  // <ErrorBoundary>
  <Provider store={store}>
    <I18nextProvider i18n={i18Instance}></I18nextProvider>
    <App store={store}/>
  </Provider>
  // </ErrorBoundary>
  , rootElement);
} else {
  ReactDOM.render(
    // <ErrorBoundary>
    <Provider store={store}>
          <I18nextProvider i18n={i18Instance}></I18nextProvider>
    <App store={store}/>
  </Provider>
  // </ErrorBoundary>
  , rootElement);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorkerRegistration.register();
serviceWorkerRegistration.registerNotification();