import React, { useEffect, useState } from "react";
import { Drawer, Toolbar, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import E_SidebarList from "./view/_sidebarList";
import E_SocialLinks from "./view/_socialLinks";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  MuiDrawer: {
    background: theme.palette.ePrimary?.base,
    width: "60px !important",
    border:"none",
    zIndex:1
  },
  fullHeight: {
    height: "auto",
    minHeight: "calc(100% - 64px)"
  },
  fullWidth: {
    width: "100%",
    overflow: 'hidden'
  }
}));

export default function E_SideBar() {
  let classes = useStyle();
  let widgets = useSelector((state) => state?.parametersWidgets?.WidgetLookUp);
  let [socialLinks, setSocialLinks] = useState(false);
  let [socialData, setSocialData] = useState();
  useEffect(() => {
    widgets &&
      widgets.map((item) => {
        if (item.widgetCode === "E_SIDEBAR.SOCIAL_LINKS") {
          setSocialLinks(item.widgetDefaultValue === "true" ? true : false);
          setSocialData(item.children);
        }
      });
  }, [widgets]);

  return (
    <>
      <Drawer variant="permanent" classes={{ paper: classes.MuiDrawer }}>
        <Toolbar />
        <Box className={classes.fullHeight}>
          <Grid
            container
            className={classes.fullHeight}
            direction="column"
            justify="space-between"
          >
            <Grid className={classes.fullWidth} item>
              <E_SidebarList mobile={false} />
            </Grid>
            <Grid item>
              {/*socialLinks && <E_SocialLinks socialData={socialData} />*/}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}
