import { energyPlansConstants } from "./constants";

const initialState = {};

export default function energyPlanDetails(state = initialState,action){
    switch(action.type){
      case energyPlansConstants.ENERGY_PLANS_DETAILS_SUCCESS:{
        return{
          ...state,
          data: action.data
        };
      }
      case energyPlansConstants.ENERGY_PLAN_TO_COMPARE_SUCCESS:{
        return{
          ...state,
          dataToCompare: action.data
        }
      }
      case energyPlansConstants.ENERGY_PLANS_DETAILS_ERROR:{
      return {
        ...state,
        ...action.data,
      };
      }
      default:
        return state;
    }
  }