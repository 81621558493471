import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { languageActions } from "../../actions/index";

export default function FetchLabels() {
  let dispatch = useDispatch();
  let languageCode = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  useEffect(() => {
    if (languageCode) {
      dispatch(languageActions.downloadLabels(languageCode));
    }
  }, [languageCode]);
  return null;
}
