import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { history } from "../../../../../helpers/history";
import Radio from "@material-ui/core/Radio";
import { adminService } from "../../../../../services/admin.service";
import {
  Grid,
  Typography,
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { routePaths } from "../../../../config/route-paths";
import { store } from "../../../../../helpers/store";
import { Alert } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Payment from "../../../e_razor_pay/e_razor_payment";
import ContentAndRequestHelp from "../../view/e_multiple_payments_content_and_request_help";
import FooterContent from "../../view/e_multiple_payments_footer";
import { appStyles } from "../../../../app/app-style";
import {
  RechargeTextField,
  useCustomRadioStyles,
  useCustomCheckboxStyles,
} from "../../view/e_multiple_payments_style";

let selectBills = [];
let totalSelAmount = 0;
let creditCardGatewayCode = "";
let connectionDetails = [];
let amount;
let isMultiSelect = false;
let isPartialPay = false;
let minAmount = "";
let maxAmount = "";
let primaryIdentifier = "";
let makePaymentsLabels = "";

if (store.getState().labels) {
  makePaymentsLabels = store.getState().labels.data.getLabels.E_MAKE_PAYMENT;
} else {
  store.subscribe(() => {
    if (store.getState().labels) {
      makePaymentsLabels = store.getState().labels.data.getLabels
        .E_MAKE_PAYMENT;
    }
  });
}

var styles = {
  yourAccountCard: {
    padding: "0% 4%",
  },
  selectAll: {
    color: appStyles.outerTheme.palette.ePrimary?.base,
  },
  accountNo: {
    color: appStyles.outerTheme.palette.ePrimary?.base,
  },
  cantSeeButton: {
    color: appStyles.outerTheme.palette.ePrimary?.base,
    border: "1.5px solid " + appStyles.outerTheme.palette.ePrimary?.base,
    borderRadius: "30px",
    fontSize: 10,
  },
  checkBox: {
    color: appStyles.outerTheme.palette.ePrimary?.base,
  },
  AccountBalanceGrid: {
    padding: "2% 2%",
  },
  AccountBalanceCard: {
    backgroundColor: appStyles.outerTheme.palette.ePrimary?.base,
    color: appStyles.outerTheme.palette.eWhite?.base,
  },
  totalOutStandingBalance: {
    backgroundColor: appStyles.outerTheme.palette.eTertiary?.base,
    padding: "4% 4%",
  },
  padding: {
    padding: "4% 4%",
  },
  secondPadding: {
    padding: "0% 4%",
  },
  secondPaddingStyling: {
    padding: "0% 4%",
    flexBasis: 0,
  },
  margin: {
    margin: "auto 0",
  },
  payNowButton: {
    padding: "4% 4%",
    align: "right",
    justifyContent: "right",
    justify: "right",
  },
  disabledButton: {
    align: "right",
    justifyContent: "right",
    justify: "right",
    backgroundColor: appStyles.outerTheme.palette.eSecondary?.base,
    color: appStyles.outerTheme.palette.eWhite?.base,
    borderRadius: "30px",
  },
  secondPaddingStyling: {
    padding: "0% 4%",
    flexBasis: 0,
  },
};

export default class YourAccount extends React.Component {
  constructor(props) {
    super();
    // localStorage.getItem("nativeAppRediection")
    this.queryString = window.location.search;
    this.isNativePayment =  false
    // localStorage.getItem("nativeAppRediection") === "true" ;
    if (this.queryString) {
      this.urlParams = new URLSearchParams(this.queryString);
      this.isNativePayment = true;
      this.nativeAmount =this.urlParams.get("amount");
      // localStorage.getItem("nativeAmount");
      this.nativeAccount = this.urlParams.get("accounts");
    }
    this.state = {
      billDetails: [],
      contentRes: {},
      selectedAmount: {},
      selectedBills: [],
      connectionId: null,
      saId: null,
      currency: props.linkedAccounts?.[0].currency,
      totalSelAmount: 0,
      selectAllDisable: false,
      sessionId: "",
      orderId: "",
      creditCardGatewayCode: null,
      amountToPay: this.isNativePayment ? Number(this.nativeAmount) : 0,
      totalOutStandingBalance: 0,
      initiatePaymentError: false,
      isMultiSelect: false,
      isPartialPay: false,
      accountData: [],
      allData: "",
      razorpayColor: "",
      buttonDisabled: true,
      paymentErrorMessage: "",
    };
    this.handleKeypress = this.handleKeypress.bind(this);
    this.functionAdmin = this.functionAdmin.bind(this);
  }

  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;
    if (characterCode === ".") return;
    if (characterCode === "0") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  functionAdmin(languageCode) {
    adminService.getContentData(languageCode, "E_MAKE_PAYMENTS").then((res) => {
      res.data.getContents.E_MAKE_PAYMENTS.forEach((item) => {
        if (item.contentCode === "E_MAKE_PAYMENTS_ERROR_MESSAGE") {
          this.setState({
            paymentErrorMessage: item.answer,
          });
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isNativePayment !== true) {
      if (prevState.amountToPay !== this.state.amountToPay) {
        if (
          this.state.amountToPay < minAmount ||
          this.state.amountToPay > maxAmount
        ) {
          this.setState({
            buttonDisabled: true,
          });
        } else {
          this.setState({
            buttonDisabled: false,
          });
        }
      }

      if (prevState.buttonDisabled !== this.state.buttonDisabled) {
        if (!this.state.buttonDisabled) {
          localStorage.setItem("enabled_state", 1);
        } else {
          localStorage.removeItem("enabled_state");
        }
      }

      if (!this.state.buttonDisabled) {
        window.addEventListener("beforeunload", this.handleBeforeUnload, {
          capture: true,
        });
      } else {
        window.removeEventListener("beforeunload", this.handleBeforeUnload, {
          capture: true,
        });
      }

      if (prevProps !== this.props) {
        let billdetails = [];
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (item) => {
              if (
                store.getState().meterDetails?.[primaryIdentifier] ===
                item[primaryIdentifier]
              ) {
                item.checked = true;
                amount = parseFloat(item.currentBalance);
                selectBills = [item];
                this.setState({
                  selectedBills: selectBills,
                  amountToPay: amount,
                  totalOutStandingBalance: 0,
                  initiatePaymentError: false,
                });
              } else {
                item.checked = false;
              }
              item.disabled = false;
              if (parseInt(item.currentBalance) <= 0) {
                item.disabled = true;
              }
              billdetails.push(item);
            }
          );
        this.setState({
          billDetails: billdetails,
          allData: store.getState().profileDetails,
          razorpayColor: store.getState()?.parametersWidgets?.ParameterLookup
            ?.PRIMARY_COLOR_2,
        });
      }
    } 
  }

  componentDidMount() {
    if (this.isNativePayment !== true) {
      if (this.state.buttonDisabled) {
        window.removeEventListener("beforeunload", this.handleBeforeUnload, {
          capture: true,
        });
      }

      let languageCode = "";
      if (
        store.getState()?.parametersWidgets?.ParameterLookup
          ?.TENANT_PRIMARY_LANGUAGE
      ) {
        languageCode = store.getState()?.parametersWidgets?.ParameterLookup
          ?.TENANT_PRIMARY_LANGUAGE;
        languageCode && this.functionAdmin(languageCode);
      } else {
        store.subscribe(() => {
          if (
            store.getState()?.parametersWidgets?.ParameterLookup
              ?.TENANT_PRIMARY_LANGUAGE
          ) {
            languageCode = store.getState()?.parametersWidgets?.ParameterLookup
              ?.TENANT_PRIMARY_LANGUAGE;
            languageCode && this.functionAdmin(languageCode);
          }
        });
      }

      let billdetails = [];
      store
        .getState()
        .accountInformation.personAcccountDetail.personAccountList.forEach(
          (item) => {
            item.disabled = false;
            item.checked = false;
            if (parseInt(item.currentBalance) <= 0) {
              item.disabled = true;
            }
            billdetails.push(item);
          }
        );
      this.setState({
        billDetails: billdetails,
      });
      if (store.getState().parametersWidgets.ParameterLookup) {
        let isMultiSelect =
          store.getState().parametersWidgets.ParameterLookup
            .MULTI_SELECT_TYPE === "true"
            ? true
            : false;
        let isPartialPay =
          store.getState().parametersWidgets.ParameterLookup
            .PARTIAL_PAY_ENABLE === "true"
            ? true
            : false;

        this.setState({
          isMultiSelect: isMultiSelect,
          isPartialPay: isPartialPay,
        });
        primaryIdentifier = store.getState().parametersWidgets.ParameterLookup
          .PRIMARY_IDENTIFIER;
      }
    }
  }
  componentWillMount() {
    if (this.isNativePayment !== true) {
      store.getState().snackbar = true;
      selectBills = [];
      if (store.getState().parametersWidgets.ParameterLookup) {
        this.setState({
          creditCardGatewayCode: store
            .getState()
            .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(
              ","
            )[1],
        });
      }
      if (store.getState().meterDetails) {
        this.state.billDetails.forEach((item) => {
          if (!item.disabled) {
            if (
              item.saDetails[0].saId ===
              store.getState().meterDetails.getSaInfo.saId
            ) {
              item.checked = true;
              totalSelAmount = parseFloat(item.saDetails[0].currentBalance);
              selectBills.push(item);
              this.setState({
                saId: store.getState().meterDetails.getSaInfo.saId,
              });
              selectBills.forEach((element, i) => {
                connectionDetails.push({
                  saId: element.saDetails[0].saId,
                  Amount: element.saDetails[0].currentBalance,
                });
              });
              localStorage.removeItem("PaymentConnections");
              localStorage.setItem(
                "PaymentConnections",
                JSON.stringify(connectionDetails)
              );
            }
          }
        });
        store.getState().snackbar = false;
        this.setState({
          selectedBills: selectBills,
        });
        store.getState().snackbar = false;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload, {
      capture: true,
    });
  }

  handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  updateErrorMessage() {
    let message = this.state.paymentErrorMessage;
    return message;
  }
  handleCheckbox = (event) => {
    connectionDetails = [];
    totalSelAmount = 0;
    if (event.target.checked) {
      if (event.target.value === "selectAll") {
        this.setState({
          totalOutStandingBalance: 0,
        });
        selectBills = [];
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (element) => {
              if (element.accountSaList[0].isPrepaidSa === "N") {
                if (!element.disabled) {
                  element.checked = true;
                  this.state.isMultiSelect == true
                    ? selectBills.push(element)
                    : (selectBills = [element]);
                }
              }
            }
          );
        this.setState({
          selectedBills: selectBills,
          selectAllDisable: true,
          initiatePaymentError: false,
        });
      } else {
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (element) => {
              if (element.accountSaList[0].isPrepaidSa === "N") {
                if (event.target.value === element[primaryIdentifier]) {
                  element.checked = true;
                  amount = amount + parseFloat(element.currentBalance);

                  this.state.isMultiSelect == true
                    ? selectBills.push(element)
                    : (selectBills = [element]);
                }
              }
            }
          );
      }
      let totalBalance = 0;
      if (selectBills.length >= 1 || this.state.isPartialPay == false) {
        selectBills.forEach(
          (item) =>
            (totalBalance = totalBalance + parseFloat(item.currentBalance))
        );
      }
      this.setState({
        selectedBills: selectBills,
        amountToPay: totalBalance,
        initiatePaymentError: false,
        totalOutStandingBalance: 0,
      });
    } else {
      if (event.target.value === "selectAll") {
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (element) => {
              element.checked = false;
              selectBills.pop(element);
            }
          );
        this.setState({
          selectedBills: selectBills,
          selectAllDisable: false,
          initiatePaymentError: false,
        });
      } else {
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (element) => {
              if (event.target.value === element[primaryIdentifier]) {
                element.checked = false;
                selectBills.forEach((item, i) => {
                  if (item[primaryIdentifier] === event.target.value) {
                    selectBills.splice(i, 1);
                    amount = 0;
                  }
                });
              }
            }
          );
      }
      selectBills.forEach((element, i) => {
        if (element.accountSaList[0].isPrepaidSa === "N") {
          totalSelAmount =
            parseFloat(totalSelAmount) + parseFloat(element.currentBalance);
          connectionDetails.push({
            saId: element[primaryIdentifier],
            Amount: element.currentBalance,
          });
        }
      });
      this.setState({
        selectedBills: selectBills,
        selectAllDisable: false,
        amountToPay: totalSelAmount,
        totalOutStandingBalance: 0,
        initiatePaymentError: false,
      });
    }
    localStorage.removeItem("billID");

    localStorage.removeItem("PaymentConnections");
    localStorage.setItem(
      "PaymentConnections",
      JSON.stringify(connectionDetails)
    );
  };

  parseAccountInfo = (input) => {
    let splitAccountInfo = input.split(" ");
    splitAccountInfo.splice(splitAccountInfo.length - 1, 1);
    return splitAccountInfo.join("");
  };

  handleAmountPayment = (value) => {
    let balance =
      value.indexOf(".") >= 0
        ? value.substr(0, value.indexOf(".")) +
          value.substr(value.indexOf("."), 3)
        : value;
    let balanceAmount = parseFloat(this.state.selectedBills[0]?.currentBalance);

    if (balance) {
      amount = parseFloat(balance);

      let amountError = false;
      if (amount < minAmount || amount > maxAmount || amount > balanceAmount) {
        amountError = true;
      }
      this.setState({
        amountToPay: amount,
        totalOutStandingBalance:
          parseFloat(this.state.selectedBills[0]?.currentBalance) - amount,
        initiatePaymentError: amountError,
      });
    } else {
      this.setState({
        amountToPay: "",
        totalOutStandingBalance: parseFloat(
          this.state.selectedBills[0]?.currentBalance
        ),
      });
    }
  };
  keyPress = (evt) => {
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
  };

  render() {
    if (store.getState().parametersWidgets.ParameterLookup) {
      minAmount = store.getState().parametersWidgets.ParameterLookup
        .E_MINIMUM_AMOUNT_VALUE;
      minAmount = Number(minAmount);
      maxAmount = store.getState().parametersWidgets.ParameterLookup
        .E_MAXIMUM_AMOUNT_VALUE;
    }
    console.log("testttttttttt", this.isNativePayment);

    return (
      <div>
        {this.isNativePayment=== true ? 
         <Payment
         totalAmount={this.state.amountToPay}
         allData={this.state.allData}
         selectBills={this.nativeAccount}
         errorMessage={
           this.state.paymentErrorMessage
         }
         buttonLabel={makePaymentsLabels?.E_PAY_NOW}
         saId={this.state.saId}
         isNative={true}
       />
        :
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={7} style={styles.yourAccountCard}>
            <br />
            <Typography variant="h6">
              <b>{makePaymentsLabels?.E_YOUR_ACCOUNTS_TITLE}</b>
            </Typography>
            <br />
            {this.state.billDetails.length === 1 ||
            this.state.isMultiSelect == false ? null : (
              <FormControlLabel
                control={
                  <Checkbox
                    value="selectAll"
                    onChange={this.handleCheckbox}
                    color="primary"
                    checked={this.state.selectAllDisable}
                  />
                }
                label={
                  <Typography style={styles.selectAll} variant="subtitle2">
                    <b>{makePaymentsLabels?.E_SELECT_ALL}</b>
                  </Typography>
                }
              />
            )}
            {this.state.billDetails.length !== 0 ? (
              <div>
                <List
                  dense={!this.props.isTabletOrMobile}
                  className="paddingList"
                >
                  <FormGroup>
                    {store
                      .getState()
                      .accountInformation.personAcccountDetail.personAccountList.map(
                        (item) => {
                          let labelText = (
                            <React.Fragment>
                              {this.props.isTabletOrMobile ? (
                                <div>
                                  <Typography
                                    style={styles.accountNo}
                                    variant="subtitle2"
                                  >
                                    <b>{makePaymentsLabels?.E_ACCOUNT_NO}</b>
                                    <br />
                                    {item[primaryIdentifier]}{" "}
                                    {
                                      item.accountPersonDetail
                                        .accountPremiseDetailList.address1
                                    }
                                    ,
                                    {
                                      item.accountPersonDetail
                                        .accountPremiseDetailList.address2
                                    }
                                    ,
                                    {
                                      item.accountPersonDetail
                                        .accountPremiseDetailList.city
                                    }
                                    ,
                                    {
                                      item.accountPersonDetail
                                        .accountPremiseDetailList.state
                                    }
                                    ,
                                    {
                                      item.accountPersonDetail
                                        .accountPremiseDetailList.postal
                                    }
                                  </Typography>
                                </div>
                              ) : (
                                <Grid xs={12}>
                                  <ListItem className="paddingList">
                                    <ListItemText
                                      primary={
                                        <Typography
                                          style={styles.accountNo}
                                          variant="subtitle2"
                                        >
                                          <b>
                                            {makePaymentsLabels?.E_ACCOUNT_NO}
                                          </b>
                                          {""}
                                          {item[primaryIdentifier]}
                                          <br />
                                          {
                                            item.accountPersonDetail
                                              .accountPremiseDetailList.address1
                                          }
                                          ,
                                          {
                                            item.accountPersonDetail
                                              .accountPremiseDetailList.address2
                                          }
                                          ,
                                          {
                                            item.accountPersonDetail
                                              .accountPremiseDetailList.city
                                          }
                                          ,
                                          {
                                            item.accountPersonDetail
                                              .accountPremiseDetailList.state
                                          }
                                          ,
                                          {
                                            item.accountPersonDetail
                                              .accountPremiseDetailList.postal
                                          }
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                </Grid>
                              )}
                            </React.Fragment>
                          );
                          return (
                            <React.Fragment>
                              {item.accountSaList[0].isPrepaidSa ===
                              "Y" ? null : (
                                <>
                                  <FormControlLabel
                                    control={
                                      item.disabled &&
                                      this.state.isMultiSelect ? (
                                        <StyledCheckbox disabled />
                                      ) : item.disabled &&
                                        this.state.isMultiSelect == false ? (
                                        <StyledRadiobox disabled />
                                      ) : this.state.isMultiSelect ? (
                                        <div>
                                          <Checkbox
                                            value={item[primaryIdentifier]}
                                            onChange={this.handleCheckbox}
                                            style={styles.checkBox}
                                            checked={item.checked}
                                            disabled={item.disabled}
                                          />
                                        </div>
                                      ) : (
                                        <Radio
                                          value={item[primaryIdentifier]}
                                          onChange={this.handleCheckbox}
                                          style={styles.checkBox}
                                          checked={this.state.selectedBills.includes(
                                            item
                                          )}
                                          disabled={item.disabled}
                                        />
                                      )
                                    }
                                    label={labelText}
                                  />
                                  <Divider />
                                  {this.props.isTabletOrMobile ? <br /> : null}
                                </>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                  </FormGroup>
                </List>
              </div>
            ) : null}
            <br />
            <div align="right">
              <Button
                style={styles.cantSeeButton}
                onClick={() => {
                  history.push({
                    pathname: routePaths.GET_SERVICE,
                    state: { tab: "E_COMPLAINT" },
                  });
                }}
              >
                {makePaymentsLabels?.E_CANT_SEE_AN_ACCOUNT}
              </Button>
            </div>
            <br />
            <Hidden smDown xlUp>
              <FooterContent />
            </Hidden>
          </Grid>

          <Grid item xs={12} md={5} style={styles.AccountBalanceGrid}>
            <Card style={styles.AccountBalanceCard}>
              <Typography style={styles.padding} variant="h6">
                <b>{makePaymentsLabels?.E_ACCOUNT_BALANCE}</b> <br />
              </Typography>
              <br />
              {this.state.selectedBills
                ? this.state.selectedBills.map((element) => {
                    return (
                      <>
                        <Grid container justify="space-between">
                          <Grid
                            item
                            xs={5}
                            sm={5}
                            md={4}
                            lg={6}
                            style={styles.secondPadding}
                          >
                            <Typography variant="subtitle2">
                              {makePaymentsLabels?.E_OUTSTANDING_BALANCE}
                              <br />
                              {element[primaryIdentifier]}
                            </Typography>
                          </Grid>
                          <Hidden xsDown>
                            <Grid
                              item
                              sm={3}
                              md={8}
                              lg={6}
                              style={styles.secondPadding}
                            >
                              <Typography align="right" variant="subtitle2">
                                {element.curSymbol}&nbsp;
                                {element.currentBalance}
                              </Typography>
                            </Grid>
                          </Hidden>
                          <Hidden smUp>
                            <Grid
                              item
                              sm={3}
                              xs={5}
                              style={styles.secondPadding}
                            >
                              <Typography align="right" variant="subtitle2">
                                {element.curSymbol}&nbsp;
                                {element.currentBalance}
                              </Typography>
                            </Grid>
                          </Hidden>
                        </Grid>
                      </>
                    );
                  })
                : null}
              <br />
              {this.state.selectedBills.length == 1 &&
              this.state.isPartialPay == true ? (
                <Grid container justify="space-between">
                  <Grid
                    item
                    md={5}
                    xs={3}
                    className={styles.margin}
                    style={styles.secondPadding}
                  >
                    <Typography variant="subtitle2">
                      {makePaymentsLabels?.E_AMOUNT_TO_PAY}
                    </Typography>
                  </Grid>
                  <Grid item lg={7} md={7} xs={6} style={styles.secondPadding}>
                    <Grid container justify="flex-end">
                      <Grid item xs={1} style={styles.margin}>
                        <Typography variant="subtitle2">
                          <b>
                            {
                              store.getState().accountInformation
                                .personAcccountDetail.personAccountList[0]
                                .curSymbol
                            }
                          </b>
                        </Typography>
                      </Grid>

                      <Grid item lg={6} md={6} xs={5}>
                        <RechargeTextField
                          fullWidth="false"
                          align="right"
                          value={this.state.amountToPay}
                          id="amount-to-pay"
                          type="number"
                          onKeyUp={(e) =>
                            this.handleAmountPayment(e.target.value)
                          }
                          onChange={(e) =>
                            this.handleAmountPayment(e.target.value)
                          }
                          onKeyPress={this.keyPress}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container justify="space-between">
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={4}
                    lg={6}
                    style={styles.secondPadding}
                  >
                    <Typography variant="subtitle2">
                      {makePaymentsLabels?.E_AMOUNT_TO_PAY}
                    </Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid
                      item
                      sm={3}
                      md={8}
                      lg={6}
                      style={styles.secondPadding}
                    >
                      <Typography align="right" variant="subtitle2">
                        {
                          store.getState().accountInformation
                            .personAcccountDetail.personAccountList[0].curSymbol
                        }
                        &nbsp;
                        {this.state.amountToPay.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Hidden smUp>
                    <Grid
                      item
                      sm={3}
                      xs={5}
                      style={styles.secondPaddingStyling}
                    >
                      <Typography variant="subtitle2">
                        {
                          store.getState().accountInformation
                            .personAcccountDetail.personAccountList[0].curSymbol
                        }
                        &nbsp;
                        {this.state.amountToPay.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>
              )}
              <br />
              <br />
              <Grid
                container
                justify="space-between"
                style={styles.totalOutStandingBalance}
              >
                <Grid item xs={6}>
                  <Typography align="left" variant="subtitle2">
                    {makePaymentsLabels?.E_TOTAL_OUTSTANDING_BALANCE}
                    <br />
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={5}>
                  <Typography align="right" variant="subtitle2">
                    {
                      store.getState().accountInformation.personAcccountDetail
                        .personAccountList[0].curSymbol
                    }
                    &nbsp;
                    {this.state.totalOutStandingBalance.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <div>
                  <Grid>
                    <br />
                  </Grid>
                  <Grid direction="row" align="center">
                    {this.state.initiatePaymentError ? (
                      <Grid Container>
                        <Grid item xs={12}>
                          <Alert severity="error">
                            {this.updateErrorMessage()}
                          </Alert>
                          <br />
                        </Grid>
                      </Grid>
                    ) : null}
                    {this.state.amountToPay < 1 ||
                    this.state.amountToPay > maxAmount ? (
                      <div>
                        {makePaymentsLabels && (
                          <span>
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              style={styles.payNowButton}
                            >
                              <Grid item>
                                <Button
                                  variant="contained"
                                  style={styles.disabledButton}
                                  disabled={true}
                                >
                                  {makePaymentsLabels?.E_PAY_NOW}
                                </Button>
                              </Grid>
                            </Grid>
                          </span>
                        )}
                        <br />
                      </div>
                    ) : (
                      <div>
                        {makePaymentsLabels && (
                          <span>
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              style={styles.payNowButton}
                            >
                              <Grid item>
                                {this.state.amountToPay ? (
                                  <Payment
                                    totalAmount={this.state.amountToPay}
                                    allData={this.state.allData}
                                    selectBills={this.state.selectedBills}
                                    errorMessage={
                                      this.state.paymentErrorMessage
                                    }
                                    buttonLabel={makePaymentsLabels?.E_PAY_NOW}
                                    saId={this.state.saId}
                                    isNative={this.isNativePayment}
                                    nativeAccount={this.nativeAccount}
                                  />
                                ) : null}
                              </Grid>
                            </Grid>
                          </span>
                        )}
                      </div>
                    )}
                  </Grid>
                </div>
              </Grid>
              <ContentAndRequestHelp />
            </Card>
            <br />
            <Hidden smUp>
              <FooterContent />
            </Hidden>
          </Grid>
        </Grid>}
      </div>
    );
  }
}
function StyledCheckbox(props: CheckboxProps) {
  const classes = useCustomCheckboxStyles();
  return <Checkbox icon={<span className={classes.icon} />} {...props} />;
}

function StyledRadiobox(props: RadioProps) {
  const classes = useCustomRadioStyles();
  return <Radio icon={<span className={classes.icon} />} {...props} />;
}
