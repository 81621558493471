import { appConstants } from "../constants/index";

export const ticketAction = {
  getTicketCategory
};

function getTicketCategory(category,ticketType){
    return dispatch => {
      dispatch(getCategory(category,ticketType));
    };
  
    function getCategory(category,ticketType) {
      return { type: appConstants.GET_CATEGORY, category:category,ticketType:ticketType };
    }
  }