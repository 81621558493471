import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import Usage from "../e_usage/e_usage";
// import Home from "../e_home_component/e_home";
import { PrivateRoute } from "../config/private-route";
import { routePaths } from "../config/route-paths";
import { Ecommerce } from "../common/ecommerce/Ecommerce";


const Usage =  React.lazy(() => import("../e_usage/e_usage"));
const Home =  React.lazy(() => import("../e_home_component/e_home"));
const SearchResults =  React.lazy(() => import("../e_layout/e_header/e_searchbar/e_search-controller"));
const E_Alerts_Notification =  React.lazy(() => import("../e_alerts_notification/e_alerts_notification"));
const Billing =  React.lazy(() => import("../e_billing/e_billing"));
const Sustainability =  React.lazy(() => import("../e_sustainability/e_sustainability"));
const Logout =  React.lazy(() => import("../config/logout.component"));
const SelfHelp = React.lazy(() => import("../e_self_help/e_self_help"));
const Tariff = React.lazy(() => import("../tariff/tariff-component"));
const PaymentReceipt = React.lazy(() => import("../e_billing/e_payment-details/e_payment-receipt-component"));
const GetSupportTicket = React.lazy(() => import("../ticket/get-support"));
const GetService = React.lazy(() => import("../e_service/e_service"));
const GetServiceRequest = React.lazy(() => import("../service/service-request/view/_service_request"));
const E_BillDetails = React.lazy(() => import("../e_bill-details/e_bill-details"));
const ProfileManagment = React.lazy(() =>  import("../e_profile_management/e_profile"));
const EPaymentConfirmationComponent = React.lazy(() =>  import("../e_payments/e_razor_pay/e_payment-confirmation/e_payment-confirmation-component"));
const RetryMain = React.lazy(() => import("../common/retry/retry.jsx"));
const ManagePreference = React.lazy(() => import("../e_manage_preferences/e_manage_preferences"));
const Outage = React.lazy(() => import("../e_outage/e_outage"));
const paymentNew = React.lazy(() => import("../e_payments/e_multiple_payments/e_multiple_payments_new"))
const EnergyPlans = React.lazy(() => import("../energy-plans/energy-plans"));
const EnergyDetailsPage = React.lazy(() => import("../energy-details-page/energy-details-page"));
const Your_Rewards = React.lazy(() => import("../e_your_rewards/e_your_rewards"));

const Routes = () => {
  return (
    <Suspense fallback={<div></div>}>
    <Switch>
      <Route path={routePaths.LOGOUT} component={Logout} />
      <PrivateRoute exact path={routePaths.HOME} component={Home} />
      <PrivateRoute exact path={routePaths.SELF_HELP} component={SelfHelp} />
      <PrivateRoute exact path={routePaths.TARIFF} component={Tariff} />
      <PrivateRoute  path={routePaths.PAYMENT_RECEIPT} component={PaymentReceipt} />
      <PrivateRoute exact path={routePaths.BILLING} component={Billing} />
      <PrivateRoute exact path={routePaths.GET_SUPPORT} component={GetSupportTicket} />
      <PrivateRoute exact path={routePaths.GET_SERVICE} component={GetService} />
      <PrivateRoute exact path={routePaths.GET_SERVICE_REQUEST} component={GetServiceRequest} />
      <PrivateRoute exact path={routePaths.SEARCH_RESULTS} component={SearchResults} />
      <PrivateRoute path={routePaths.BILL_DETAILS} component={E_BillDetails} />
      <PrivateRoute exact path={routePaths.PROFILE} component={ProfileManagment} />
      <PrivateRoute exact path={routePaths.PAYMENT_CONFIRMATION} component={EPaymentConfirmationComponent} />
      <PrivateRoute exact path={routePaths.RETRY} component={RetryMain} />
      <PrivateRoute exact path={routePaths.ALERTS_AND_NOTIFICATION} component={E_Alerts_Notification} />
      <PrivateRoute exact path={routePaths.MANAGE_PREFERENCE} component={ManagePreference} />
      <PrivateRoute exact path={routePaths.USAGE} component={Usage} />
      <PrivateRoute exact path={routePaths.OUTAGE} component={Outage} />
      <PrivateRoute exact path={routePaths.ENERGY_PLANS} component={EnergyPlans} />
      <PrivateRoute path={routePaths.ENERGY_DETAILS_PAGE} component={EnergyDetailsPage} />
      <PrivateRoute exact path = {routePaths.SUSTAINABILITY} component={Sustainability} />
      <PrivateRoute exact path = {routePaths.INITIATE_PAYMENT} component={paymentNew} />
      <PrivateRoute exact path = {routePaths.ECOMMERCE} component={Ecommerce} />
      <PrivateRoute exact path={routePaths.Your_Rewards} component={Your_Rewards} />
      <Redirect from="*" to={routePaths.HOME} />
    </Switch>
    </Suspense>
  );
};

export default Routes;
