import React, { useState, useEffect } from "react";
import { Grid, Typography, CardContent, Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";
import { store } from "../../../../helpers/store";
import { makeStyles, createStyles } from "@material-ui/core";
import { commonService } from "../../../../services/common.service";
import { useMediaQuery } from "react-responsive";
import { useTranslationCustom } from "../../../../i18nOverrides";

const useStyles = makeStyles((theme) =>
  createStyles({
    footerCard: {
      padding: "0% 0% 0% 0%",
    },
    image: {
      height: "330px",
      marginBottom: 20,
    },
    margin: {
      marginBottom: 20,
      fontSize: "0.85rem !important",
    },
  })
);

export default function FooterContent() {
  const customStyle = useStyles();
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  // const Labels =useSelector((state) => state?.labels?.data?.getLabels?.E_MAKE_PAYMENT );
  const { t: labels, ready: labelReady } = useTranslationCustom(
    "label-E_MAKE_PAYMENT"
  );
  const { t: content, ready: contentReady } = useTranslationCustom(
    "content-E_MAKE_PAYMENTS"
  );
  const [footerSubtitle, setFooterSubTitle] = useState("");
  const [inquirySubtitle, setInquirySubTitle] = useState("");
  const languageCode = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  let tenantName = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.TENANT_NAME
  );
  useEffect(() => {
    let manageTitle;
    store.getState().snackbar = true;
    if (contentReady) {
      setFooterSubTitle(
        content("E_FOOTER_CONTENT")?.answer?.replace(
          new RegExp("<TENANT_NAME>", "g"),
          tenantName
        )
      );
      setInquirySubTitle(
        content("E_MAKE_PAYMENTS_ERROR_MESSAGE")?.answer?.replace(
          new RegExp("<TENANT_NAME>", "g"),
          tenantName
        )
      );
    }
  }, [contentReady, content]);
  const [paymentImagePath, setPaymentImagePath] = useState("");
  let paymentImage = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.E_MAKE_PAYMENT_IMAGE
  );
  useEffect(() => {
    if (paymentImage) {
      commonService.getAssestUrl(paymentImage).then((response) => {
        setPaymentImagePath(response);
      });
    }
  }, [paymentImage]);
  return (
    <>
      {isDesktop ? (
        <>
          {paymentImagePath && (
            <img
              className={customStyle.image}
              src={paymentImagePath?.assetPath}
              alt={labels(paymentImagePath?.fallBackLabelKey)}
            />
          )}
          <br />
          <br />
        </>
      ) : null}
      <CardContent>
        <Grid container className={customStyle.footerCard}>
          <Grid item>
            <Typography
              variant="caption"
              align="left"
              className={customStyle.margin}
              dangerouslySetInnerHTML={{
                __html: footerSubtitle,
              }}
            ></Typography>
            <Typography
              variant="caption"
              align="left"
              className={customStyle.margin}
              dangerouslySetInnerHTML={{
                __html: inquirySubtitle,
              }}
            ></Typography>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}
