import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { appStyles } from "../../app/app-style";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { PaymentService } from "../e_razor_pay/model/e_service";
import { history } from "../../../helpers/history";
import { routePaths } from "../../config/route-paths";
import { makeStyles } from "@material-ui/core";
import { RouteContext } from "../e_multiple_payments/e_multiple_payments";
import { config } from "../../../enviroment";
import { useTranslationCustom } from "../../../i18nOverrides";
import {logService} from "../../../services/log.service"

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: "1rem",
    backgroundColor: theme.palette.eSecondary?.base,
    color: theme.palette.eWhite?.base,
    borderRadius: "30px",
    align: "left",
  },
  disabledButton: {
    align: "right",
    justifyContent: "right",
    justify: "right",
    backgroundColor: appStyles.outerTheme.palette.eSecondary?.base,
    color: appStyles.outerTheme.palette.eWhite?.base,
    borderRadius: "30px",
    cursor: "not-allowed !important",
  },
}));

export default function Payment({
  totalAmount,
  allData,
  selectBills,
  errorMessage,
  buttonLabel,
  buttonStyle,
  buttonProps,
  prepaidEngineMinimumRechargeError,
  isNative,
  bearer,
}) {
  bearer = bearer ? bearer : JSON.parse(localStorage.getItem("bearer"));
  const [selected, setSelected] = useState();
  let accountDataInfo = useSelector(
    (state) =>
      state?.accountInformation?.personAcccountDetail?.personAccountList
  );
  let profileData = useSelector((State) => State?.profileDetails);
  useEffect(() => {
    if (isNative === true) {
      btnCall();
    }
  }, [isNative, accountDataInfo]);
  let contextData = useContext(RouteContext);
  let [routeData, setRouteData] = useState({});
  useEffect(() => {
    if (history?.location?.state?.source === "STOP_SERVICE") {
      setRouteData(contextData?.routeData);
    }
  }, [history?.location]);

  const [err, seterr] = useState(false);
  const [errMsg, setErrMsg] = useState(errorMessage);
  const [paymentConfirmationWidget, setPaymentConfirmationWidget] = useState(
    true
  );
  const [nativeError, setNativeErr] = useState(false);
  const [openDialog, setOpen] = useState(false);
  const { t: labels, ready: labelReady } = useTranslationCustom(
    "label-E_MAKE_PAYMENT"
  );
  const { t: errorLabel, ready: errorLabelReady } = useTranslationCustom(
    "label-SERVER_EXCEPTIONS"
  );

  let razorpayColor = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.PRIMARY_COLOR_2
  );

  let widgets = useSelector(
    (state) => state?.parametersWidgets?.WidgetLookUp?.E_PAYMENT_CONFIRMATION
  );

  useEffect(() => {
    widgets &&
      widgets.map((item) => {
        if (item.widgetCode === "E_PAYMENT_CONFIRMATION") {
          setPaymentConfirmationWidget(
            item.widgetDefaultValue === "true" ? true : false
          );
        }
      });
  }, [widgets]);

  const debounce = (fn, delay) => {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const btnCall = () => {
    handleClickOpen();
    let amountPay = parseInt(totalAmount * 100);
    let accountData = [];
    if (isNative !== true) {
      if (selectBills.length === 1) {
        accountData.push({
          accountId: selectBills?.[0]?.accountId,
          amount: totalAmount,
        });
      } else {
        selectBills.forEach((element) => {
          return accountData.push({
            accountId: element.accountId,
            amount: element.currentBalance,
          });
        });
      }
    } else {
      // accountData = JSON.parse(selectBills);
      // partial payment change
      const parseSelectBills = JSON.parse(selectBills);
         accountData.push({
          accountId:parseSelectBills?.[0]?.accountId,
          amount:totalAmount
        })     
    }
    let accountId = accountData?.[0]?.accountId;
    logService.postExternalLog('UI_CLIENT_PREPAYMENT_REQUEST',JSON.stringify({accountInfo:accountData}))

    PaymentService.prepayment(amountPay, accountId, bearer)
      .then((res) => {
        if (res?.data?.prePaymentDetailsRazorPay) {
          seterr(false);
          logService.postExternalLog('UI_CLIENT_REDIRECT_PAYMENTGATEWAY',JSON.stringify({accountInfo:accountData}))
          displayRazorPay(res?.data?.prePaymentDetailsRazorPay, accountData);
          handleClose();
        }
      })
      .catch((err) => {
        handleClose();
        if (isNative) {
          window.location.href =
            config.constants.NATIVEAPP_URL +
            "Payments?accounts=" +
            selectBills +
            "&amount=" +
            totalAmount +
            "&paymentStatus=fail";
        }
        if (err?.response?.status === 400) {
          seterr(true);
          logService.postExternalLog('UI_CLIENT_PREPAYMENT_ERROR',JSON.stringify({accountInfo:accountData,message:err?.response?.data?.errors[0]?.message}))
          setErrMsg(err?.response?.data?.errors[0]?.message);
          handleClose();
        } else if (err?.response?.status === 500) {
          seterr(true);
          logService.postExternalLog('UI_CLIENT_PREPAYMENT_ERROR',JSON.stringify({accountInfo:accountData,message:errorLabel("SERVER_EXCEPTIONS_NOT_RIGHT")}))
          setErrMsg(errorLabel("SERVER_EXCEPTIONS_NOT_RIGHT"));
          handleClose();
        } else {
          seterr(true);
          logService.postExternalLog('UI_CLIENT_PREPAYMENT_ERROR',JSON.stringify({accountInfo:accountData,message:errorLabel("SERVER_EXCEPTIONS_NOT_RIGHT")}))
          setErrMsg(errorLabel("SERVER_EXCEPTIONS_NOT_RIGHT"));
          handleClose();
        }
      });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorPay = async(orderData, accountData) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    // this.loadScript.use(cors());

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log("alldata", profileData);
    const { amount, id: order_id, currency } = orderData;
    const options = {
      key: "rzp_test_dtRg3HFLmfZtiP", // Enter the Key ID generated from the Dashboard
      amount: totalAmount.toString(),
      currency: currency,
      name: "Abjayon",
      description: "Test Transaction",
      // image: { logo },
      order_id: order_id,
      // callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      handler: function(response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        if (isNative) {
          localStorage.setItem("nativeRedirect", "PAYMENTCONFIRM");
        }
        paymentConfirmationWidget &&
          history.push(routePaths.PAYMENT_CONFIRMATION, [
            response,
            orderData,
            accountData,
            routeData,
          ]);
           if (isNative) {
          window.location.reload();
        }
      },
      prefill: {
        name:
        isNative?"Eugene Morris":
          profileData?.firstName || profileData?.lastName
            ? (profileData.firstName + " " + profileData.lastName)?.replaceAll(
                ",",
                " "
              )
            : "-",
        email:isNative?"eugenemorris@mailinator.com": profileData?.email,
        contact:isNative?"+15108669331": profileData?.phoneNumber,
      },
      notes: {
        address: profileData?.address,
      },
      theme: {
        color: isNative?"#15537c":razorpayColor,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    console.log("payment object open");

    paymentObject.on("payment.failed", function(response) {
      console.log("payment.failed-------------------");

      history.push("/", [response, "", accountData]);
    });
  };
  const classes = useStyle();

  return (
    <>
      {isNative ? (
        <Typography variant="h4" style={{ marginTop: "10%" }} align="center">
          Redirecting...
        </Typography>
      ) : (
        <div justify="right">
          <Button
            type="submit"
            variant="contained"
            disabled={
              prepaidEngineMinimumRechargeError
                ? true
                : false
            }
            onClick={ debounce(btnCall, 200) }
            className={
              prepaidEngineMinimumRechargeError 
                ? classes.disabledButton
                : buttonStyle
                ? buttonStyle
                : classes.root
            }
            {...buttonProps}
          >
            {buttonLabel}
          </Button>

          {err && <Alert severity="error">{errMsg}</Alert>}
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
          >
            <DialogTitle id="simple-dialog-title">
              <Typography color="primary" variant="h5">
                <b>{labels("E_LOADING_DIALOG_TITLE")}</b>
              </Typography>
            </DialogTitle>
            <DialogContent align="center">
              <div className="margin-top-20">
                <CircularProgress color="primary" size={60} />
              </div>
            </DialogContent>
            <DialogActions>
              <Typography className="padding-10">
                {labels("E_LOADING_DIALOG_SUBTITLE")}
              </Typography>{" "}
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}
