import { appConstants } from "../constants";

const initialState = {};
export function parametersWidgets(state = initialState, action) {
  switch (action.type) {
    case appConstants.PARAMETER_WIDGET_SUCCESS: {
      return {
        ...state,
        ...action.params
      };
    }
    case appConstants.PARAMETER_WIDGET_FAILURE:
      return state;
    default:
      return state;
  }
}

const browserName = "";
export function browserDetect(state = browserName, action) {
  switch (action.type) {
    case appConstants.BROWSER_DETECT: {
      return {
        ...state,
        browserName: action.browserName
      };
    }
    default:
      return state;
  }
}

const snackbarparam = "";
export function snackbar(state = snackbarparam, action) {
  switch (action.type) {
    case appConstants.SNACKBAR_SUCCESS:
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
}

const initialStateAppLogo = {};
export function appLogo(state = initialStateAppLogo, action) {
  switch (action.type) {
    case appConstants.APPLOGO_SUCCESS: {
      return {
        ...state,
        ...action.logoResponse
      };
    }
    case appConstants.APPLOGO_FAILURE:
      return state;
    default:
      return state;
  }
}

const initialStateProfileIcon = {};
export function profileIcon(state = initialStateProfileIcon, action) {
  switch (action.type) {
    case appConstants.PROFILE_ICON_SUCCESS: {
      return {
        ...state,
        ...action.iconResponse
      };
    }
    case appConstants.PROFILE_ICON_FAILURE:
      return state;
    default:
      return state;
  }
}

const initialStateProfileDarkIcon = {};
export function profileDarkIcon(state = initialStateProfileDarkIcon, action) {
  switch (action.type) {
    case appConstants.PROFILE_DARK_ICON_SUCCESS: {
      return {
        ...state,
        ...action.darkIconResponse
      };
    }
    case appConstants.PROFILE_DARK_ICON_FAILURE:
      return state;
    default:
      return state;
  }
}