import { Box } from "@material-ui/core";
import React from "react";
import { config } from "../../../enviroment";

export function Ecommerce() {
  const url = config.constants.BASE_URL + ":" + config.constants.ECOMMERCE_FRONTEND_PORT
  return (
    <Box style={{ margin: "0px", padding: "0px", overflow: "hidden",
    height:"100%", width: "100%" 
    }}>
      <iframe
        src={url}
        title="store"
        key={"store"}
        style={{
            overflow:"hidden",
            height:"100%",
            width:"100%",
        }}
        height="100%"
        width={"100%"}
        frameBorder="0"
      />
    </Box>
  );
}
