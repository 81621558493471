import React from "react";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // const isLocalhost = Boolean(
    //   window.location.hostname === 'localhost' ||
    //     // [::1] is the IPv6 localhost address.
    //     window.location.hostname === '[::1]' ||
    //     // 127.0.0.0/8 are considered localhost for IPv4.
    //     window.location.hostname.match(
    //       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    //     ),
    // );
    // if(isLocalhost)
      this.setState({error:error,errorInfo:errorInfo})
  }
  render() {
    if (this.state.hasError || this.state.errorInfo || this.state.error) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <h1>Something went wrong.</h1>
          {
            this.state.error &&
            <>
            <p>Error Information</p>
            <p>{this.state.error.toString()}</p>
            </>
          }
          {
            this.state.errorInfo &&
            <>
            <p>More Error Information</p>
            <p>{JSON.stringify(this.state.errorInfo)}</p>
            </>
          }
        </React.Fragment>
      )
    }
    return this.props.children; 
  }
}