import axios from "axios";
import { config } from "../../../../enviroment";
import { apolloClientService } from '../../../../services/apollo-client';

export function getContentData(languageCode, categoryCode) {
    if (localStorage.getItem("bearer")) {
      const GET_CONTENT=`{getContents(input:{languageCode:"${languageCode}",categoryCode:"${categoryCode}"}) }`;
      return apolloClientService.ApolloClientgqls(GET_CONTENT);
     
    }
  }

export function getAssetUrl(assestCode: string): Promise<AssestResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(config.urls.ASSEST_CODE_API +"/" + assestCode)
        .then(function(response) {
          let returnData: AssestResponse = {
            assetCode: response.data.assetCode,
            assetPath: config.urls.ASSEST_URL_ENDPOINT + response.data.assetPath,
            fallBackLabelKey: response.data.fallBackLabelKey,
          };
          resolve(returnData);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }