import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem, Typography, Tooltip, Toolbar } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector,useDispatch } from "react-redux";
import { updateMeterDetails } from "../../../../../actions/home.action";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useMediaQuery } from "react-responsive";
import EAccount_SA_Switched_Warning from "../../../../e_account_sa_switch_warning/e_account-sa-switched-warning";


const useStyles = makeStyles((theme) => ({
  blueText: {
    backgroundColor: theme.palette.ePrimary?.base,
    borderRadius:"4px",
    padding:"2px 9px",
    border: "1px solid " +theme.palette.ePrimary?.base+ " !important",
    justifyContent: "flex-start",
    color:theme.palette.eWhite?.base,
    fontSize: "0.875rem",
    maxWidth: "170px",
    maxHeight: "45px",
    height: "45px",
    lineHeight: "1.4",
  },
  mobilePadding:{
    padding:"6px 1px"
  },
  tooltipStyle: {
    background: theme?.palette?.eWhite?.base,
    padding: '0.5rem 0.8rem',
    color: theme.palette.ePrimary.base,
    fontSize: '0.7rem',
    border: '1px solid ' + theme.palette.ePrimary.base,
    fontWeight: "bold"
  },
  blueTextColor: {
    maxWidth: "170px",
    borderRadius:"4px",
    fontSize: "0.875rem",
    width: "170px",
    maxHeight: "37px",
    "& .MuiSelect-select": {
      paddingRight: "18px",
      "& .MuiTypography-subtitle1": {
        backgroundColor: theme.palette.ePrimary?.base,
        padding: 0,
        height: "37px",
        lineHeight: 1.5,
      },
    },
  },
  dropdownblueText: {
    color:theme.palette.eWhite?.base,
    maxWidth: "170px",
    padding:"2px 9px",
    backgroundColor: theme.palette.ePrimary?.hover,
    borderRadius:"0",
    justifyContent: "flex-start",
    fontSize: "0.875rem",
    width: "100%",
    maxHeight: "55px",
    height: "55px", 
    "&:hover": {
      backgroundColor: theme.palette.ePrimary?.base,
    },  
  },
  headaddress: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "0.7rem",
    display: "block",
    fontStyle: "italic",
  },
  list:{
    borderRadius: "0 0 4px 4px !important",
    "& .MuiList-padding": {
      padding:0,
      "& .MuiMenuItem-root": {
        padding: 0,
      }
    } ,
  },
}));

export const STYLED_SELECT = withStyles((theme) => ({
  root: {
    fontSize: "0.875rem",
    borderRadius: "4px",
    padding: "3px 9px",
    justifyContent: "flex-start",
    color: theme.palette.eWhite?.base,
    whiteSpace: "pre !important",
    backgroundColor: theme.palette.ePrimary?.base + " !important",
},
select: {
    "&:focus": {
        borderRadius: "4px",
    },
    "&[aria-expanded='true']": {
      borderRadius: "4px 4px 0 0",
    },
    whiteSpace: "pre-line",
  },
  icon: {
    color: theme.palette.eWhite?.base,
  },
  
}))(Select);

export const STYLED_MENU_ITEM = withStyles((theme) => ({
  root: {
    fontSize:"0.8rem",
    justifyContent:"flex-start",
    color: theme.palette.eRich?.base,
  }
}))(MenuItem);

export const getPremiseDetailsForAcct = (accountDetails) => {
  let premise = accountDetails?.accountPersonDetail?.accountPremiseDetailList;
  if(premise) {
    return premise.length > 1 ? premise[0]?.address1+ ', ' + premise[0]?.city + ', ' + premise[0]?.country : premise?.address1 + ', ' + premise?.city + ', ' + premise?.country;
  }
}

export default function E_AccountDropdown() {
  const [connection, setConnection] = useState("");
  const [account,setAccount]=useState(null)
  const [showWarning,setShowWarning]=useState(false)
  const [openDialog,setOpenDialog]=useState(false)
  let dispatch=useDispatch();
  let classes=useStyles();
  const isMobile = useMediaQuery({ minWidth: 380 });
  let accountList = useSelector(
    (store) =>
      store?.accountInformation?.personAcccountDetail?.personAccountList
  );
  let primaryIdentifier = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.PRIMARY_IDENTIFIER
  );
  let accountId = useSelector(
    (store) => store?.meterDetails[primaryIdentifier]
  );

  useEffect(() => {
      localStorage.setItem("accountSelected",accountId)
    if (accountId) {
      setConnection(accountId);
    }
  }, [accountId]);

  const updateDetails=()=>{
    setOpenDialog(false)
    setAccount(null)
    let selectedConnetionDetails; 
    let saId = connection
    if (accountList) {
      accountList.forEach((single, k) => {
        selectedConnetionDetails = single?.accountSaList?.forEach((item, i) => {
          if (Number(single?.[primaryIdentifier]) === Number(saId)) {
            if(i === 0) {
              localStorage.removeItem("enabled_state")
              dispatch(updateMeterDetails(single, item));
            }
          }
        });
      });
    }

  }

  const sameDetails=()=>{
    setOpenDialog(false)
    setConnection(account)
    setAccount(null)

  }
  
  const handleConnectionOnChange = (event) => {
    let selectedConnetionDetails; 
    let accountId = event.target.value;
    localStorage.setItem("accountSelected",event.target.value);
    if (accountList) {
      accountList.forEach((account, k) => {
        selectedConnetionDetails = account?.accountSaList?.forEach((sa, i) => {
          if (Number(account?.[primaryIdentifier]) === Number(accountId)) {
            if(i === 0) {
              // let enabled_state_value=localStorage.getItem("enabled_state")
              // if(enabled_state_value){
                setAccount(connection)
                setConnection(event.target.value);
                setShowWarning(true)
                setOpenDialog(true)
                // return
              // }
              // setConnection(event.target.value);
              // dispatch(updateMeterDetails(account, sa));
            }
          }
        });
      });
    }
  };
  const ddlMenuProps = {
    disableScrollLock: false,
    anchorOrigin: {
      vertical: "bottom"
    },
    transformOrigin: {
      vertical: "top"
    },
    getContentAnchorEl: null,
    classes: { paper: classes.list },
  };
  return (
    <>
    {showWarning ? (<EAccount_SA_Switched_Warning 
    heading="accountId"
    openDialog={openDialog}
    sameDetails={sameDetails}
    updateDetails={updateDetails}
    />):null}
    <Grid item xs={12}>
      {accountList && accountList.length === 1 ? (
        <Tooltip title={`${accountList?.[0]?.[primaryIdentifier]} - ${getPremiseDetailsForAcct(accountList?.[0])}`} classes={{tooltip: classes.tooltipStyle}}>
          <Typography variant="subtitle1" className={`${classes.blueText} ${!isMobile ? classes.mobilePadding :''}`}>{accountList?.[0]?.[primaryIdentifier]}<br/><span className={classes.headaddress}>{getPremiseDetailsForAcct(accountList?.[0])}</span></Typography>
        </Tooltip>
      ) : (
        <STYLED_SELECT
          value={account || connection}
          IconComponent={ExpandMoreSharpIcon}
          disableUnderline={true}
          className={classes.blueTextColor}
          MenuProps={ddlMenuProps}
          onChange={handleConnectionOnChange}
        >
          {accountList !== undefined &&
            accountList.map((account, k) => {
              return (
                <STYLED_MENU_ITEM key={k} value={account?.[primaryIdentifier]}>
                  <Typography variant="subtitle1" className={`${classes.dropdownblueText} ${!isMobile ? classes.mobilePadding :''}`}>{account?.[primaryIdentifier]} <br/><span className={classes.headaddress}>{getPremiseDetailsForAcct(account)}</span></Typography>
                </STYLED_MENU_ITEM>
              );
            })}
        </STYLED_SELECT>
      )}
      </Grid>
    </>
  );
}
