export const meterAlertsConstant = {
    METER_ALERTS_START: "METER_ALERTS_START",
    METER_ALERTS_SUCCESS: "METER_ALERTS_SUCCESS",
    METER_ALERTS_FAILURE: "METER_ALERTS_FAILURE",

    METER_ALERTS_SEARCH_START: "METER_ALERTS_SEARCH_START",
    METER_ALERTS_PAGE_START: "METER_ALERTS_PAGE_START",
    METER_ALERTS_TABLE_START: "METER_ALERTS_TABLE_START",
    METER_ALERTS_WITHOUT_PAGINATION_START:"METER_ALERTS_WITHOUT_PAGINATION_START",
  };
  