import {billsandPaymentsHistoryConstants} from './constants';

const billsandPaymentsHistoryInitialState = {};
export const billsandPaymentsHistoryData = (
  state = billsandPaymentsHistoryInitialState,
  action
) => {
  switch (action.type) {
    case billsandPaymentsHistoryConstants.BILLS_AND_PAYMENTS_HISTORY_DATA_SUCCESS:
        return { ...state, data: action.data };
    case billsandPaymentsHistoryConstants.BILLS_AND_PAYMENTS_HISTORY_TAB_SUCCESS:
        return { ...state, tab: action.data };
    default:
      return state;
  }
}