export const billPreferenceConstant = {
  SEND_BILL_ROUTING_START: "SEND_BILL_ROUTING_START",
  SEND_BILL_ROUTING_SUCCESS: "SEND_BILL_ROUTING_SUCCESS",
  SEND_BILL_ROUTING_FAILURE: "SEND_BILL_ROUTING_FAILURE",

  SEND_PAYMENT_PREFERENCE_START: "SEND_PAYMENT_PREFERENCE_START",
  SEND_PAYMENT_PREFERENCE_SUCCESS: "SEND_PAYMENT_PREFERENCE_SUCCESS",
  SEND_PAYMENT_PREFERENCE_FAILURE:"SEND_PAYMENT_PREFERENCE_FAILURE",

  SEND_BILL_ALERTS_NOTIFICATION_START: "SEND_BILL_ALERTS_NOTIFICATION_START",
  SEND_BILL_ALERTS_NOTIFICATION_SUCCESS: "SEND_BILL_ALERTS_NOTIFICATION_SUCCESS",
  SEND_BILL_ALERTS_NOTIFICATION_FAILURE: "SEND_BILL_ALERTS_NOTIFICATION_FAILURE",

  HANDLE_CHANGE_BILL_ROUTING:"HANDLE_CHANGE_BILL_ROUTING",
  HANDLE_CHANGE_BILL_ALERTS:"HANDLE_CHANGE_BILL_ALERTS",
  HANDLE_BILLS_AND_PAYMENTS_PREFERENCES: "HANDLE_BILLS_AND_PAYMENTS_PREFERENCES",
  HANDLE_CHANGE_BILL_ALERTS_DATA:"HANDLE_CHANGE_BILL_ALERTS_DATA",
  HANDLE_CHANGE_BILL_FORECAST_PREFERENCE: "HANDLE_CHANGE_BILL_FORECAST_PREFERENCE",
  UPDATE_BILL_FORECAST_PREFERENCE_START: "UPDATE_BILL_FORECAST_PREFERENCE_START",
  UPDATE_BILL_FORECAST_PREFERENCE_SUCCESS: "UPDATE_BILL_FORECAST_PREFERENCE_SUCCESS",
  UPDATE_BILL_FORECAST_PREFERENCE_FAILURE: "UPDATE_BILL_FORECAST_PREFERENCE_FAILURE",
};
