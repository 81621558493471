import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import {
  Badge,
  List,
  ListItemText,
  Popper,
  Grow,
  Paper,
  ListSubheader,
  Button,
  makeStyles,
  ClickAwayListener,
  Divider,
  Link,
  Typography,
  withStyles,
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { routePaths } from "../../../config/route-paths";
import { history } from "../../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "./model/notification.action";
import { meterAlertsCall } from "./model/service";
import getNotificationAPI from "./model/service";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const CustomBadge =  withStyles((theme) => ({
  root: {
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.eDanger?.dark,
      color: theme.palette.eWhite?.base
   }
  }
}))(Badge);


const useStyles = makeStyles((theme) => ({
  blueText: {
    color: theme.palette.ePrimary?.base,
    textTransform: "capitalize",
    fontSize: "12px",
  },
  blueButton: {
    color: theme.palette.ePrimary?.base,
    border: "1px solid " + theme.palette.ePrimary?.base,
    borderRadius: "20px",
    textTransform: "capitalize",
    padding: "1% 6%",
    fontSize: "12px",
    marginRight: "5%",
    "&&:hover": {
      backgroundColor: theme.palette.ePrimary?.base,
      color: theme.palette.eWhite?.base,
    },
  },
  blueBox: {
    backgroundColor: theme.palette.ePrimary?.base,
    borderRadius: "2px",
    border: "2px solid " + theme.palette.ePrimary?.base + " !important",
    justifyContent: "flex-start",
    color: theme.palette.eWhite?.base,
  },
  hoverButton: {
    "&&:hover": {
      backgroundColor: theme.palette.ePrimary?.base,
      color: theme.palette.eWhite?.base,
    },
  },
  notificationButton: {
    padding:"9%",
    margin: "0.625rem",
    border: "0.125rem solid transparent",
  },
  styleIndex: {
    minWidth:"13rem",
    zIndex: "2",
  },
  listItemStyle: {
    padding: "1% 6%",
    backgroundColor: theme.palette.eBackground?.base,
    "&:hover": {
      backgroundColor: theme.palette.eBackground?.hover,
      cursor: "pointer"
    },
  },
  buttonTopStyle: {
    marginTop: "3%",
  },
  paddingButton: {
    margin: "0",
  },
  alertsList: {
    backgroundColor: theme.palette.eBackground?.base
  },
  readMore: {
    marginLeft: "3rem",
    textDecoration: "underline",
    color: theme.palette.ePrimary?.base,
    "&:hover": {
      color: theme.palette.ePrimary?.hover,
      cursor: "pointer"
    },
  },
  whiteIcon:{
    color:theme.palette.ePrimary.base
  }
}));

export default function E_NotificationIcon() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ minWidth: 380 });
  let classes = useStyles();
  const anchorRef = React.useRef(null);
  let [open, setOpen] = useState(false);
  let labels = useSelector((state) => state?.labels?.data?.getLabels?.E_HEADER);
  let curSymbol = useSelector((state) => state?.meterDetails?.curSymbol);
  let dueAmount = useSelector((state) => state?.meterDetails?.currentBalance);
  let updateDotApiCount =  useSelector((state) => state?.e_updateDotCount?.data);
  let dueDate = useSelector((state) => state?.meterDetails?.latestDueDate);
  const [redDot, setRedDot] = React.useState(false);
  const [totalUnread, setTotalUnread] = useState();
  const [unreadData, setUnreadData] = useState([])
  let saId = useSelector((store) => store?.meterDetails?.getSaInfo?.saId);
  let accountId = useSelector((store) => store?.meterDetails?.accountId);
  let customerClassCd = useSelector(
    (store) => store?.meterDetails?.customerClassCd
  );
  let dateFormat = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.SHORT_DATE_FORMAT
  );
  let isPrepaidSa = useSelector(
    (store) => store?.meterDetails?.getSaInfo?.isPrepaidSa
  );
  const backendDataFormat = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.YEAR_DATE_FORMAT
  );

  useEffect(() => {
    if (accountId && customerClassCd) {      
      makeMeterAlertsCall()
    }
  }, [accountId,customerClassCd]);

  const makeMeterAlertsCall = () => {
    getNotificationAPI(accountId,customerClassCd)
      .then(res => {
        let total = 0;
        if(isPrepaidSa === "Y") {
          if(Number(res?.data?.notificationDot?.PrepaidLowBalance)) total += res?.data?.notificationDot?.PrepaidLowBalance;
          if(Number(res?.data?.notificationDot?.PrepaidAccountCharge)) total += res?.data?.notificationDot?.PrepaidAccountCharge;
          if(Number(res?.data?.notificationDot?.PrepaidMinimumBalance)) total += res?.data?.notificationDot?.PrepaidMinimumBalance;
        } else {
          if(Number(res?.data?.notificationDot?.Bill)) total += res?.data?.notificationDot?.Bill;
          if(Number(res?.data?.notificationDot['AutoPay-PaymentSuccess'])) total += res?.data?.notificationDot['AutoPay-PaymentSuccess'];
          if(Number(res?.data?.notificationDot['AutoPay-PaymentAdditionalCharge'])) total += res?.data?.notificationDot['AutoPay-PaymentAdditionalCharge'];
          if(Number(res?.data?.notificationDot['AutoPay-PaymentTechError'])) total += res?.data?.notificationDot['AutoPay-PaymentTechError'];
          if(Number(res?.data?.notificationDot['AutoPay-PaymentCancel'])) total += res?.data?.notificationDot['AutoPay-PaymentCancel'];
        }
        if(Number(res?.data?.notificationDot?.Meter)) total += res?.data?.notificationDot?.Meter;
        if(Number(res?.data?.notificationDot?.Announcements)) total += res?.data?.notificationDot?.Announcements;
        setTotalUnread(total)
      })
  }

  const handleToggle = () => {
    if(unreadData.length>0) {
      setOpen(true);
    } else {
      history.push(routePaths.ALERTS_AND_NOTIFICATION);
    }
  };

  const handleCloseAway = () => {
    setOpen(false);
  };
  const viewNow = () => {
    history.push(routePaths.ALERTS_AND_NOTIFICATION);
    setOpen(false);
  };
  const alertClickHandler = (alertData) => {
    history.push({
      pathname: routePaths.ALERTS_AND_NOTIFICATION,
      state: { data: alertData }
    });
    handleCloseAway();
    makeMeterAlertsCall();
  }

  const readMoreClickHandler = () => {
    history.push(routePaths.ALERTS_AND_NOTIFICATION);
    handleCloseAway();
  }

  useEffect(()=>{
    if(updateDotApiCount.justReadNow===true){
      let updatedCount= Number(totalUnread)-1
      setTotalUnread(updatedCount>=0?updatedCount:0);
    }
  },[updateDotApiCount])

  return (
    <>
      <IconButton
        onClick={viewNow}
        className={`${classes.notificationButton} ${!isMobile && classes.paddingButton} ${open && classes.blueBox} ${open && classes.hoverButton}`}
        ref={anchorRef}
      >
        <CustomBadge
          overlap="circle"
          badgeContent={totalUnread}
        >
          <NotificationsNoneOutlinedIcon className={!open && classes.whiteIcon} />
        </CustomBadge>
      </IconButton>
      {/* <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className={classes.styleIndex}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <List
                  className={classes.alertsList}
                >
                  {
                    unreadData.map((data, index) => {
                      return <span key={data?.AlertId}>
                      <ListItemText
                        className={classes.listItemStyle}
                        primary={
                          <Typography variant="caption">
                            {moment(data?.InsertedDateTime).format(dateFormat)} - 
                            {data?.Description}
                          </Typography>
                        }
                        onClick={() => alertClickHandler(data)}                  
                      />
                      {!(unreadData.length-1 === index) && <Divider variant="middle" component="li" />}
                      </span>
                    })
                  }
                  {totalUnread > 3 ?
                    <Typography onClick={readMoreClickHandler} variant="caption" className={classes.readMore}>
                      {labels?.E_NOTIFICATIONS_READ_MORE}
                    </Typography>
                    : null
                  }
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </>
  );
}
