import { apolloClientService } from "../../../../../services/apollo-client";

export function getContentData(languageCode, categoryCode) {
    if (localStorage.getItem("bearer")) {
      const GET_CONTENT=`{getContents(input:{languageCode:"${languageCode}",categoryCode:"${categoryCode}"}) }`;
      return apolloClientService.ApolloClientgqls(GET_CONTENT);
    
    }
  }

  export function getAccountDetails(accountId,languageCode) {
    let APIquery =
      `  query{getAccountDetails(input:{accountId:"`+accountId+`"})}`;
    return apolloClientService.ApolloClientgqlsAuth(APIquery);
  }

  export function getUserAccountDetails(accountId,languageCode) {
    let APIquery =
      `  query{getUserAccountDetails(input:{accountId:"`+accountId+`"})}`;
    return apolloClientService.ApolloClientgqlsAuth(APIquery);
  }