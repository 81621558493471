import {ApolloClient,gql, ApolloLink, InMemoryCache,HttpLink ,concat, from  } from '@apollo/client';
import{persistCache} from 'apollo-cache-persist'
import { config } from "../enviroment";
import { refreshBearerService } from "../services/refresh.bearer.service";
import { isTokenRefreshing, setIsTokenRefreshing } from '../components/app/axios-setup';
import { onError } from "@apollo/client/link/error";
export const apolloClientService = {
  ApolloClientgqls,
  ApolloClientgqlsUsage,
  ApolloClientgqlsAuth,
  ApolloClientgqlsCommunication
};
// let bearer = localStorage.getItem("bearer");
// if(bearer) {
//   bearer = JSON.parse(bearer);
// }

// let  accessToken;
// let userName;
// let personId;

const getPersonId = (bearer) => {
  if(bearer?.personId) {
    return bearer?.personId
  }
  if(bearer.accountDetails && bearer.accountDetails.indentifiers.personId) {
    return bearer.accountDetails.indentifiers.personId;
  }
  return "";
}

let cache=new InMemoryCache();
const init = async () => {
  await persistCache({
    cache,
    storage: window.localStorage,
  });
  }
  const authMiddleware = new ApolloLink(async (operation, forward) => {
    let bearer = localStorage.getItem("bearer");
    bearer = JSON.parse(bearer);
    let accessToken = bearer.acessToken;
    let dataExpiration = getExpirationDate(accessToken);
    let timeCheck = isExpired(dataExpiration);
    let personId = getPersonId(bearer)
    let userName = bearer.userName
    if (timeCheck) {
      if(!isTokenRefreshing) { 
        let promise = isTokenRefreshing ? isTokenRefreshing : refreshBearerService.refreshBearerToken();
        setIsTokenRefreshing(promise);
      }
      await isTokenRefreshing;
    }
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          tenantCode:config.constants.BASE_TENANT_CODE,
          userName:userName,
          personId: headers.personId ? headers?.personId : personId, 
          accesstoken: bearer.acessToken || null,
          isAgent:bearer.isAgent
        }
      }));
    return forward(operation);
  })
  let errorLink=  onError(({networkError})=>{
    if(networkError?.statusCode === 403){
      refreshBearerService.refreshBearerToken()
    }
    // if(networkError?.statusCode === 500){
    //   history.push(routePaths.RETRY);
    // }
  })
  const endPoints = config.urls;
  let httplink=new HttpLink({
  uri: `${endPoints.ADMIN_SERVICE_BASE_URL}`,
});
let  httplinkUsage=new HttpLink({
  uri:`${endPoints.USAGE_SERVICE_BASE_URL}`,
  headers:{}
});
let httplinkAuth=new HttpLink({
  uri:`${endPoints.OAUTH_SERVICE_BASE_URL}`,
  headers:{}
});
let  httplinkCommunication=new HttpLink({
  uri:`${endPoints.COMMUNICATION_SERVICE_BASE_URL}`,
});
const defaultOptions= {
  watchQuery: {
  fetchPolicy: 'cache-only',
  },
  query: {
    fetchPolicy: 'cache-only',
    errorPolicy: 'ignore'
  },
};
init();
const client = new ApolloClient({
  link: from([authMiddleware, errorLink, httplink]),
  cache,
  queryDeduplication: false,
  defaultOptions: defaultOptions  
});

const clientUsage = new ApolloClient({
  link: from([authMiddleware, errorLink, httplinkUsage]),
  cache,
  queryDeduplication: false,
  defaultOptions: defaultOptions
});
const clientAuth = new ApolloClient({
  link: from([authMiddleware, errorLink, httplinkAuth]),
  cache,
  queryDeduplication: false,
  defaultOptions: defaultOptions
});
const clientCommunication = new ApolloClient({
  link: from([authMiddleware, errorLink, httplinkCommunication]),
  cache,
  queryDeduplication: false,
  defaultOptions: defaultOptions
});

function ApolloClientgqls(reguestQuery) {
    return new Promise((resolve, reject) =>{
      client.query({ query:gql`${reguestQuery}`
        ,fetchPolicy: 'cache-first'
      })
      .then((res) => {
            resolve(res);
        }).catch(function(error) {
             reject(error);
        }); 
    });
}

function ApolloClientgqlsUsage(reguestQuery,headers,network,networkOptions) {
  let fetchPolicy='cache-first';
  headers['saId']=headers['saId']?headers['saId']:httplinkUsage.options.headers['saId']
  if(httplinkUsage.options.headers['saId']!==headers['saId'] || httplinkUsage.options.headers['accountId']!==headers['accountId']){
      httplinkUsage.options.headers['saId']=headers['saId'];
      httplinkUsage.options.headers['accountId']=headers['accountId'];
      fetchPolicy='network-only'
    }
    if(network){
      fetchPolicy=network
    }
    let queryOptions = { 
      query: gql`${reguestQuery}`,
      fetchPolicy: fetchPolicy,
      context:{
        headers: headers,
      }
    }
    if(networkOptions && networkOptions.signal) {
      queryOptions.context.fetchOptions = {signal: networkOptions.signal}
    }
    return new Promise((resolve, reject) =>{
      clientUsage.query(queryOptions)
      .then((res) => {
            resolve(res)
        }).catch(function(error) {
             reject(error);
        }); 
    })
}

function ApolloClientgqlsAuth(reguestQuery,headers,fetchPolicy) {
  if(fetchPolicy!="network-only" )
      fetchPolicy='cache-first';
  if(headers){
    httplinkAuth.options.headers=Object.assign(httplinkUsage.options.headers,headers)
  }
    return new Promise((resolve, reject) =>{
      clientAuth.query({ query:gql`${reguestQuery}`
        ,fetchPolicy: fetchPolicy,context:{headers: headers
        }
      })
      .then((res) => {
            resolve(res)
        }).catch(function(error) {
             reject(error);
        }); 
    })
}
function ApolloClientgqlsCommunication(reguestQuery,headers,fetchPolicy) {
    if(fetchPolicy!="network-only" )
      fetchPolicy='cache-first';
      if(headers){
        httplinkCommunication.options.headers=Object.assign(httplinkUsage.options.headers,headers)
      }
    return new Promise((resolve, reject) =>{
      clientCommunication.query({ query:gql`${reguestQuery}`
        ,fetchPolicy: fetchPolicy,context:{headers: headers
        }
      })
      .then((res) => {
            resolve(res)
        }).catch(function(error) {
             reject(error);
        }); 
    })
}
const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }
  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};
const isExpired = (exp) => {
  if (!exp) {
    return false;
  }
  return Date.now() > exp;
};
