//@flow

import { config } from "../enviroment";
import axios from "axios";
import { commonService } from "../services/common.service";

export const refreshBearerService = {
  refreshBearerToken,
};

async function refreshBearerToken() {
  const bearer = JSON.parse(localStorage.getItem("bearer"));

  if (bearer) {
    await axios
      .post(
        config.urls.REFRESH_BEARER,
        {
          grantType: "refresh_token",
          refreshToken: bearer.refreshToken,
          isAgent:bearer.isAgent ? bearer.isAgent  : false
        },
        {
          headers: {
            oldAccessToken: bearer.acessToken,
          },
        }
      )
      .then(function(response) {
        bearer.acessToken = response.data.access_token;
        bearer.refreshToken = response.data.refresh_token;
        localStorage.setItem(
          "bearer",
          commonService.encodeString(JSON.stringify(bearer))
        );

      })
      .catch(function(err) {
        const status = err.status || (err.response ? err.response.status : 0);
        if (status === 401 || status === 400) {
          commonService.logoutUser();
        }
      });
  } else {
    commonService.logoutUser();
  }
}
