import React, { useState, useEffect } from "react";
import { Grid, Hidden } from "@material-ui/core";
import E_AccountDetails from "../e_accountDetails/e_accountDetails";
import E_ClientImage from "./_clientImage";
import E_ProfileDropdown from "../e_profile_dropdown/e_profileDropdown";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import E_NotificationIcon from "../e_notification/e_notification";
import { makeStyles } from "@material-ui/core/styles";
import EaccountSaSwitcherMd from "../e_account_sa_switcher_md/eAccountSaSwitcherMd";

const useStyle = makeStyles((theme) => ({
  toolbarStyling: {
    "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
      padding: "0px !important",
    },
  },
}));

export default function E_RightToolbar() {
  const classes = useStyle();
  const isTabletOrMobile = useMediaQuery({ minWidth: 520 });
  const isMobile = useMediaQuery({ maxWidth: 328 });
  let widgets = useSelector((state) => state?.parametersWidgets?.WidgetLookUp);
  let [profileDropdown, setProfileDropdown] = useState(false);
  let [profileData, setProfileData] = useState();
  let bearer = JSON.parse(localStorage.getItem("bearer"));

  useEffect(() => {
    widgets &&
      widgets.map((item) => {
        if (bearer.isAgent ? item.widgetCode === "E_HEADER_AGENT.PROFILE_DROPDOWN" : item.widgetCode === "E_HEADER.PROFILE_DROPDOWN") {
          setProfileDropdown(true);
          setProfileData(item.children);
        }
      });
  }, [widgets]);

  return (
    <Grid container spacing={1} justify="center" alignItems="center">
      <Grid item className={isMobile && classes.toolbarStyling}>
        { bearer?.isAgent == true?<></> :<E_AccountDetails />}
      </Grid>
      <Grid item className={isMobile && classes.toolbarStyling}>
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item className={isMobile && classes.toolbarStyling}>
            { bearer?.isAgent == true?<></>:<E_NotificationIcon />}
          </Grid>
          <Hidden lgUp>
            <Grid item>
            { bearer?.isAgent == true?<></>:<EaccountSaSwitcherMd />}
            </Grid>
          </Hidden>
          <Grid item className={isMobile && classes.toolbarStyling}>
            {profileDropdown && <E_ProfileDropdown profileData={profileData} />}
          </Grid>
        </Grid>
      </Grid>
      <Hidden xsDown>
        <Grid item>
          <E_ClientImage />
        </Grid>
      </Hidden>
    </Grid>
  );
}
