import React, { useState, useEffect } from "react";
import { routePaths } from "../../../config/route-paths";
import ImpresaCX from "../assets/ImpresaCX_logo.png";
import Logo_W from "../assets/Logo_w.svg";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getAssetUrl } from "../../../e_home_component/e_account_summary/model/service";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Clientlogo from "../../../../assests/images/e_impresa-cx_white_logo.png"

const useStyle = makeStyles((theme) => ({
  tabletImageSize:{
    [theme.breakpoints.only('sm')]: {
      width:"auto",
      height:"40px"
    }
  }
}));

export default function E_ImpresaImage({ white }) {
  let classes = useStyle();
  const {t:labels, ready: labelReady} = useTranslation("label-E_HEADER");
  let [assetInfo, setAssetInfo] = useState();
  let logoWhiteImageAlt = null;
  let logoImageAlt = null
  let isTabletOrMobile = useMediaQuery({ minWidth: 961 });
  let isMobile = useMediaQuery({ minWidth: 300 });
  let logoImage = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.E_CLIENT_LOGO
  );
  let logoWhiteImage = useSelector(
    (store) =>
      store?.parametersWidgets?.ParameterLookup?.E_SIDEBAR_IMPRESA_CX_WHITE_LOGO
  );

  useEffect(() => {
    if (white && logoWhiteImage) {
      getAssetUrl(logoWhiteImage).then((res) => setAssetInfo(res) && (logoWhiteImageAlt = labels(res.fallBackLabelKey)))
      .catch(err => {
        setAssetInfo(null)
      });
    } else if (logoImage) {
      getAssetUrl(logoImage).then((res) => setAssetInfo(res) && (logoImageAlt = labels(res.fallBackLabelKey)))
      .catch(err => {
        setAssetInfo(null)
      });
    }
  }, [logoImage, logoWhiteImage, white, isTabletOrMobile]);

  return (
    <Link to={routePaths.HOME}>
      <img
        src={
          assetInfo?.assetPath
            ? assetInfo.assetPath
            : white
            ? Clientlogo
            : Logo_W
        }
        alt={assetInfo && labelReady ? labels([assetInfo.fallBackLabelKey]) : ''}
        className={classes.tabletImageSize}
        width={isTabletOrMobile ? "150vw" : isMobile ? "110vw" : "80vw"}
      />
    </Link>
  );
}
