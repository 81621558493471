import { appConstants } from "../constants";

const initialState = {};
export function meterDetails(state = initialState, action) {
  switch (action.type) {
    case appConstants.UPDATE_OR_ADD_METER_DETAILS: {
      return {
        ...state,
        ...action.snack
      };
    }
    default:
      return state;
  }
}

