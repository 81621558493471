import React, { useState, useEffect } from "react";
import { getContentData } from "./model/e_service";
import { useSelector } from "react-redux";
import { Link, Grid, makeStyles, Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
  termsLinks: {
    color: theme.palette.ePrimary?.base,
  },
  footerStyle:{
    position:"absolute",
    bottom:"0.4rem",
    [theme.breakpoints.up("sm")]: {
      left:"4.3rem",
    },
     [theme.breakpoints.down("sm")]: {
      left:"0.5rem !important"
     },
  }
}));

export default function E_Footer() {
  let classes = useStyle();
  let isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  let [content, setContent] = useState();
  let [copyright, setCopyright] = useState();
  let [terms, setTerms] = useState();
  let languageCode = useSelector(
    (store) =>
      store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  const {t:contents, ready: contentReady}=useTranslation("content-E_FOOTER")
  

  // useEffect(() => {
  //   if(languageCode){
      
  //   }
  //   languageCode &&
  //     getContentData(languageCode, "E_FOOTER")
  //       .then((res) => setContent(res?.data?.getContents?.E_FOOTER))
  //       .catch((err) => {
  //         setContent(null);
  //       });
  // }, [languageCode]);

  useEffect(() => {
    content &&
      content.map((item) => {
        item.contentCode === "E_FOOTER_COPYRIGHT" && setCopyright(item.answer);
        item.contentCode === "E_FOOTER_TERMS_POLICIES" &&
          setTerms([item.question, item.answer]);
      });
    if(contentReady){
      setCopyright(contents('E_FOOTER_COPYRIGHT')?.answer)
      setTerms([contents('E_FOOTER_TERMS_POLICIES')?.question, contents('E_FOOTER_TERMS_POLICIES')?.answer]);
    }
  }, [contentReady]);
  return (
    <div className={classes.footerStyle} >
    <Typography
      variant="caption"
      align="left"
    >
      {copyright}
      {isTabletOrMobile ? <br /> : <>&emsp;</>}
      {terms && (
        <a
          className={classes.termsLinks}
          href={terms?.[1]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {terms?.[0]}
        </a>
      )}
    </Typography>
    </div>
  );
}
