import { adminService } from './../services/admin.service'
import { appConstants } from "../constants/app.constants";
import { store } from "../helpers/store"
import { batch } from 'react-redux';
import { getUserPreferences } from '../services/user-preferences.service';

export {
    // accountDetails,
    userDetails,
    updateMeterDetails,
    updateAccountDetails,
    accountsDetails,
    userPreferences
}
function userDetails() {
    return dispatch => {
        // dispatch(accountDetails())
        dispatch(accountsDetails())
        let bearer = JSON.parse(localStorage.getItem("bearer"));
        if(bearer.isAgent){
            if(store.getState().accountInformation?.personDetailList){
                let userdetails={};
                store.getState().accountInformation.personDetailList["C1-Person"].personContactDetail.forEach((item) => {
                    if (item.personContactType == "CELLPHONE") {
                        userdetails['phoneNumber'] = item.contactDetailValue;
                    }
                    if (item.personContactType == "PRIMARYEMAIL") {
                        userdetails['email']  = item.contactDetailValue;
                    }
                    if (item.personContactType == "PRIMARYEMAIL") {
                        userdetails['userName']  = item.contactDetailValue;
                    }
                });
                userdetails['firstName'] = Array.isArray(store.getState().accountInformation.personDetailList["C1-Person"].personName)?
                store.getState().accountInformation.personDetailList["C1-Person"].personName[0].entityName:
                store.getState().accountInformation.personDetailList["C1-Person"].personName.entityName;
                userdetails['lastName'] = "";
                dispatch(dispatchFormat(appConstants.PROFILE_DETAILS_SUCESS, userdetails))
            }
        }else{
            adminService.profileDetails().then(profileResponse => {
                dispatch(dispatchFormat(appConstants.PROFILE_DETAILS_SUCESS, profileResponse.data))
            }).catch(profileError => {
                dispatch(dispatchFormat(appConstants.PROFILE_DETAILS_ERROR, {
                    email: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    address: '',
                    userName: ''
                }))
            })
        }
    }
}
function userPreferences() {
    let bearer = localStorage.getItem("bearer");
    let email = JSON.parse(bearer)?.email;
    return dispatch => {
        getUserPreferences().then(preferencesResponse => {
            dispatch(dispatchFormat(appConstants.GET_USER_PREFERENCES_SUCCESS, preferencesResponse))
        }).catch(error=> {
            dispatch(dispatchFormat(appConstants.GET_USER_PREFERENCES_ERROR, {
                getUserPreferences: 'Error'
            }))
        })
    }
}

// function accountDetails() {   

//     return dispatch => {
//             store.getState().snackbar = true; 
//         authService.accountDetais().then(accountInformation => { 
//             const getFirstMeter = (accountInformation.accountSummary.accountDetails) ? accountInformation.accountSummary.accountDetails.find(singleAcc => !singleAcc.isDisabled) : {};
//             dispatch(updateMeterDetails(getFirstMeter));
//             dispatch(dispatchFormat(appConstants.ACCOUNT_DETAILS_SUCESS, accountInformation.accountSummary))
//             store.getState().snackbar = false; 
//         }).catch(accountInfoError => {
//             store.getState().snackbar = true;
//             dispatch(dispatchFormat(appConstants.ACCOUNT_DETAILS_ERROR, {
//                 accountName: '',
//                 accountType: '',
//                 accountId: '',
//                 billingAddress: '',
//                 externalMeters: []
//             }))
//             store.getState().snackbar = false;
//         })
//    }
// }

function accountsDetails() {

    return (dispatch, getState) => {
        getState().snack = true;
        let accountInfo = {};
        let bearer = JSON.parse(localStorage.getItem("bearer"));
        if (bearer.accountDetails) {
            accountInfo = bearer.accountDetails.accountSummary;
        }
        let data = accountInfo?.personAcccountDetail?.personAccountList
        let getFirstMeter = accountInfo ? accountInfo?.personAcccountDetail?.personAccountList.find(singleAcc => !singleAcc.isDisabled) : {};
        let accountCloser = localStorage.getItem("accountSelected");
        batch(() => {
            getState().snack = true;
            dispatch(dispatchFormat(appConstants.ACCOUNT_DETAILS_SUCESS, accountInfo));
            if (accountCloser && !isNaN(accountCloser)) {
                data.forEach((single, k) => {
                    if (Number(single?.accountId) === Number(accountCloser)) {
                        dispatch(updateMeterDetails(single, single.accountSaList[0]));
                    }
                });
            } else {
                dispatch(updateMeterDetails(getFirstMeter, getFirstMeter?.accountSaList[0]));
                localStorage.setItem("accountSelected", getFirstMeter?.customerNumber);
            }
            getState().snackbar= false;
        })
    }
}

function updateMeterDetails(meterInformation, saId) {
    
    const sampleObj = { getSaInfo: saId };
    meterInformation = { ...meterInformation, ...sampleObj }
    return dispatch => {
        store.getState().snackbar = true;
        dispatch(dispatchFormat(appConstants.UPDATE_OR_ADD_METER_DETAILS, meterInformation))
        store.getState().snackbar = false;
    }
}
function updateAccountDetails(accountDetails) {
    return dispatch => {
        store.getState().snackbar = true;
        dispatch(dispatchFormat(appConstants.UPDATE_ACCOUNT_DETAILS, accountDetails))
        store.getState().snackbar = false;
    }
}
function dispatchFormat(actionType, snack) {
    return { type: actionType, snack };
}