import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { store } from "../../../../helpers/store";
import { CardContent, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { routePaths } from "../../../config/route-paths";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link, useHistory } from "react-router-dom";
import Backlink from "../../../common/e_backLink/e_back_link";
import { useTranslationCustom } from "../../../../i18nOverrides";


export const useStyle = makeStyles((theme) =>
  createStyles({
    headerColor: {
      color: theme.palette.ePrimary?.base,
    },
    link: {
      justify: 'left',
      color: theme.palette.ePrimary?.base,
      marginLeft: 25,
      textDecoration: "underline"
    },
    primaryColor: {
      "& a": {
        color: theme.palette.ePrimary?.base,
        textDecoration: "underline",
        "&:hover": {
          color: theme.palette.ePrimary?.hover,
        }
      }
    },
    blueText: {
      color: theme.palette.ePrimary?.base,
      fontWeight: "bold",
      "&:hover": {
        color: theme.palette.ePrimary?.hover,
      },
    },
    underlineText: {
      textDecoration: "underline"
    },
  })
);
export default function PaymentHeader({isMultiAccount}) {
  const [postpaidSubtitle, setPostPaidSubTitle] = useState("");
  const [prepaidSubtitle, setPrePaidSubTitle] = useState("");
  const [faqLinkLabel, setFaqLinkLabel] = useState("");
  const customStyle = useStyle();
  const languageCode = useSelector((state) => state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE);
  let tenantName = useSelector((state) => state?.parametersWidgets?.ParameterLookup?.TENANT_NAME);
  const { t: labels, ready: labelReady } = useTranslationCustom("label-E_MAKE_PAYMENT");
  const { t: contentPostpaid, ready: contentPostpaidReady } = useTranslationCustom("content-E_MAKE_PAYMENTS")
  const { t: contentPrepaid, ready: contentPrepaidReady } = useTranslationCustom("content-E_PREPAID_PAYMENTS")
  useEffect(() => {
    if (contentPostpaidReady) {
      if(isMultiAccount) {
        setPostPaidSubTitle(contentPostpaid("E_MAKE_PAYMENTS_SUBTITLE")?.answer?.replace("****", routePaths.SELF_HELP)?.replace(new RegExp("<TENANT_NAME>", 'g'), tenantName))
        setFaqLinkLabel(contentPostpaid("E_PAYMENT_CONTENT")?.answer?.split("***")[1])
      } else {
        setPostPaidSubTitle(contentPostpaid("E_MAKE_PAYMENTS_SUBTITLE_SINGLE_ACCOUNT")?.answer?.replace("****", routePaths.SELF_HELP)?.replace(new RegExp("<TENANT_NAME>", 'g'), tenantName))
        setFaqLinkLabel(contentPostpaid("E_PAYMENT_CONTENT")?.answer?.split("***")[1])
      }
    }
    if (contentPrepaidReady) {
      if(isMultiAccount) {
        setPrePaidSubTitle(contentPrepaid("E_PREPAID_PAYMENTS_SUBTITLE")?.answer?.split("***")[0])
        setFaqLinkLabel(contentPrepaid("E_PREPAID_PAYMENTS_SUBTITLE")?.answer?.split("***")[1])
      } else {
        setPrePaidSubTitle(contentPrepaid("E_PREPAID_PAYMENTS_SUBTITLE_SINGLE_ACCOUNT")?.answer?.split("***")[0])
        setFaqLinkLabel(contentPrepaid("E_PREPAID_PAYMENTS_SUBTITLE_SINGLE_ACCOUNT")?.answer?.split("***")[1])
      }
    }
  }, [contentPostpaidReady, contentPostpaidReady, contentPostpaid, contentPrepaid, tenantName, isMultiAccount]);

  let { t: rechargeAccountLabels, ready: rechargeAccountLabelsReady } = useTranslationCustom("label-E_PREPAID_PAYMENT");
  const isPrepaid = useSelector((store) => store?.meterDetails?.getSaInfo?.isPrepaidSa);

  const history = useHistory();
  const backCall = (props) => {
    if (props.backToHome) {
      history.push(routePaths.HOME)
    } else {
      history.length >= 1 ? history.goBack() : history.push(routePaths.HOME);
    }

  };

  return (
    <CardContent>
      <React.Fragment>
        <Grid
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Backlink />
          </Grid>
          <Grid item>
            {isPrepaid && isPrepaid === "Y" ? (
              <Typography className={customStyle.headerColor} align="left" variant="h5">
                <b>
                  {rechargeAccountLabels("E_PREPAID_PAYMENT_TITLE")}
                </b>
              </Typography>
            ) : (
              <Typography className={customStyle.headerColor} align="left" variant="h5">
                <b>
                  {labels("E_MAKE_PAYMENT_TITLE")}
                </b>
              </Typography>
            )}
          </Grid>
        </Grid>
      </React.Fragment>

      {isPrepaid && isPrepaid === "N" ? (
        <>
          <Typography
            variant="subtitle2"
            align="left"
            className={customStyle.primaryColor}
            dangerouslySetInnerHTML={{
              __html: postpaidSubtitle,
            }}
          >
          </Typography></>
      ) : (
        <>
          <Typography
            variant="subtitle2"
            align="left"
            className={customStyle.primaryColor}
          >
            {prepaidSubtitle} <Link to={routePaths.SELF_HELP} className={customStyle.linkColor}>{faqLinkLabel}</Link>
          </Typography> </>
      )}
    </CardContent>
  )
}