import { meterAlertsConstant } from "./meter-alerts-constants";

const initialState = [];
export function meterAlertsAllData(state = initialState, action) {
  switch (action.type) {
    case meterAlertsConstant.METER_ALERTS_START:
      return { ...state,loading:true};
    case meterAlertsConstant.METER_ALERTS_SUCCESS:
      return { ...state, data: action.data ,loading:false};
    case meterAlertsConstant.METER_ALERTS_FAILURE:
        return {
          ...state,
          ...action.data,
          loading:false
      };
    default:
      return state;
  }
}

export function meterAlertsSearchInput(state = "", action) {
  switch (action.type) {
    case meterAlertsConstant.METER_ALERTS_SEARCH_START:
      return { ...state, word: action.word };

    default:
      return state;
  }
}

const initialData = {};
initialData.offset = 0;
initialData.page = 1;
export function meterAlertsPageInput(state = initialData, action) {
  switch (action.type) {
    case meterAlertsConstant.METER_ALERTS_PAGE_START:
      return { ...state, offset: action.offset, page: action.page };

    default:
      return state;
  }
}

const initialWithoutPaginationData = [];
initialWithoutPaginationData.tabelData = [];

export function meterAlertsWithoutPagination(
  state = initialWithoutPaginationData,
  action
) {
  switch (action.type) {
    case meterAlertsConstant.METER_ALERTS_WITHOUT_PAGINATION_START:
      return { ...state, tabelData: action.tableData };

    default:
      return state;
  }
}

const initialTableData = [];
initialTableData.tabelData = [];
export function meterAlertsTableInput(state = initialTableData, action) {
  switch (action.type) {
    case meterAlertsConstant.METER_ALERTS_TABLE_START:
      return { ...state, tabelData: action.tableData, count: action.count };

    default:
      return state;
  }
}
