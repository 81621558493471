import React, { useEffect, useState } from "react";
import { Grid, List, ListItem, IconButton } from "@material-ui/core";
import facebook from "../../assets/facebook.svg";
import googlePlus from "../../assets/google-plus.svg";
import linkedIn from "../../assets/linkedin.svg";
import twitter from "../../assets/twitter.svg";
import { withStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { getContentData } from "../model/e_service";
import { makeStyles } from "@material-ui/core/styles";
import { getAssetUrl } from "../../../../e_home_component/e_account_summary/model/service";
import { useTranslationCustom } from "../../../../../i18nOverrides";

export const StyleIconButton = withStyles({
  root: {
    padding: "65% 0% !important",
  },
})(IconButton);

const useStyle = makeStyles((theme) => ({
  columnLayout: {
    padding: "0.5em"
  },
  rowLayout: {
     gap:'1.5rem'
  }
}));

export default function E_SocialLinks({ socialData }) {
  let classes = useStyle();
  const isTabletOrMobile = useMediaQuery({ minWidth: 961 });
  let [presentComponents, setPresentComponent] = useState("");
  let languageCode = useSelector(
    (store) =>
      store?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  let [content, setContent] = useState();

  const {t:contents}=useTranslationCustom("content-E_SIDEBAR")
  const {i18n}=useTranslationCustom()
  let sidebarArray=[]
  let i18nObject=i18n.store.getResourceBundle('en', 'content-E_SIDEBAR')
  useEffect(() => {
    if (i18nObject && Object.values(i18nObject).length) {
      Object.keys(i18nObject).forEach(key => {
        sidebarArray.push(i18nObject[key]);
      })
      setContent(sidebarArray)
    }
  }, [i18nObject]);

  // useEffect(() => {
  //   languageCode && getContentData(languageCode, "E_SIDEBAR").then((res) =>
  //     setContent(res?.data?.getContents?.E_SIDEBAR)
  //   )
  //   .catch(err => {
  //     setContent(null)
  //   });
  // }, [languageCode]);
  let ParameterLookup = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup
  );
  let labels = useSelector(
    (state) => state?.labels?.data?.getLabels?.E_SIDEBAR
  );

  let [components, setComponents] = useState("");

  useEffect(() => {
    if (ParameterLookup && labels) {
      setComponents([
        {
          icon: <img src={linkedIn} width="90%" alt={labels?.E_SIDEBAR_LINKEDIN_IMAGE_FALLBACK}/>,
          widgetCode: "E_SIDEBAR.LINKEDIN",
          contentCode: "E_SIDEBAR_LINKEDIN",
          parameterCode: ParameterLookup?.E_SIDEBAR_LINKEDIN,
        },
        {
          icon: <img src={twitter} width="90%" alt={labels?.E_SIDEBAR_TWITTER_IMAGE_FALLBACK}/>,
          widgetCode: "E_SIDEBAR.TWITTER",
          contentCode: "E_SIDEBAR_TWITTER",
          parameterCode: ParameterLookup?.E_SIDEBAR_TWITTER,
        },
        {
          icon: <img src={facebook} width="90%" alt={labels?.E_SIDEBAR_FACEBOOK_IMAGE_FALLBACK}/>,
          widgetCode: "E_SIDEBAR.FACEBOOK",
          contentCode: "E_SIDEBAR_FACEBOOK",
          parameterCode: ParameterLookup?.E_SIDEBAR_FACEBOOK,
        },
      ]);
    }
  }, [ParameterLookup,labels]);
  useEffect(() => {
    if (socialData && components && presentComponents.length === 0) {
      let widget = [];
      socialData.map((element) => {
        components.map((item, k) => {
          if (element.widgetCode === item.widgetCode) {
            if (element.widgetDefaultValue === "true") {
              item.widgetRank = element.widgetRank;
              widget.push(item);
            }
          }
        });
      });
      setPresentComponent(widget);
    }
  }, [socialData, presentComponents]);

  useEffect(() => {
    if (presentComponents && presentComponents.length !== 0) {
      presentComponents.sort((a, b) => {
        return a.widgetRank - b.widgetRank;
      });
    }
  }, [presentComponents]);

  useEffect(() => {
    if (content && components) {
      let widget = [];
      content.map((element) => {
        components.map((item, k) => {
          if (element.contentCode === item.contentCode) {
            item.url = element.answer;
            widget.push(item);
          }
        });
      });
      setPresentComponent(widget);
    }
  }, [content,components]);

  useEffect(() => {
    let widget = [];
    components &&
      labels &&
      components.forEach((item) => {
        if (item?.parameterCode) {
          getAssetUrl(item?.parameterCode).then((res) => {
            item.assetPath = res?.assetPath;
            item.fallBackLabelKey = labels?.[res?.fallBackLabelKey];
            widget.push(item);
          })
          .catch(err => {
            widget =[]
          })
        }
      });
    setPresentComponent(widget);
  }, [components, labels]);

  return (
    <Grid
      container
      direction={isTabletOrMobile ? "column" : "row"}
      alignItems="center"
      className={isTabletOrMobile ? '' : classes.rowLayout }
    >
      {presentComponents &&
        presentComponents.length > 0 &&
        presentComponents.map((item, k) => (
          <Grid
            item
            id={item.title}
            key={k}
            onClick={() => window.open(item.url)}
            className={isTabletOrMobile ? '' : classes.columnLayout}
          >
            <StyleIconButton>
              {item?.assetPath ? <img src={item?.assetPath} width="100%" alt={item?.fallBackLabelKey}/> : item.icon}
            </StyleIconButton>
          </Grid>
        ))}
    </Grid>
  );
}