import React, { useState, useEffect } from "react";
import { Button, Grid, SwipeableDrawer, Typography, IconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import { commonService } from "../../services/common.service";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from "react-i18next";
import "./base-style.css";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    background: theme.palette.ePastelColor2.base,
    padding: "0px 10px 5px 10px",
  },
  hrLine: {
    borderTop: "3px solid rgba(0,0,0,.1)",
    margin:"2% auto"
  },
}));

export default function SwipeableInstallBanner() {
  const classes = useStyles();
  const [toggleBottom, setBottom] = useState(true);
  const [appLogoPath, setAppLogoPath] = useState("");
  const [bannerPath, setBannerpath] = useState("");
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  // const [contain, setcontain] = useState("");
  const {t:contents, ready: contentReady}=useTranslation("content-INSTALL_BANNER")
  let appLogo = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.E_IMPRESACX_LOGO
  );
  let banner = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.INSTALL_BANNER
  );
  let labels = useSelector((store) => store?.labels?.data?.getLabels?.HEADER);
  let label = useSelector(
    (store) => store?.labels?.data?.getLabels?.INSTALL_BANNER
  );
  useEffect(() => {
    if (appLogo) {
      commonService.getAssestUrl(appLogo).then((response) => {
        setAppLogoPath(response);
      });
    }
    if (banner) {
      commonService.getAssestUrl(banner).then((response) => {
        setBannerpath(response);
      });
    }
  }, [appLogo, banner,contentReady]);
  // let lang = useSelector(
  //   (state) =>
  //     state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  // );
  // useEffect(() => {
  //   document.body.style.overflow="auto";
  //   store.getState().snackbar = true;
  //   if(lang){
  //     adminService.getContentData(lang, "INSTALL_BANNER").then((res) => {
  //       setcontain(res?.data?.getContents?.INSTALL_BANNER?.[0]?.answer);
  //       store.getState().snackbar = false;
  //     });
  //   }
  // }, [lang]);
  // const clickaway=()=>{
  //   setBottom(false)
  // }
  return (
    // <ClickAwayListener onClickAway={clickaway}>
      <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="bottom"
      open={toggleBottom}
      swipeAreaWidth={5}
      id="mainBackground"
      style={{ position: "inherit" }}
    >
      <div className={classes.mainBackground}>
        <hr
          align="center"
          style={{ width: "50px" }}
          className={classes.hrLine}
        />
        <Grid alignContent="center" alignItems="center" container direction="row">
          <Grid item xs={6}>
            {appLogoPath && (
              <img
                height="auto"
                width="100%"
                className="app-logo"
                src={appLogoPath?.assetPath}
                alt={
                  appLogoPath
                    ? labels?.[appLogoPath?.fallBackLabelKey]
                    :''
                }
              />
            )}
          </Grid>
          <Grid item align="right" xs={4} >
            <Button id="INSTALL_PWA_APP" color="primary" variant="contained">
              {label?.INSTALL_BUTTON}
            </Button>
          </Grid>
          <Grid item align="right" xs={2}>
            <IconButton onClick={() => setBottom(false)}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr className={classes.hrLine} />
        <Grid container direction="row">
          <Grid item xs={9}>
            {contentReady && (
              <Typography
                dangerouslySetInnerHTML={{
                  __html:contents("INSTALL_BANNER_PARAGRAPH")?.answer,
                }}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            {bannerPath && (
              <img
                height="auto"
                width="100%"
                src={bannerPath?.assetPath}
                alt={
                  bannerPath
                    ? label?.[bannerPath?.fallBackLabelKey]
                    : label.INSTALL_BANNER_IMAGE_FALLBACK
                }
              />
            )}
          </Grid>
        </Grid>
      </div>
    </SwipeableDrawer>
    // </ClickAwayListener>
  );
}
