import { config } from "../enviroment";
import axios from "axios";
import {apolloClientService} from './apollo-client';

const endPoints = config.urls;
export const adminService = {
  profileDetails,
  getContentData,
};

async function profileDetails() {
  if (localStorage.getItem("bearer")) {
    return await axios.post(`${endPoints.ADMIN_BASE_URL}profile-details`);
  }
}

function getContentData(languageCode, categoryCode) {
  // apollo client request here
  if (localStorage.getItem("bearer")) {
    const GET_CONTENT=`{getContents(input:{languageCode:"${languageCode}",categoryCode:"${categoryCode}"}) }`;
    return apolloClientService.ApolloClientgqls(GET_CONTENT);
   
    // return new Promise((resolve, reject) => {
    //   axios
    //     .post(config.urls.ADMIN_SERVICE_BASE_URL, {
    //       query:
    //         `{getContents(input:{languageCode:"` +
    //         languageCode +
    //         `",categoryCode:"` +
    //         categoryCode +
    //         `"})}`,
    //     })
    //     .then(function(response) {
    //       resolve(response.data);
    //     })
    //     .catch(function(error) {
    //       reject(error);
    //     });
    // });
  }
}
