import {config} from './../../enviroment';
import { store } from "../../helpers/store";
 
const ChatWidget = () => {
    (function (w, d, s, u) {
            w.RocketChat = function (c) { w.RocketChat._.push(c) }; w.RocketChat._ = []; w.RocketChat.url = u;
            var h = d.getElementsByTagName(s)[0], j = d.createElement(s);
            j.async = true; j.src = `${config.urls.ROCKET_CHAT_WIDGET}rocketchat-livechat.min.js`;
            h.parentNode.insertBefore(j, h);
        })(window, document, 'script', config.urls.ROCKET_CHAT_WIDGET);
    // (function(w, d, s, u) {
    //     w.RocketChat = function(c) { w.RocketChat._.push(c) };
    //     w.RocketChat._ = [];
    //     w.RocketChat.url = u;
    //     var h = d.getElementsByTagName(s)[0],
    //           j = d.createElement(s);
    //     j.async = true;
    //     j.src = `${config.urls.ROCKET_CHAT_WIDGET}rocketchat-livechat.min.js`;
    //     h.parentNode.insertBefore(j, h);
    //    w.RocketChat(function() {
    //     let storeData = null;
    //     store.subscribe(()=>{
    //         storeData = store.getState();
    //         if(storeData.accountInformation.demographicDetails != null || storeData.accountInformation.demographicDetails != undefined){
    //             this.setCustomField('personId', storeData.accountInformation.demographicDetails.personId ? storeData.accountInformation.demographicDetails.personId : '')
    //             this.setGuestName(storeData.accountInformation.demographicDetails.primaryName ? storeData.accountInformation.demographicDetails.primaryName : '');
    //             let email = storeData.accountInformation.demographicDetails.personContactList.find(element => element.contactRouteType == 'PRIMARYEMAIL');
    //             this.setGuestEmail(email.contactValue ? email.contactValue : '');
    //         }
    //     });
        
    //     });
    //     })(window, document, 'script', config.urls.ROCKET_CHAT_WIDGET);
    // }
}
 
export default ChatWidget;