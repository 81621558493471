import axios from "axios";
import { config } from "../../../../../enviroment";
import { apolloClientService } from "../../../../../services/apollo-client";

export default function getNotificationAPI(accountId,customerClass) {
    let APIquery = `query{
      notificationDot(input:{
        isPublic:"LOGGED_IN",
        customerClass:"`+customerClass+`"})
    }`;
    return new Promise((resolve, reject) => {
      axios({
        url: config.urls.COMMUNICATION_SERVICE_BASE_URL,
        method: 'post',
        data: {
          query: APIquery
        },
        headers: {
          "accountid": accountId
        },
      })
        .then(function(response) {
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }

  export function meterAlertsCall(startDate, endDate, origin, accountId) {
    let APIinput =
      `{getAlerts(input:{origin:"` +
      origin +
      `",
          startDate:"` +
      startDate +
      `",
          endDate:"` +
      endDate +
      `"
          })}`;
  
    return apolloClientService.ApolloClientgqlsCommunication(APIinput, {accountId: accountId}, "network-only")
  }