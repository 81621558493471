const BASE_URL = "https://impresacx-prod.abjayon.com";
const OAUTH_BASE_URL = BASE_URL + "/auth/";
const ADMIN_BASE_URL = BASE_URL + "/admin/";
const COMMUNICATION_BASE_URL = BASE_URL + "/communication/";
const LOGIN_APP_URL = BASE_URL + "/login/";
const ASSEST_MANAGER_URL = BASE_URL + "/assets";
const ROCKET_CHAT_WIDGET = BASE_URL + "/assets/mitr/";
const USAGE_SERVICE_ENDPOINT = BASE_URL + "/usage/";
const BASE_TENANT_CODE = "ABJ";
const TENANT_MAP_URL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.1939778010556!2d78.37885331418846!3d17.450427405578676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93dce9d6cf77%3A0xe7f33917f8ba3f89!2sAbjayon%20Consulting!5e0!3m2!1sen!2sin!4v1581938464709!5m2!1sen!2sin";
const ADMIN_SERVICE_BASE_URL = BASE_URL + "/admin/admin-service";
const USAGE_SERVICE_BASE_URL = BASE_URL + "/usage/usage-service";
const COMMUNICATION_SERVICE_BASE_URL = BASE_URL + "/communication/communication-service";
const SW_VAP_PUBLIC_KEY = "PUSH_VAP_PUBLIC_KEY_VALUE";
const constants = {
  urls: {
    SEARCH:ADMIN_BASE_URL+"globalsearch",
    MAP_URL: TENANT_MAP_URL,
    ROCKET_CHAT_WIDGET,
    LOGIN: OAUTH_BASE_URL + "login",
    USAGE_ELECTRICITY_DATA: USAGE_SERVICE_ENDPOINT + "usage-comparison",
    LANGUAGE_LIST: "https://api.myjson.com/bins/1fj62q",
    LOGIN_APP: LOGIN_APP_URL ,
    LABELS: ADMIN_BASE_URL + "labels/" + BASE_TENANT_CODE,
    PARAMETER_WIDGET_CONTROL:
    ADMIN_BASE_URL + "parameter-controller/" + BASE_TENANT_CODE,
    ASSEST_CODE_API: ADMIN_BASE_URL + "assets/" + BASE_TENANT_CODE,
    PROFILE_DETAILS_DATA: ADMIN_BASE_URL + "profile-details",
    ASSEST_URL_ENDPOINT: ASSEST_MANAGER_URL,
    ADMIN_BASE_URL,
    USAGE_SERVICE_ENDPOINT,
    EXPIRE_SESSION: OAUTH_BASE_URL + "login/signout",
    REFRESH_BEARER: OAUTH_BASE_URL + "login/refresh",
    AUTH_SERVICE: OAUTH_BASE_URL + "auth-service",
    UPDATE_PASSWORD: OAUTH_BASE_URL + "login/update-password",
    ACCOUNT_SUMMARY_USAGE_GRAPH:
      USAGE_SERVICE_ENDPOINT + "usage-account-dashboard",
    ACCOUNT_SUMMARY_USAGE: USAGE_SERVICE_ENDPOINT + "account-summary-usage",
    ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL,
    USAGE_SERVICE_BASE_URL: USAGE_SERVICE_BASE_URL,
    OAUTH_SERVICE_BASE_URL : OAUTH_BASE_URL + "auth-service",
    LOGIN_RESENDOTP: OAUTH_BASE_URL + "otp/resend",
    VALIDATEOTP: OAUTH_BASE_URL + "otp/validate",
    COMMUNICATION_SERVICE_BASE_URL:COMMUNICATION_SERVICE_BASE_URL,
    COMMUNICATION_BASE_URL: COMMUNICATION_BASE_URL,
    PREPAID_ELASTC_SEARCH_DATA:BASE_URL + "/usage-producer/elastic-search",
    GENERATE_BEARER_ACCESS: OAUTH_BASE_URL + "login/generate-bearer-from-access",
  },
  constants: {
    BASE_TENANT_CODE: BASE_TENANT_CODE,
    BASE_URL:BASE_URL,
    I18_CACHE_EXPIRY_TIME: "300000",
    DEFAULT_SERVICE_TYPE: "Electric",
    REDIRECT_URL:"/nativeRedirect",
    NATIVEAPP_URL:"impressacx://",
    ECOMMERCE_FRONTEND_PORT: 8001
    // BASE_URL+"/"
  },
  NodeVariables: {
    NODE_ENV: 'production',
    PUBLIC_URL: BASE_URL,
  },
  snackBarConfig : {
    TIME_OUT: 3000,
    MAX_COUNT: 3
    },
  confidentialClient:{
    SW_VAP_PUBLIC_KEY
  },
  pagination: {
    perPage: 6
  },
  EnergyConsumption: {
    FALLBACK_MONTHLY_CHART_DATE_FORMAT: "MMM'YY"
  },
  ServiceTypeIdentifiers: {
  COOL_WATER: "CW",
  COOL_WATER_RESIDENTIAL: "CW-RES",
  GAS: "G",
  ELECTRIC: "E",
  WATER: "W",
  WASTE_WATER: "WW",
  COOLING_SERVICE: "CS"
  }
};
export const config = constants;