import React, { useState, useEffect } from "react";
import ClientLogo from "../assets/impress.png";
import { useSelector } from "react-redux";
import { getAssetUrl } from "../../../e_home_component/e_account_summary/model/service";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles({
  imgStyle: {
    margin: "5% 2%",
  },
});

export default function E_ClientImage() {
  const {t:labels, ready: labelReady} = useTranslation("label-E_HEADER");
  const [fallBackKey,setfallbackKey] = useState();
  let [assetInfo, setAssetInfo] = useState();
  let classes = useStyle();
  let clientLogoImage = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.E_IMPRESACX_LOGO
  );
  useEffect(() => {
    clientLogoImage &&
      getAssetUrl(clientLogoImage).then((res) => setAssetInfo(res) && (setfallbackKey(res.fallBackLabelKey)))
      .catch(err => {
        setAssetInfo(null)
      });
  }, [clientLogoImage]);
  return (
    <img
      src={assetInfo?.assetPath}
      alt={assetInfo && labels?.[assetInfo?.fallBackLabelKey]}
      width="120px"
      className={classes.imgStyle}
    />
  );
}
