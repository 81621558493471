import { notificationConstant } from "./notification.constant";

export function notificationDot(state = {}, action) {
  switch (action.type) {
    case notificationConstant.GET_NOTIFICATION_SUCCESS:
      return { ...state, data: action.data};
    case notificationConstant.GET_NOTIFICATION_FAILURE:
        return {
          ...state,
          ...action.data
      };
    default:
      return state;
  }
}