import { appConstants } from "../constants/app.constants";
import { commonService } from "../services/index";

export const appActions = {
  getParametersWidgets,
  getSnackbar,
  getAppLogo,
  getprofileIcon,
  getprofileDarkIcon
};
export{
  getAppReady_widget,
  browserDetect
}
function getAppReady_widget(){
  return dispatch => {
    // dispatch(appActions.getParametersWidgets())
  }
}

function browserDetect() {
  return dispatch => {
    let userAgent = navigator.userAgent;
  let browserName;
         
 if(userAgent.match(/chrome|chromium|crios/i)){
     browserName = "chrome";
   }else if(userAgent.match(/firefox|fxios/i)){
     browserName = "firefox";
   }  else if(userAgent.match(/safari/i)){
    browserName = "safari";
   }else if(userAgent.match(/opr\//i)){
     browserName = "opera";
   } else if(userAgent.match(/edg/i)){
     browserName = "edge";
   }else{
      browserName="No browser detection";
   }

   dispatch({ type: appConstants.BROWSER_DETECT, browserName })
  }
}
function getParametersWidgets(params) {
  return dispatch => {
    // appService.getParametersWidgets().then(res => {
    //   if (res) {
        dispatch(success(params));
      // } else {
      //   dispatch(failure());
      // }
    // });
  };

  function success(params) {
    return { type: appConstants.PARAMETER_WIDGET_SUCCESS, params };
  }
  function failure() {
    return { type: appConstants.PARAMETER_WIDGET_FAILURE };
  }
}

function getSnackbar(snack){
  return dispatch => {
    dispatch(success(snack));
  };

  function success(snack) {
    return { type: appConstants.SNACKBAR_SUCCESS, snack };
  }
}

function getAppLogo(tenant) {
  return (dispatch) => {
    commonService.getAssestUrl(tenant).then((res) => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(logoResponse) {
    return { type: appConstants.APPLOGO_SUCCESS, logoResponse };
  }
  function failure() {
    return { type: appConstants.APPLOGO_FAILURE };
  }
}

function getprofileIcon(icon) {
  return (dispatch) => {
    commonService.getAssestUrl(icon).then((res) => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(iconResponse) {
    return { type: appConstants.PROFILE_ICON_SUCCESS, iconResponse };
  }
  function failure() {
    return { type: appConstants.PROFILE_ICON_FAILURE };
  }
}

function getprofileDarkIcon(darkIcon) {
  return (dispatch) => {
    commonService.getAssestUrl(darkIcon).then((res) => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(darkIconResponse) {
    return { type: appConstants.PROFILE_DARK_ICON_SUCCESS, darkIconResponse };
  }
  function failure() {
    return { type: appConstants.PROFILE_DARK_ICON_FAILURE };
  }
}