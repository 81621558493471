import React from "react";
import { SvgIcon } from "@material-ui/core";

export const SwitcherIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width={"682.667"}
      height={"682.667"}
      viewBox="0 0 50 50"
    >
      <path
        d="M4.5,28.88C2.36,16.72,10.48,5.11,22.65,2.97c12.17-2.15,23.77,5.98,25.92,18.15s-5.98,23.77-18.15,25.92c-12.17,2.15-23.77-5.98-25.92-18.15Z"
        style={{ fill: "none" }}
      />
      <path
        d="M30.11,36.66c-2.43,1.97-4.75,1.85-7.1,.04-.1,1.3-.17,2.4-.29,3.49-.07,.65,.17,1.05,.8,1.11,1.6,.25,3.22,.35,4.83,.29,2.22-.24,2.22-.44,1.97-2.75-.07-.65-.13-1.3-.21-2.19Z"
        style={{ fill: "none" }}
      />
      <path
        d="M25.27,35.37c.44,.21,.9,.36,1.38,.45h0c.46-.11,.91-.28,1.33-.49,2.01-1.25,3.58-3.09,4.49-5.28,.21-.46,.51-.88,.88-1.23,.72-.6,.89-1.63,.4-2.43-.2-.47-.35-.96-.47-1.46-.51-1.75-.96-3.51-1.55-5.23-.26-.39-.7-.62-1.17-.6-2.3,.54-4.67,.64-7.01,.29-.82-.1-1.81-1.07-2.5-.06-.44,.87-.75,1.79-.94,2.75-.02,.08,0,.17-.02,.24-.38,1.51,.06,3.16-.85,4.63-.22,.35,.29,1.23,.57,1.82,.23,.49,.72,.86,.92,1.35,.92,2.18,2.51,4.02,4.54,5.24Z"
        style={{ fill: "none" }}
      />
      <path
        id="uuid-a394ff3e-3bcc-4e3d-84de-f61915e558cf"
        d="M48.57,20.62c-.8-4.56-2.99-8.75-6.28-12.01-1.81-1.79-3.9-3.26-6.2-4.34-4.18-1.98-8.88-2.61-13.44-1.8-.22,.04-.43,.08-.65,.12C11.33,4.81,3.8,14.39,4.18,25.29l-3.09,.54,4.62,5.73,3.26-7.11-2.99,.52C5.71,13.62,14.7,4.2,26.06,3.93c4.39-.1,8.69,1.2,12.28,3.72,9.31,6.52,11.57,19.35,5.06,28.67-1.07,1.52-2.34,2.89-3.78,4.07-1.9-1.4-3.98-2.56-6.18-3.43-.99-.64-1.39-1.9-.95-2.99,.91-1.48,1.86-2.93,2.85-4.35,.87-1.11,1.17-2.55,.83-3.92-.26-1.18-.44-2.38-.54-3.58,0-1.02-.09-2.04-.26-3.05-.25-1.21-.78-2.35-1.55-3.32-.58-.5-1.02-1.16-1.25-1.89-.13-.6-.31-1.19-.54-1.75-.13-.29-.55-.67-.8-.65-2.74,.19-5.46,.56-8.14,1.12-2.9,.73-5.02,3.21-5.29,6.18-.21,1.37-.14,2.78-.27,4.17-.06,.84-.21,1.67-.45,2.48-.46,1.25-.3,2.64,.43,3.76,.98,1.53,2,3.03,3,4.54,.63,1.1,.31,2.51-.75,3.22-2.34,1.29-4.51,2.88-6.45,4.72,.02,.03,.05,.06,.07,.08-.17,.38-.05,.84,.29,1.08h.03s0,.01,0,.01h.07s0,.01,0,.01c1.39,.96,2.88,1.75,4.45,2.38h.03s.03,.03,.03,.03h.02s.01,.01,.01,.01h.01s0,0,0,0h.14s.01,.01,.01,.01h.02s.06,.03,.06,.03c2.99,1.11,6.18,1.56,9.35,1.32h2.48c.25-.04,.5-.09,.74-.14,11.8-2.5,19.5-13.9,17.4-25.77m-25.84,18.99c.11-1.1,.18-2.2,.29-3.49,2.36,1.8,4.67,1.93,7.1-.04,.09,.89,.14,1.54,.21,2.19,.25,2.31,.25,2.51-1.97,2.75-1.62,.06-3.24-.04-4.83-.29-.63-.05-.86-.45-.8-1.11m-2-10.07c-.2-.5-.69-.86-.92-1.35-.28-.59-.79-1.47-.57-1.82,.91-1.47,.47-3.12,.85-4.63,.02-.08,0-.17,.02-.24,.19-.95,.51-1.88,.94-2.75,.69-1.02,1.67-.04,2.5,.06,2.33,.35,4.71,.25,7.01-.29,.47-.02,.91,.21,1.17,.59,.59,1.72,1.05,3.48,1.56,5.23,.11,.5,.27,.99,.47,1.46,.49,.8,.32,1.83-.4,2.43-.37,.35-.67,.77-.88,1.23-.91,2.19-2.48,4.04-4.49,5.28-.42,.21-.87,.38-1.33,.49h0c-.48-.09-.94-.24-1.38-.45-2.03-1.22-3.62-3.06-4.54-5.25"
      />
    </SvgIcon>
  );
};
