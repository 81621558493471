import { Component, useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "i18next";
import { store } from "./helpers";
import { config } from "./enviroment";
export const useGetOverriddenNS = (ns) => {
  const currentSa = useSelector(
    (store) => store?.meterDetails?.getSaInfo?.svcTypeDesr
  );
  ns = Array.isArray(ns) ? ns : [ns];
  let updatedNs = [];
  if (currentSa) {
    ns.map((currentNs) => {
      updatedNs.push(
        `${currentSa.replace(new RegExp(/[\/?:*"" ><|]+/g), "")}_${currentNs}`
      );
      updatedNs.push(`${config.constants['DEFAULT_SERVICE_TYPE']}_${currentNs}`);
    });
  }
  else{
    ns.map((currentNs) => {
      updatedNs.push(`${config.constants['DEFAULT_SERVICE_TYPE']}_${currentNs}`);
    });
  }
  return updatedNs;
};

export const useTranslationCustom = (ns) => {
  let overriddenNs = useGetOverriddenNS(ns);
  i18n.options.fallbackNS.push(overriddenNs[overriddenNs.length - 1]);
  return useTranslation(overriddenNs, {
    i18n: i18n,
  });
};

