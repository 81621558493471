import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { history } from "../../../../../helpers/history";
import Radio from "@material-ui/core/Radio";
import { adminService } from "../../../../../services/admin.service";
import {Grid,Typography,Button,Card,List,ListItem,ListItemText, Hidden, Box} from "@material-ui/core";
import { routePaths } from "../../../../config/route-paths";
import { store } from "../../../../../helpers/store";
import { Alert } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import Payment from "../../../e_razor_pay/e_razor_payment"
import {appStyles} from "../../../../app/app-style";
import FooterContent from "../../view/e_multiple_payments_footer";
import ContentAndRequestHelp from "../../view/e_multiple_payments_content_and_request_help";
import { RechargeTextField, useCustomCheckboxStyles,} from "../../view/e_multiple_payments_style";
import Axios from "axios";
import { config } from "../../../../../enviroment";
import * as moment from 'moment'

var styles = {
    yourAccountHeader: {
      fontSize : 18,
    },
    yourAccountCard:{
      padding: '0% 4%',
    },
    selectAll:{
      color : appStyles.outerTheme.palette.ePrimary?.base
    },
    accountNo:{
      color : appStyles.outerTheme.palette.ePrimary?.base
    },
    cantSeeButton:{
      color : appStyles.outerTheme.palette.ePrimary?.base,
      border : "1.5px solid "+ appStyles.outerTheme.palette.ePrimary?.base, 
      borderRadius:"30px",
      fontSize:10,
    },
    checkBox:{
      color : appStyles.outerTheme.palette.ePrimary?.base
    },
    AccountBalanceGrid:{
      padding: '2% 2%',
    },
    AccountBalanceCard:{ 
      backgroundColor : appStyles.outerTheme.palette.ePrimary?.base,
      color: appStyles.outerTheme.palette.eWhite?.base
    },
    totalOutStandingBalance:{
      backgroundColor: appStyles.outerTheme.palette.eSecondary?.base,
      padding: '4% 4%'
    },
    padding:{
      padding: '4% 4%'
    },
    secondPadding:{
      padding: '0% 4%'
    },
    payNowButton:{
      padding: '4% 4%',
      align : "right",
      justifyContent : "right",
      justify : "right"
    },
    disabledButton:{
      align : "right",
      justifyContent : "right",
      justify : "right",
      backgroundColor: appStyles.outerTheme.palette.eSecondary?.base,
      color : appStyles.outerTheme.palette.eWhite?.base,
      borderRadius : '30px',
    },
  };

let selectBills = [];
let totalSelAmount = 0;
let connectionDetails = [];
let rechargeAccountLabels="";
let amount;
let minAmount = 0;
let maxAmount = 0;
let primaryIdentifier = "";
let makePaymentsLabels="";
let currentBal;
let meterID;
let customerClass;
let tenantMinimumRechargeAmount;
let currencyCode;

if (store.getState().labels) {
  makePaymentsLabels=store.getState().labels.data.getLabels.E_MAKE_PAYMENT;
  rechargeAccountLabels = store.getState().labels.data.getLabels.E_PREPAID_PAYMENT;
  meterID = store.getState().meterDetails?.accountPersonDetail?.accountPremiseDetailList?.meterDetailsList?.meterId
  customerClass = store.getState().meterDetails?.customerClassDesc
  currencyCode= store.getState().accountInformation
  .personAcccountDetail.personAccountList[0].curSymbol

} else {
  store.subscribe(() => {
    if (store.getState().labels) {
      makePaymentsLabels=store.getState().labels.data.getLabels.E_MAKE_PAYMENT;
      rechargeAccountLabels = store.getState().labels.data.getLabels.E_PREPAID_PAYMENT;
      meterID = store.getState().meterDetails?.accountPersonDetail?.accountPremiseDetailList?.meterDetailsList?.meterId
      customerClass = store.getState().meterDetails?.customerClassDesc
      currencyCode = store.getState().accountInformation?.personAcccountDetail?.personAccountList[0].curSymbol
    }
  });
}

export default class YourAccount extends React.Component {
  constructor(props) {
    super();
    this.state = {
      billDetails: [],
      contentRes: {},
      selectedAmount: {},
      selectedBills: [],
      connectionId: null,
      saId: null,
      currency: props.linkedAccounts?.[0]?.currency,
      totalSelAmount: 0,
      selectAllDisable: false,
      sessionId: "",
      orderId: "",
      creditCardGatewayCode: null,
      amountToPay: 0,
      totalOutStandingBalance: 0,
      initiatePaymentError: false,
      isMultiSelect: false,
      isPartialPay: false,
      value: "",
      allData:"",
      buttonDisabled:true,
      prepaidEngineUser:false,
      prepaidEngineMinimumRechargeError:false,
      prepaidEngineMinimumRechargeErrorMessage:''
    };
    this.functionAdmin = this.functionAdmin.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  functionAdmin(languageCode) {
    adminService
      .getContentData(languageCode, "E_PREPAID_PAYMENTS")
      .then((res) => {
        res.data.getContents.E_PREPAID_PAYMENTS.forEach((item) => {
          if (item.contentCode === "E_PREPAID_PAYMENTS_ERROR_MESSAGE") {
            this.setState({
              paymentErrorMessage: item.answer,
            });
          }
        });
      });
  }

  componentDidUpdate(prevProps,prevState) {

    if (prevState.amountToPay !== this.state.amountToPay) {
      if(this.state.amountToPay < minAmount ||
        this.state.amountToPay > maxAmount){
          this.setState({
            buttonDisabled:true
          })
        }else{
          this.setState({
            buttonDisabled:false
          })
        }
    }

    if(prevState.buttonDisabled !== this.state.buttonDisabled){
      if(!this.state.buttonDisabled){
        localStorage.setItem("enabled_state",1)
      }else{
        localStorage.removeItem("enabled_state")
      }
    }

    if(!this.state.buttonDisabled){
      window.addEventListener("beforeunload", this.handleBeforeUnload
      ,{capture:true});
    }else{
      window.removeEventListener("beforeunload",this.handleBeforeUnload,{capture:true});
    }

    //checking prepaid engine user account
    if(prevState.prepaidEngineUser != this.state.prepaidEngineUser){
      this.setState({
        prepaidEngineUser: store.getState().meterDetails?.isPrepaidEngineUser
      })
    }
    if (prevProps !== this.props) {
      let billdetails = [];
      store
        .getState()
        .accountInformation.personAcccountDetail.personAccountList.forEach(
          (item) => {
            if(store.getState().meterDetails?.[primaryIdentifier] === item[primaryIdentifier]){
              item.checked=true;
              amount = 0;
              selectBills = [item];
              let totalOutStandingBalance = parseFloat( this.state.prepaidEngineUser ? currentBal : item?.currentBalance);
              this.setState({
                selectedBills:selectBills,
                amountToPay:amount,
                totalOutStandingBalance,
                initiatePaymentError:false
              })
            }else{
            item.checked = false;
            }
            item.disabled = false;
            billdetails.push(item);
          }
        );
      this.setState({
        billDetails: billdetails,
        allData:store.getState().profileDetails,

      });
    }
  }

  componentDidMount() {
    //setting prepaidEngineFlag value
    let prepaidEngineUserFlag=store.getState().meterDetails?.isPrepaidEngineUser
    if(prepaidEngineUserFlag){
      this.setState({
        prepaidEngineUser : prepaidEngineUserFlag === 'Y' ? true : false
      })
    }
    if(this.state.buttonDisabled){
      window.removeEventListener("beforeunload",this.handleBeforeUnload,{capture:true});
    }

    let languageCode = "";
    if (
      store.getState()?.parametersWidgets?.ParameterLookup
        ?.TENANT_PRIMARY_LANGUAGE
    ) {
      languageCode = store.getState()?.parametersWidgets?.ParameterLookup
        ?.TENANT_PRIMARY_LANGUAGE;
      languageCode && this.functionAdmin(languageCode);
    } else {
      store.subscribe(() => {
        if (
          store.getState()?.parametersWidgets?.ParameterLookup
            ?.TENANT_PRIMARY_LANGUAGE
        ) {
          languageCode = store.getState()?.parametersWidgets?.ParameterLookup
            ?.TENANT_PRIMARY_LANGUAGE;
          languageCode && this.functionAdmin(languageCode);
        }
      });
    }
    let billdetails = [];
    store
      .getState()
      .accountInformation.personAcccountDetail.personAccountList.forEach(
        (item) => {
          item.checked = false;
          item.disabled = false;
          billdetails.push(item);
        }
      );
    this.setState({
      billDetails: billdetails,
    });
    if (store.getState().parametersWidgets.ParameterLookup) {
      let isMultiSelect =
        store.getState().parametersWidgets.ParameterLookup.MULTI_SELECT_TYPE ===
        "false"
          ? true
          : false;
      let isPartialPay =
        store.getState().parametersWidgets.ParameterLookup
          .PARTIAL_PAY_ENABLE === "true"
          ? true
          : false;

      this.setState({
        isMultiSelect: isMultiSelect,
        isPartialPay: isPartialPay,
      });
      primaryIdentifier = store.getState().parametersWidgets.ParameterLookup
        .PRIMARY_IDENTIFIER;
    }

    const balanceInformation =    {
      "indexName":"balance-audit-configuration",
      "isSortRequired":true,
      "query": {
          "match": {
            "meterId":meterID
          }
    }
    }
    Axios.post(config.urls.PREPAID_ELASTC_SEARCH_DATA, 
      balanceInformation
    ).then((res) => {
      const resArray = res.data.data
      currentBal = resArray[resArray.length-1]._source.outstandingBalance;
    }).catch((err)=>{
      console.log(err);
    });

    const body =    {
      "indexName":"tenant-recharge-configuration"
    }
    Axios.post(config.urls.PREPAID_ELASTC_SEARCH_DATA+"/get-documents",
    body
    ).then((res) => {
      const resArray = res.data
      let thresoldDataByDate=resArray.filter((s)=>s._source.customerClass == customerClass && 
      (moment(new Date()).isBetween(moment(new Date(s._source.startDate)).startOf('day'),moment(new Date(s._source.endDate)).startOf('day'))));
      tenantMinimumRechargeAmount = thresoldDataByDate[0]._source.minimumRecharge 
    }).catch((err)=>{
      console.log(err);
    });


    
  

  }

 
  componentWillMount() {
    store.getState().snackbar = true;
    selectBills = [];
    if (store.getState().parametersWidgets.ParameterLookup) {
      this.setState({
        creditCardGatewayCode: store
          .getState()
          .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[1],
      });
    }
    if (store.getState().meterDetails) {
      this.state.billDetails.forEach((item) => {
        if (!item.disabled) {
          if (
            item.accountSaList[0].saId ===
            store.getState().meterDetails.getSaInfo.saId
          ) {
            item.checked = true;
            totalSelAmount = parseFloat(this.state.prepaidEngineUser ? currentBal : item[0]?.currentBalance);
            selectBills.push(item);
            this.setState({
              saId: store.getState().meterDetails.getSaInfo.saId,
            });
            selectBills.forEach((element, i) => {
              connectionDetails.push({
                saId: element?.accountSaList[0]?.saId,
                Amount: this.state.prepaidEngineUser ? currentBal :element.currentBalance,
              });
            });
            localStorage.removeItem("PaymentConnections");
            localStorage.setItem(
              "PaymentConnections",
              JSON.stringify(connectionDetails)
            );
          }
        }
      });
      this.setState({
        selectedBills: selectBills,
      });
      store.getState().snackbar = false;
    }
  }

  componentWillUnmount(){
    window.removeEventListener("beforeunload", this.handleBeforeUnload,{capture:true});
  }

  handleBeforeUnload=(e)=>{
    e.preventDefault()
    e.returnValue = '';
  }

  
  updateErrorMessage() {
    let totalSelectedAmount = 0;
    let currencyCode;
    this.state.selectedBills.forEach((selectedAmount) => {
      totalSelectedAmount += parseFloat(this.state.prepaidEngineUser? currentBal : selectedAmount?.currentBalance);
      currencyCode = selectedAmount.curSymbol;
    });
    let message = this.props.paymentErrorMessage;
    message = message && message.replace("*maxvalue*", currencyCode + maxAmount);

    return message;
  }
  handleCheckbox = (event) => {
    connectionDetails = [];
    totalSelAmount = 0;
    if (event.target.checked) {
      if (event.target.value === "selectAll") {
        selectBills = [];
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (element) => {
              if (!element.disabled) {
                element.checked = true;
                this.state.isMultiSelect == true
                  ? selectBills.push(element)
                  : (selectBills = [element]);
              }
            }
          );
        this.setState({
          selectedBills: selectBills,
          selectAllDisable: true,
          initiatePaymentError: false,
        });
      }
      store
        .getState()
        .accountInformation.personAcccountDetail.personAccountList.forEach(
          (element) => {
            if (event.target.value === element[primaryIdentifier]) {
              element.checked = true;
              amount = 0;
              this.state.isMultiSelect == true
                ? selectBills.push(element)
                : (selectBills = [element]);
            }
          }
        );
      let totalBalance = 0;
      if (selectBills.length > 0 ) {
        selectBills.forEach(
          (item) =>
            (totalBalance = totalBalance + parseFloat(this.state.prepaidEngineUser ? currentBal : item?.currentBalance))
        );
      }
      this.setState({
        selectedBills: selectBills,
        amountToPay: amount,
        totalOutStandingBalance: totalBalance,
        initiatePaymentError: false,
      });
    } else {
      if (event.target.value === "selectAll") {
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (element) => {
              element.checked = false;
              selectBills.pop(element);
            }
          );
        this.setState({
          selectedBills: selectBills,
          selectAllDisable: false,
          initiatePaymentError: false,
        });
      } else {
        store
          .getState()
          .accountInformation.personAcccountDetail.personAccountList.forEach(
            (element) => {
              if (event.target.value === element[primaryIdentifier]) {
                element.checked = false;
                selectBills.forEach((item, i) => {
                  if (item[primaryIdentifier] === event.target.value) {
                    selectBills.splice(i, 1);
                    amount = 0;
                  }
                });
              }
            }
          );
      }
      selectBills.forEach((element, i) => {
        totalSelAmount =
          parseFloat(totalSelAmount) + parseFloat(this.state.prepaidEngineUser ? currentBal : element?.currentBalance);
        connectionDetails.push({
          saId: element[primaryIdentifier],
          Amount: this.state.prepaidEngineUser ? currentBal : element?.currentBalance,
        });
      });
      this.setState({
        selectedBills: selectBills,
        selectAllDisable: false,
        amountToPay: totalSelAmount,
        totalOutStandingBalance: 0,
        initiatePaymentError: false,
      });
    }
  };

  debounce = (fn, delay) => {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  handleAmountPayment = (value) => {
    let amountError = false;

    if(tenantMinimumRechargeAmount && (value < tenantMinimumRechargeAmount)){
      this.setState({
        prepaidEngineMinimumRechargeError:true,
        prepaidEngineMinimumRechargeErrorMessage:`Please recharge with minimum
        ${tenantMinimumRechargeAmount} ${currencyCode}`
      })
    }else{
      this.setState({
        prepaidEngineMinimumRechargeError:false,
        prepaidEngineMinimumRechargeErrorMessage:''
      })
    }
   

    let balance = value.indexOf(".") >= 0
        ? value.substr(0, value.indexOf(".")) +
        value.substr(value.indexOf("."), 3)
        : value;
    
    if(balance){
      amount = parseFloat(balance);

   
    if (amount < minAmount || amount > maxAmount) {
      amountError = true;
    }
    this.setState({
      amountToPay: amount,
      totalOutStandingBalance:
        parseFloat(this.state.prepaidEngineUser ? currentBal : this.state.selectedBills[0]?.currentBalance) + parseFloat(amount),
      initiatePaymentError: amountError,
    });
  }else{
    this.setState({
      amountToPay: null,
      totalOutStandingBalance:
        parseFloat(this.state.prepaidEngineUser ? currentBal : this.state.selectedBills[0]?.currentBalance),
    });
  }
  };
  keyPress = (evt) => {
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
  };
  handleWalletBalance = (walletAmount) => (event) => {
    let balance = walletAmount;
    
    if(balance){
      amount = parseFloat(balance);

    let amountError = false;
    if (amount < minAmount || amount > maxAmount || (this.state.amountToPay + amount) > maxAmount) {
      amountError = true;
    }
    this.setState({
      amountToPay: amount + this.state.amountToPay,
      totalOutStandingBalance:
        parseFloat(this.state.prepaidEngineUser ? currentBal: this.state.selectedBills[0]?.currentBalance) + amount + this.state.amountToPay,
      initiatePaymentError: amountError,
    });
  }else{
    this.setState({
      amountToPay: '' ,
      totalOutStandingBalance:
        parseFloat(this.state.prepaidEngineUser ? currentBal : this.state.selectedBills[0]?.currentBalance),
    });
  }
  }
  render() {
    const {buttonDisabled}=this.state;
    const { amountToPay } = this.state;
    if (store.getState().parametersWidgets.ParameterLookup) {
      minAmount = store.getState().parametersWidgets.ParameterLookup
        .E_PREPAID_MINIMUM_TOP_UP_VALUE;
      minAmount = Number(minAmount);
      maxAmount = store.getState().parametersWidgets.ParameterLookup
        .E_PREPAID_MAXIMUM_TOP_UP_VALUE;
    }
 
    return (
      <Grid >
        <Grid item container direction="row" justify="center">
          <Grid item xs={12} md={7} style={styles.yourAccountCard}>
            <br />
            <Typography >
              <b>{rechargeAccountLabels?.E_YOUR_PREPAID_ACCOUNTS_TITLE} </b>
            </Typography>
            <br />
            {this.state.billDetails.length === 1 ||
            this.state.isMultiSelect == false ? null : (
              <FormControlLabel
                control={
                  <Checkbox
                    value="selectAll"
                    onChange={this.handleCheckbox}
                    color="primary"
                    checked={this.state.selectAllDisable}
                  />
                }
                label={
                    <Typography style={styles.selectAll}>
                    <b>{makePaymentsLabels?.E_PREPAID_SELECT_ALL}</b>
                  </Typography>
                }
              />
            )}
            {this.state.billDetails.length !== 0 ? (
              <div>
                <List
                  dense={!this.props.isTabletOrMobile}
                  className="paddingList">
                  <FormGroup> 
                    {store
                      .getState()
                      .accountInformation.personAcccountDetail.personAccountList.map(
                        (item) => {
                          let labelText = (
                            <React.Fragment>
                              {this.props.isTabletOrMobile ? (
                                <Grid >
                                  <Typography style={styles.accountNo}>
                                  <b>{makePaymentsLabels?.E_ACCOUNT_NO}</b>{""}
                                    {item[primaryIdentifier]}{" "}{" "}{item.accountPersonDetail.accountPremiseDetailList.address1}{item.accountPersonDetail.accountPremiseDetailList.address2},{item.accountPersonDetail.accountPremiseDetailList.city},{item.accountPersonDetail.accountPremiseDetailList.state},{item.accountPersonDetail.accountPremiseDetailList.postal}
                                  </Typography>
                                </Grid>
                              ) : (
                                <Grid xs={12}>
                                  <ListItem className="paddingList">
                                    <ListItemText
                                      primary={
                                        <Typography style={styles.accountNo}>
                                        <b>{makePaymentsLabels?.E_ACCOUNT_NO}</b>{""}
                                        {item[primaryIdentifier]}{" "}{" "}{item?.accountPersonDetail?.accountPremiseDetailList?.address1}{item?.accountPersonDetail?.accountPremiseDetailList?.address2},{item?.accountPersonDetail?.accountPremiseDetailList?.city},{item?.accountPersonDetail?.accountPremiseDetailList?.state},{item?.accountPersonDetail?.accountPremiseDetailList?.postal}
                                      </Typography>
                                      }
                                    />
                                  </ListItem>
                                </Grid>
                              )}
                            </React.Fragment>
                          );
                          return (
                            <React.Fragment>
                              {item.accountSaList[0].isPrepaidSa === "Y" ? (
                                <>
                                  <FormControlLabel
                                    control={
                                      item.disabled ? (
                                        <StyledCheckbox disabled />
                                      ) : this.state.isMultiSelect ? (
                                        <div>
                                          <Checkbox
                                            value={item[primaryIdentifier]}
                                            onChange={this.handleCheckbox}
                                            style={styles.checkBox}
                                            checked={item.checked}
                                            disabled={item.disabled}
                                          />
                                        </div>
                                      ) : (
                                        <Radio
                                          value={item[primaryIdentifier]}
                                          onChange={this.handleCheckbox}
                                          style={styles.checkBox}
                                          checked={this.state.selectedBills.includes(
                                            item
                                          )}
                                          disabled={item.disabled}
                                        />
                                      )
                                    }
                                    label={labelText}
                                  />
                                  <Divider />
                                  {this.props.isTabletOrMobile ? <br /> : null}
                                </>
                              ) : null}
                            </React.Fragment>
                          );
                        }
                      )}
                  </FormGroup>
                </List>
              </div>
            ) : null}
             <br/>
            <div align="right">
              <Button style={styles.cantSeeButton}
                onClick={() => {
                  history.push({
                    pathname: routePaths.GET_SERVICE,
                    state: { tab: "E_COMPLAINT" }
                });
                }}
              >
                {rechargeAccountLabels?.E_PREPAID_CANT_SEE_AN_ACCOUNT}
              </Button>
            </div>
            <br />
            <Hidden smDown xlUp>
            <FooterContent/>
            </Hidden>
          </Grid>

          <Grid item xs={12} md={5} style={styles.AccountBalanceGrid}>
            <Card style={styles.AccountBalanceCard}>
              <Typography style={styles.padding}  >
                {rechargeAccountLabels?.E_PREPAID_ACCOUNT_BALANCES} <br />
              </Typography>
              <br />
              {this.state.selectedBills
                ? this.state.selectedBills.map((element) => {
                    return (
                      <Grid container justify="space-between" >
                        <Grid item sm={5} xs={6}style={styles.secondPadding}>
                          <Typography >
                              {rechargeAccountLabels?.E_PREPAID_ACCOUNT_BALANCE}
                            <br />
                            {element[primaryIdentifier]}
                          </Typography>
                        </Grid>
                        
                        <Grid item sm={3} xs={6} style={styles.secondPadding}>
                          <Typography align="right">
                            {element.curSymbol}&nbsp;
                            {this.state.prepaidEngineUser ? currentBal : element?.currentBalance}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })
                : null}
              <br />
              {this.state.selectedBills.length == 1 &&
              this.state.isPartialPay == true ? (
                <Grid container justify="space-between" style={styles.totalOutStandingBalance}>
                  <Grid item md={5} >
                    <Typography >
                        {rechargeAccountLabels?.E_PREPAID_TOP_UP_AMOUNT}
                    </Typography>
                  </Grid>
                  
                  <Grid item lg={7} md={7} xs={6} align="right" >
                    <Grid container className="partialPay" justify="flex-end">
                      <Grid item lg={1} md={1} xs={1}>
                        <Typography >
                          <Box textAlign={"left"}>
                          {
                            store.getState().accountInformation
                              .personAcccountDetail.personAccountList[0].curSymbol + " "
                          }
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item lg={6} md={6} xs={5} >
                        <RechargeTextField
                          fullWidth="false"
                          align="right"
                          value={amountToPay}
                          type="number"
                          placeholder="0"
                          id="amount-to-pay"
                          variant="outlined"
                          onKeyUp={(e) =>
                            this.handleAmountPayment(e.target.value)
                          }
                          onKeyPress={this.keyPress}

                          onChange={(e) =>
                            this.handleAmountPayment(e.target.value)
                          }
                          
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  
                </Grid>
              ) : null}
              <br />
              <br />
              <Grid container>
                <br />
                <Grid item xs={7}  style={styles.secondPadding}>
                  <p align="left" style={styles.secondPadding}>
                    {rechargeAccountLabels?.E_PREPAID_FINAL_ACCOUNT_BALANCE}
                  </p>
                </Grid>
                <Grid item xs={5}  style={styles.secondPadding}>
                  <Typography align="right" style={styles.secondPadding}>
                    {
                      store.getState()?.accountInformation
                        ?.personAcccountDetail?.personAccountList?.[0]
                        ?.curSymbol
                    }
                    &nbsp;
                    {parseFloat(this.state.totalOutStandingBalance).toFixed(2)}
                  </Typography>
                </Grid>
                <br />
              </Grid>
            <Grid item xs={12} md={12}> 
                <Grid >
                  <Grid>
                    <br />
                  </Grid>
                  <Grid direction="row" align="center">

                  {this.state.prepaidEngineMinimumRechargeError ? (
                      <Grid Container>
                        <Grid item xs={12}>
                          <Alert severity="error">
                            {this.state.prepaidEngineMinimumRechargeErrorMessage}
                          </Alert>
                        </Grid>
                      </Grid>
                    ) : null}
                    
                    {this.state.initiatePaymentError ? (
                      <Grid Container>
                        <Grid item xs={12}>
                          <Alert severity="error">
                            {this.updateErrorMessage()}
                          </Alert>
                        </Grid>
                      </Grid>
                    ) : null}
                    {this.state.amountToPay < minAmount ||
                    this.state.amountToPay > maxAmount ? (
                      <Grid>
                        <br />
                        {rechargeAccountLabels && (
                          <span>
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              style={styles.payNowButton}
                            >
                              <Grid item>
                                <Button
                                  variant="contained"
                                  size="medium"
                                  style={styles.disabledButton}
                                  disabled={true}>
                                  {rechargeAccountLabels?.E_PREPAID_PAY_NOW}
                                </Button>
                              </Grid>
                            </Grid>
                          </span>
                        )}
                        <br />
                      </Grid>
                    ) : (
                        <Grid>
                          {rechargeAccountLabels && (
                            <span>
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                style={styles.payNowButton}>
                                <Grid item>
                                  {this.state.amountToPay ? (
                                    <Payment
                                      totalAmount={
                                        this.state.selectedBills.length == 1
                                          ? this.state.amountToPay
                                          : this.state.totalOutStandingBalance
                                      }
                                      allData={this.state.allData}
                                      selectBills={this.state.selectedBills}
                                      buttonLabel={rechargeAccountLabels?.E_PREPAID_PAY_NOW}
                                      saId={this.state.saId} 
                                      prepaidEngineMinimumRechargeError={this.state.prepaidEngineMinimumRechargeError}
                                      />
                                  ) : null}
                                </Grid>
                              </Grid> 
                            </span>
                          )}
                        </Grid>   
                    )}
                  </Grid>  
                </Grid>
              </Grid>
              <ContentAndRequestHelp/>
            </Card>
            <br />
            <Hidden smUp>
            <FooterContent/>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function StyledCheckbox(props: CheckboxProps) {
  const classes = useCustomCheckboxStyles();
  return <Checkbox icon={<span className={classes.icon} />} {...props} />;
}