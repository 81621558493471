import React, { useState, useEffect } from "react";
import {
  SwipeableDrawer,
  Toolbar,
  Box,
  Grid,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import E_SidebarList from "../desktop_sidebar/view/_sidebarList";
import E_SocialLinks from "../desktop_sidebar/view/_socialLinks";
import E_ImpresaImage from "../../e_header/view/_impresacx_img";
import MenuIcon from "@material-ui/icons/Menu";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { commonService } from "../../../../services/common.service";

const useStyle = makeStyles((theme) => ({
  MuiDrawer: {
    background: theme.palette.ePrimary?.base,
    width: "85%",
    [theme.breakpoints.only('sm')]: {
      width: "50%",
    }
  },
  drawerBackground:{
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    width:"100%",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  whiteColor: {
    color: theme.palette.eWhite?.base,
  },
  socialLinks: {
    marginLeft: "1em"
  },
  imageGrid: {
    height: "100%"
  },
  imageGridItem: {
    padding: '2em'
  },
  whiteLogoImg: {
    height: "auto",
    maxWidth: "10rem"
  }
}));

export default function E_MobileSideBar({
  open,
  handleDrawerOpen,
  components,
}) {
  let classes = useStyle();
  const isTabletOrMobile = useMediaQuery({ minWidth: 961 });
  let widgets = useSelector((state) => state?.parametersWidgets?.WidgetLookUp);
  let [socialLinks, setSocialLinks] = useState(false);
  let [socialData, setSocialData] = useState();
  let [whiteLogo, setWhiteLogo] = useState({});
  
  let whiteLogoImage = useSelector(
    (store) => store?.parametersWidgets?.ParameterLookup?.E_ABJAYON_WHITE_IMAGE
  );
  useEffect(() => {
    if (whiteLogoImage) {
      commonService.getAssestUrl(whiteLogoImage).then((response) => {
        setWhiteLogo(response)
      })
      .catch(err => setWhiteLogo({}))
    }
},[whiteLogoImage]);

  useEffect(() => {
    widgets &&
      widgets.map((item) => {
        if (item.widgetCode === "E_SIDEBAR.SOCIAL_LINKS") {
          setSocialLinks(item.widgetDefaultValue === "true" ? true : false);
          setSocialData(item.children);
        }
      });
  }, [widgets]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleDrawerOpen();
  };

  return (
    <>
      <SwipeableDrawer
        classes={{ paper: classes.MuiDrawer }}
        className={classes.drawerBackground}
        anchor="left"
        open={open}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Grid container spacing={1} className={classes.drawerHeader}>
          <Grid item>
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
            >
              <MenuIcon className={classes.whiteColor} />
            </IconButton>
          </Grid>
          <Grid item>
            <E_ImpresaImage white={true} />
          </Grid>
        </Grid>

        <Box style={{ height: "100%" }}>
          <Grid
            container
            style={{ height: "100%" }}
            direction="column"
            justify={isTabletOrMobile ? "space-between" : "flex-start"}
          >
            <Grid item>
              <E_SidebarList
                handleDrawerOpen={handleDrawerOpen}
                mobile={true}
              />
            </Grid>
            <Grid item className={classes.socialLinks}>
              {/*socialLinks && <E_SocialLinks socialData={socialData} />*/}
            </Grid>
          </Grid>
        </Box>
        
        <Grid 
          container
          direction="column"
          justify={"flex-end"}
          alignItems={"flex-end"}
          className={classes.imageGrid}>
            <Grid item className={classes.imageGridItem}>
              <img
              className={classes.whiteLogoImg}
               src={whiteLogo?.assetPath}
               alt={whiteLogo?.fallBackLabelKey}            
              />
            </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
}