import { alertsPriorityConstant } from "../model/alert-priority-constants";

const initialStateDisplay = {display:true};
export function alertsPriorityDataDisplay(state = initialStateDisplay, action) {
  switch (action.type) {
    case alertsPriorityConstant.ALERTS_PRIORITY_DISPLAY:
      return { ...state, display: action.display };
    default:
      return state;
  }
}