import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../enviroment";
import { commonService } from "../../services/common.service";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { appActions } from "../../actions";
import ChatWidget from "../chat-widget/chat.widget";

export default function TitleComponent() {
  // parameter widget
  const { t: widget, ready: widgetReady } = useTranslation(
    "widget-ParameterLookup"
  );
  const { t: widget1, ready: widgetReady1 } = useTranslation(
    "widget-WidgetLookUp"
  );
  let ParameterLookup = i18n.store.getResourceBundle(
    i18n.language,
    "widget-ParameterLookup"
  );
  let WidgetLookUp = i18n.store.getResourceBundle(
    i18n.language,
    "widget-WidgetLookUp"
  );
  const dispatch = useDispatch();
  const { t: content, ready: contentReady } = useTranslation("content-HEADER");
  const title = useSelector(
    (state) => state?.labels?.data?.getLabels?.HEADER?.TITLE
  );
  const [appDescription, setAppDesc] = useState("");
  const [favicon, setFavicon] = useState("");
  const isAiEnabledChatBotEnabled = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.E_ENABLE_CHATGPT_CHAT_WIDGET
  );
  const chatGPTbotID = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.E_CHATGPT_CHAT_WIDGET_ID
  );
  const chatGPTbotSrc = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.E_CHATGPT_CHAT_WIDGET_SRC
  );

  const [showChatGPTBot, setShowChatGPTBot] = useState(false);

  useEffect(() => {
    let timer1;
    if (isAiEnabledChatBotEnabled === "true" && chatGPTbotSrc && chatGPTbotID) {
      timer1 = setTimeout(() => {
        setShowChatGPTBot(true)
      }, 10000);
    }
    else if(isAiEnabledChatBotEnabled === "false") {
      setShowChatGPTBot(false);
      // timer1 = setTimeout(() => {
        ChatWidget();
      // }, 10000);
    }
    return () => {
      clearTimeout(timer1);
    };
  }, [isAiEnabledChatBotEnabled, chatGPTbotSrc, chatGPTbotID]);
  useEffect(() => {
    if (
      WidgetLookUp &&
      Object.values(WidgetLookUp).length &&
      ParameterLookup &&
      Object.values(ParameterLookup).length
    ) {
      let WidgetLookUps = [];
      Object.keys(WidgetLookUp).forEach((key) => {
        WidgetLookUps.push(WidgetLookUp[key]);
      });
      let dtaa = {
        ParameterLookup: ParameterLookup,
        WidgetLookUp: WidgetLookUps,
      };
      dispatch(appActions.getParametersWidgets(dtaa));
    }
  }, [widgetReady1, widgetReady]);
  const lang = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  const favi = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.APPLICATION_FAVICON
  );
  useEffect(() => {
    (async () => {
      if (lang) {
        let description = content("APPLICATION_DESCRIPTION")?.answer;
        setAppDesc(description);
      }
      if (favi) {
        await commonService.getAssestUrl(favi).then((resp) => {
          setFavicon(resp);
        });
      }
    })();
  }, [favi, lang]); //adding lang casuing issue
  const canonical = config.urls.BASE_URL;
  return (
    <>
      {/* <Favicon url={favicon?.assetPath ? favicon?.assetPath : '/'} /> */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={appDescription} />
        <link rel="canonical" href={canonical} />
        <link
          rel="apple-touch-icon"
          href={favicon?.assetPath ? favicon?.assetPath : "/"}
        />
        {/* <link
          rel="shortcut icon"
          type="image/png"
          href={favicon?.assetPath ? favicon?.assetPath : '/'}
        /> */}
        <link rel="icon" href={favicon?.assetPath ? favicon?.assetPath : "/"} />
        {/* <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        ></meta> */}
        {showChatGPTBot && (
          <script src={chatGPTbotSrc} id={chatGPTbotID}></script>
        )}
      </Helmet>
    </>
  );
}
