import React, { useState, useEffect } from "react";
import {
  IconButton,
  Grid,
  OutlinedInput,
  InputAdornment,
  FormHelperText,Typography, CircularProgress
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles, withStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { history } from "../../../../helpers";
import { routePaths } from "../../../config/route-paths";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import { getUserAccountDetails } from "./model/service"

const useStyles = makeStyles((theme) => ({
  iconHeight: {
    height: "10% !important",
  },
  iconStyle: {
    padding: "0.2rem 0.3rem 0.2rem 0.5rem !important",
    position: "absolute",
    right: 0,
    borderRadius: "0 8px 8px 0",
    background: theme.palette.ePrimary.hover,
    color: theme.palette.eWhite.base,
    "&:hover": {
      background: theme.palette.ePrimary.hover,
    }
  },
  widthSearch: {
    width: "18vw",
  },
  fullwidthSearch: {
    width: "98%",
    marginLeft: "1%",
    position: "relative",
  },
  errorColor: {
    color: theme.palette.eDanger?.dark,
    marginTop: "0px",
    [theme.breakpoints.between("md","xl")]:{
      marginBottom:"1%"
    }
  },
  space: {
    margin: "1.1rem",
  },
  accountSearch:{
    padding: "5px 10px",
    background: theme.palette.eFaint.hover,
    opacity: ".5",
    fontSize: "0.85rem",
    borderRight: "1px solid #DEE2E4",
  },
  loader: {
    height: "10% !important",
    color: theme.palette.eWhite.base,
  }
}));
export const CustomOutline = withStyles({
  root: {
    borderRadius: "8px",
    minHeight: "2rem",
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DEE2E4",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DEE2E4",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DEE2E4",
        borderWidth: "1px",
    },
  },
  adornedStart: {
    paddingLeft: "0px !important",
  },
  input: {
    padding: "6px 4px 6px 1px !important",
    borderRadius: "inherit",
    backgroundColor: "none !important",
    fontSize: "14px",
    textIndent: "10px",
  },
})(OutlinedInput);

export const CustomInputAdornment = withStyles({
  positionStart: {
    marginRight: "0px !important",
  },
})(InputAdornment);
export default function E_AgentSearchbar(props) {
  const classes = useStyles();
  const [accountId, setAccountId] = useState("");
  const [error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [invalidAccountId, setInvalidAccountId] = useState(false);
  
  let location = useLocation();
  let bearer = JSON.parse(localStorage.getItem("bearer"));

  useEffect(() => {
    if (history?.location?.state?.word) {
      setAccountId(history?.location?.state?.word);
    } else {
      setAccountId("");
      setError(false)
    }
  }, [history?.location?.state?.word, location]);
  const {t:labels, ready: labelReady} = useTranslation("label-E_HEADER");
  const handleSearchInputChange = (e) => {
    if(e.target.value.trim().length>=0){
      setAccountId(e.target.value);
      setError(false);
    }
    else{
      
    }
  };

  const searchClickHandle = (e) => {
    // debugger
    e.preventDefault();
    if (accountId?.length >= 10 && accountId?.length <= 20) {
      setLoading(true)
      getUserAccountDetails(
        accountId
      )
        .then((res) => {
          bearer['accountDetails']=  res.data.getUserAccountDetails;
          bearer['personId']=  res.data.getUserAccountDetails?.indentifiers.personId;
          bearer['accountId']=  accountId;
          localStorage.setItem('bearer',JSON.stringify(bearer))
          localStorage.setItem("accountSelected",accountId);
          props.handleChange()
          setLoading(false);
          // window.location.reload();
          history.push(routePaths.HOME);
        })
        .catch((err) => {
          setLoading(false)
          let bearer = JSON.parse(localStorage.getItem("bearer"));
          delete bearer?.accountDetails;
          localStorage.setItem('bearer',JSON.stringify(bearer))
          localStorage.removeItem('accountSelected');
          props.handlefailed()
        });
    } else {
      setInvalidAccountId(true)
    }
  };
  const keyPress = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      searchClickHandle();
    }
  };

  return (
    <form>
      <CustomOutline
        id="outlined-adornment-weight"
        placeholder={labelReady ? labels?.E_SEARCH_PLACEHOLDER : ""}
        onChange={(e) => handleSearchInputChange(e)}
        onInput = {(e) =>{
          e.target.value = (e.target.value).toString().slice(0, parseInt(20))
        }}
        value={accountId}
        autoComplete="off"
        className={props.fullWidth ? classes.fullwidthSearch : classes.widthSearch}
        startAdornment={
          <CustomInputAdornment position="start">
            <Typography className={classes.accountSearch}>{labels('E_AGENT_SEARCH_ACCOUNT')}</Typography> 
            <IconButton
              type="submit"
              onClick={searchClickHandle}
              onKeyPress={keyPress}
              className={classes.iconStyle}
            >
              {
               !Loading ? <SearchIcon className={classes.iconHeight} />
               : <CircularProgress className={classes.loader} size={"1.5rem"}/>
              }
            </IconButton>
          </CustomInputAdornment>
        }
      />
      {/* {
        Loading?(
           <Skeleton />
        ):
        (
          <></>
        )
      } */}
      {error ? (
        labelReady ?
        <FormHelperText className={classes?.errorColor}>
          {labels("E_SEARCH_ERROR")}
        </FormHelperText>
        : <Skeleton />
      ) : (
        <div className={classes.space} />
      )}
    </form>
  );
}
