import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { store } from "../../../../helpers/store";
import { history } from "../../../../helpers/history";
import { routePaths } from "../../../config/route-paths";
import { Button } from "@material-ui/core";
import { indigo800 } from "material-ui/styles/colors";
import { useTranslationCustom } from "../../../../i18nOverrides";

const useStyles = makeStyles((theme) =>
  createStyles({
    requestHelp: {
      backgroundColor: theme.palette.eSecondary?.base,
      color: theme.palette.eWhite?.base,
      borderRadius: "30px",
      marginBottom: 20,
      marginTop: 10,
      "&:hover": {
        backgroundColor: theme.palette.eSecondary?.hover,
      },
    },
    impContent: {
      padding: "2% 6% !Important",
      backgroundColor: theme.palette.eTertiary?.base,
      marginBottom: "2%",
    },
    paddingContent: {
      padding: "4% !important",
    },
  })
);

export default function ContentAndRequestHelp() {
  const [paymentSubtitle, setPaymentSubTitle] = useState("");
  const [impSubtitle, setImpSubTitle] = useState("");
  const languageCode = useSelector(
    (state) =>
      state?.parametersWidgets?.ParameterLookup?.TENANT_PRIMARY_LANGUAGE
  );
  let tenantName = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.TENANT_NAME
  );
  const { t: content, ready: contentReady } = useTranslationCustom(
    "content-E_MAKE_PAYMENTS"
  );
  const classes = useStyles();
  useEffect(() => {
    if (contentReady) {
      setPaymentSubTitle(
        content("E_IMPORTANT_INSTRUCTION")?.answer?.replace(
          new RegExp("<TENANT_NAME>", "g"),
          tenantName
        )
      );
      setImpSubTitle(
        content("E_INQURY_LINK")?.answer?.replace(
          new RegExp("<TENANT_NAME>", "g"),
          tenantName
        )
      );
    }
  }, [contentReady]);
  const { t: labels, ready: labelReady } = useTranslationCustom(
    "label-E_MAKE_PAYMENT"
  );
  return (
    <>
      <Grid container spacing={2} className={classes.impContent}>
        <Grid item>
          <Typography
            variant="caption"
            align="center"
            dangerouslySetInnerHTML={{
              __html: paymentSubtitle,
            }}
          ></Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            align="left"
            dangerouslySetInnerHTML={{
              __html: impSubtitle,
            }}
          ></Typography>
        </Grid>
      </Grid>
      <Grid align="center">
        <Button
          variant="contained"
          size="medium"
          className={classes.requestHelp}
          onClick={() => {
            history.push({
              pathname: routePaths.GET_SERVICE,
              state: { tab: "E_COMPLAINT" },
            });
          }}
        >
          {labels("E_REQUEST_HELP")}
        </Button>
      </Grid>
    </>
  );
}
