export const routePaths = {
  HOME: "/", //dashboard
  USAGE: "/usage-stats", //usage component
  SELF_HELP: "/self-help", //Selfhelp component
  TARIFF: "/tariff", //Tariff Component
  LOGOUT: "/logout", //logout
  CARDS: "/cards", //Cards - Dev Only Component
  RES: "/responsive", //Responsive - Dev Only Component
  PAYMENT_RECEIPT:"/payment-receipt",//Payment receipt
  BILLING: "/billing", //Billing Component
  GET_SUPPORT: "/get-support",// get support ticket
  GET_SERVICE: "/service", 
  GET_SERVICE_REQUEST: "/service/service-request",
  PROFILE: "/profile", // get profile
  INITIATE_PAYMENT: "/initiate-payment", // Initiate Payment Component
  BILL_DETAILS: "/bill-details", //Bill Details
  PAYMENT_CONFIRMATION: "/payment", //Payment Confirmation Component
  RETRY: "/service-unavailable",
  // CONTACTS: "/contacts", //Contacts Component
  SEARCH_RESULTS: "/path/search-results",
  ALERTS_AND_NOTIFICATION : "/alerts-notification", // alerts and notifications
  MANAGE_PREFERENCE : "/manage-preference", //Manage Preference
  ENERGY_PLANS : "/energy-plans",  //energy-plans
  ENERGY_DETAILS_PAGE : "/energy-plans-details",  //energy-plans-details-page
  SUSTAINABILITY : "/tariff-plans",
  OUTAGE:"/outage",
  ECOMMERCE: "/store",
  Your_Rewards:"/your_rewards"    //Demand Side Managment
};
