//@flow

import { config } from "../enviroment";
import axios from "axios";
import moment from "moment";
import { saveAs } from 'file-saver';
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";

type AssestResponse = {
  assetCode: string,
  assetPath: string,
  fallBackLabelKey: string,
};

export const commonService = {
  encodeString: encodeString,
  decodeString: decodeString,
  logoutUser: logoutUser,
  getAssestUrl: getAssestWithCode,
  convertHexToRGBA: convertHexToRGBA,
  dateFormat: dateFormat,
  get_days: get_days,
  downloadPdf: downloadPdf,
  getDueDays: getDueDays,
  Numberwithcommas: Numberwithcommas,
  getBearerFromAccess: getBearerFromAccess
};

function encodeString(inputString: string) {
  return inputString;
}

function decodeString(encodedString: string) {
  return encodedString;
}

async function getAssestWithCode(assestCode: string): Promise<AssestResponse> {
  return new Promise((resolve, reject) => {
    axios
      .get(config.urls.ASSEST_CODE_API +"/" + assestCode)
      .then(function(response) {
        let returnData: AssestResponse = {
          assetCode: response.data.assetCode,
          assetPath: config.urls.ASSEST_URL_ENDPOINT + response.data.assetPath,
          fallBackLabelKey: response.data.fallBackLabelKey,
        };
        resolve(returnData);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function logoutUser() {
  const bearer = JSON.parse(localStorage.getItem("bearer"));
  if (bearer) {
    let readTips = bearer.readTipId;    
    await axios
      .post(
        config.urls.EXPIRE_SESSION,
        {
          sessionId: bearer.sessionId,
          email: bearer.email,
          readTipId:readTips,
          tenantCode:config.constants.BASE_TENANT_CODE
        },
        {
          headers: {
           accessToken: bearer.acessToken,
        },
      }
      )
      .then(function(response) {
        resetSession();
      })
      .catch(function(error) {
        resetSession();
      });
  } else {
    resetSession();
  }
}

function resetSession() {
  let popup = localStorage.getItem("popup");
  let banner = localStorage.getItem("banner")
  localStorage.clear();
  localStorage.setItem("popup",popup);
  localStorage.setItem("banner",banner)
  serviceWorkerRegistration.unregister();
  if (
    window.location.href !== config.urls.SIGNUP ||
    window.location.href !== config.urls.LOGIN_VIA_OTP ||
    window.location.href !== config.urls.QUICK_PAY ||
    !window.location.href.startsWith(config.urls.PAYMENT)
  ) {
     window.location.href = config.urls.LOGIN_APP;
  }
}

function convertHexToRGBA(hex: string, opacity: number) {
  const tempHex = hex.replace("#", "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity / 100})`;
}

function dateFormat(date: string, dateFormat: string) {
  return moment(date).format(dateFormat);
}

function get_days(selectedDate: string) {
  let date = new Date(selectedDate);
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return new Date(year, month, 0).getDate();
}

function downloadPdf(data, pdffileName) {
  saveAs(data, pdffileName + ".pdf");
}

function getDueDays(dueDate: string) {
  let start = moment(dueDate, "YYYY-MM-DD");
  let end = moment(new Date()).format("YYYY-MM-DD");
  return moment.duration(start.diff(end)).asDays();
}

function Numberwithcommas(x){
  x=x.toString();
  let reqNumber = x.split(".");
  return reqNumber[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") + (reqNumber[1] ? ("."+reqNumber[1]): "");
  //var lastThree = x.substring(x.length-6);
  //var otherNumbers = x.substring(0,x.length-6);
  //if(otherNumbers != '')
  //    lastThree = ',' + lastThree;
  //var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  //return res;
}

async function getBearerFromAccess(accessToken){
  return new Promise((resolve, reject) => {
    axios
      .post(
        config.urls.GENERATE_BEARER_ACCESS,
        {},
        {
          headers: {
            accesstoken: accessToken,
            tenantCode: config.constants.BASE_TENANT_CODE,
            // Authorization: `Bearer ${accessToken}`
          },
        }
      )
      .then(function (response) {
        resolve(response.data)
        // localStorage.removeItem("authbearer")
        // localStorage.setItem(
        //   "authbearer",
        //   commonService.encodeString(JSON.stringify(response.data))
        // );
      })
      .catch(function (error) {
              reject(error);
      });
  });
}