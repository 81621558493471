import { createMuiTheme } from "@material-ui/core/styles";
import "./app.css";

const font = "'Noto Sans'";

export const appStyles = {
  outerTheme: outerTheme(),
};

function outerTheme() {
  return createMuiTheme({
    palette: {
      primary: {
        main: "#15537c",
      },
      secondary: {
        main: "#00ab6a",
      },
      tertiary: {
        main: "#f77d0f",
      },
      quaternary: {
        main: "#e5f6df",
      },
      quinary: {
        main: "#90cce1",
      },
      whiteColor: {
        main: "#FFFFFF",
      },
      blackColor: {
        main: "#000000",
      },
      usageNegativeColor: { 
        main: "#FF0000",
      },
      usagePositiveColor: {
        main: "#01B61E",
      },
      lightGrayColor: {
        main: "#ECECEC",
      },
      lightRedColor: {
        main: "#FFF1F0",
      },
      lightGraphColor: {
        main: "#f2dfde",
      },
      grayColor: {
        main: "#DCDCDC",
      },
      ePrimaryColors:{
        first:"#5FC96E",
        second:"#3A55B4",
        third:"#6CADDF",
        forth:"#A9FF9F",
        fifth:"#8CD9FF",
      },
      eSecondaryColors:{
        first:"#37BFBA",
        second:"#C3F6F4",
        third:"#FFFFFF",
        forth:"#DBDBDB",
        fifth:"#757575",
        sixth:"#292929"
      },
      eTertiaryColors:{
        first:"#F04E45",
        second:"#F0A645",
        third:"#F5F5F5"
      },
      ePrimary: { base: "#15537c", selected: "#144362", hover: "#477695" },
      eSecondary: { base: "#00ab6a", selected: "#018854", hover: "#44AD85" },
      eTertiary: { base: "#f77d0f", selected: "#c4641f", hover: "#f79752" },
      ePastelColor1: { base: "#e5f6df", selected: "#d5f0cb", hover: "#ecf8e8" },
      ePastelColor2: { base: "#90cce1", selected: "#7cc3dc", hover: "#b1dbea" },

      eRich: { base: "#37393a", selected: "#2c2e2e", hover: "#5f6161" },
      eDark: { base: "#5c5e60", selected: "#4a4b4d", hover: "#7d7e80" },
      eMedium: { base: "#93979a", selected: "#76797b", hover: "#a9acae" },
      eLight: { base: "#dee2e4", selected: "#c8ccce", hover: "#e5e8e9" },

      eBackground: { base: "#eff2f4", selected: "#d8dadc", hover: "#f2f5f6" },
      eFaint: { base: "#f4f7f8", selected: "#e8eaeb", hover: "#f6f9f9" },
      eWhite: { base: "#ffffff", selected: "#cccccc", hover: "#ffffff" },
      eSuccess: { dark: "#12805c", light: "#ddffdd" },
      eDanger: { dark: "#c9252d", light: "#ffdddd" },
      eInformation: { dark: "#0d66d0", light: "#ddffff" },
      eWarning: { dark: "#ffe500", light: "#ffffcc" },
    },
    fontFamily: font,
  });
}
