import { BILL_FORECAST_SUCCESS, BILL_FORECAST_FAILURE ,BILL_FORECAST_REQUEST, Bill_Forecast_contentConstants, Bill_Forecast_preference_enabled } from "./bill-forcast-constants";

const initialState = {
    billforecastcontentloading: null,
    loading: false,
    data: [],
    error: ''
  }
  
  export const billForecastReducer = (state = initialState, action) => {
    switch (action.type) {
      /*case BILL_FORECAST_REQUEST:
        return {
          ...state,
          loading: true
        }
      case BILL_FORECAST_SUCCESS:
        return {
            ...state,
          loading: false,
          data: action.payload,
          error: ''
        }
      case BILL_FORECAST_FAILURE:
        return {
            ...state,
          loading: false,
          data: [],
          error: action.payload
        }*/
        case Bill_Forecast_contentConstants:
            return { ...state, loading: true, billforecastcontentloading: action.data }
      default: 
            return state
    }
  }
  
const sendbillforecastPreferenceState = [];
export function sendBillForecastPreferenceData(state = sendbillforecastPreferenceState,action){
  switch(action.type){
    case Bill_Forecast_preference_enabled:
      return {...state, data: action.data}
    default:
      return state;
  }
}