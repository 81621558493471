import { usageConstants } from "./usage-constants";

const initialState = [];
export function UsageGraphData(state = initialState, action) {
  switch (action.type) {
    case usageConstants.GET_USAGE_SUCCESS:
      return { ...state, data: action.data };
    case usageConstants.GET_USAGE_FAILURE:
      return {...state,...action.data};
    default:
      return state;
  }
}
