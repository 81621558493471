import { profileConstants} from "./profile-constants";
const initialState = {
    updatedValAction: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case profileConstants.UPDATE_SELECTED_USER_PROFILE :
            return {
                ...state,
                updatedValAction: action.updatedValAction
            }
        default:
            return state
    }
}