import { contentConstants } from "./announcement-constant";
import { produce } from "immer";


function announcementReducer(state = {}, action) {
  switch (action.type) {
    case contentConstants.ANNOUNCEMENT_SUBMIT_SUCCESS:
      return produce(state, (draftState) => {
        draftState.annResults = action.payload;
      });

    case contentConstants.ANNOUNCEMENT_SUBMIT_FAILURE:
      return produce(state, (draftState) => {
        draftState.annError = action.payload;
      });

    default:
      return state;
  }
}

const initialState = {
  updatedAnnouncementAction: 0,
  announcementTab:0
};


function updateAnnouncementValue(state = initialState, action) {
  switch (action.type) {
    case contentConstants.UPDATE_SELECTED_ALERT_PAGE:
      return {
        ...state,
        updatedAnnouncementAction: action.updatedAnnouncementAction,
        announcementTab: action.announcementTab,
        prepaidTab: action.prepaidTab
      };
    default:
      return state;
  }
}

export { announcementReducer,updateAnnouncementValue};
