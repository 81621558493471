import { usageConstants } from "../constants/usage.constants";

const initialState = {};
export function usageUserData(state = initialState, action) {    
  switch (action.type) {
    case usageConstants.USAGE_USER_DATA: {
      return {
        ...state,
        ...action.snack
      };
    }
    default:
      return state;
  }
}

export function usageMeterDetails(state = initialState, action) {
  switch (action.type) {
    case usageConstants.USAGE_METER_DETAIL: {
      return {
        ...state,
        ...action.snack
      };
    }
    default:
      return state;
  }
}

